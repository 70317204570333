$easing: cubic-bezier(.25, 1.7, .35, .8);
$duration: 0.5s;
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.header-h1 {
    color: white;
    font-weight: bold;
    font-size: 59px;
    margin: 0px 0 20px 0;
    line-height: 70px;
    width: 96%;
}

.header-para {
    color: white;
    font-size: 24px;
}

.categories-belt-section {
    margin: 50px;
}

.categories-belt {
    display: none;
    list-style-type: none;
    justify-content: space-between;
    overflow: auto;
    cursor: grabbing;
}

.categories-belt-li {
    min-width: 151px;
    text-align: center;
    list-style: none;
    margin: 0 3px;
    img {
        width: 60px;
    }
}

.centerdata {
    .react-horizontal-scrolling-menu--scroll-container {
        justify-content: center;
    }
}

.categories-belt-header {
    text-align: center;
    margin: 0 0 65px 0px;
    color: black;
}

.categories-belt-round {
    width: 60px;
    height: 57px;
    border-color: black;
    border: solid;
    border-radius: 48px;
    border-width: 1px;
}

.categories-belt-text {
    text-align: center;
}

.categories-label {
    margin: 10px 0 0 0;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    width: 86%;
    color: black;
}

.feature-section {
    margin: 50px;
}

.feature-header {
    margin: 20px 0 65px 0px;
    text-align: center;
    color: black
}

.features {
    display: flex;
    list-style-type: none;
    justify-content: space-evenly;
    flex-wrap: wrap;
    a {
        text-align: center;
        display: inline-block;
    }
}

.feature-text {
    margin: 15px;
    text-align: center;
    display: list-item;
    font-size: 30px;
    color: black;
}

.testimonial-section {
    margin: 50px;
    color: black;
    padding: 0 0 85px 0;
}

.testimonial-header {
    margin: 0 0 65px 0px;
    text-align: center;
    color: black;
    font-size: 45px;
}

.testimonial {
    display: flex;
    list-style-type: none;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.testimonial-card {
    width: 388px;
    height: 350px;
    background-color: #f8f8f8;
    border-radius: 22px;
    border: none;
    z-index: 2;
    box-shadow: 0 4px 3px 1px #ccc;
    position: relative;
    margin: 105px 0 0 0;
}

.testimonial-image {
    border-radius: 132px;
    width: 133px;
    height: 135px;
    z-index: 3;
    position: relative;
    margin: -68px 0px 0px 0px;
}

.testimonial-img {
    width: 15px;
    margin: 5px 0px -25px 15px;
}

.testimonial-img1 {
    text-align: end;
    margin: -36px 46px 38px 0;
}

.testimonial-para {
    margin: 32px;
    font-size: 17px;
    text-align: center;
    color: black;
}

.testimonial-h6 {
    margin: 12px 0px 5px 0px;
    font-size: 23px;
    text-align: center;
    color: black;
}

.testimonial-p {
    font-size: 13px;
    text-align: center;
    color: #8f8585;
}

.signup-section {
    margin: 50px;
}

.signup-header {
    margin: 0 0 65px 0px;
    text-align: center;
    color: black;
    font-size: 40px;
}

.signup {
    display: flex;
    list-style-type: none;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 0 30px 0;
}

.signup-round {
    margin: 100px 20px 20px 20px;
    background-color: #fef9ec;
    width: 30px;
    height: 30px;
    border-radius: 34px;
}

.signup-btn {
    color: white;
    background-color: #e63c2c;
    border-radius: 9px;
    /* font-size: 12px; */
    box-shadow: 0 0 6px 2px #ccc;
    padding: 13px 45px 13px 45px;
}

.signup-btn:hover {
    background: none;
    color: #ee3d23;
    border-radius: 10px;
    border: 1px solid;
}

.signup-image {
    width: 80px;
    margin: 0 0 20px 0px;
}

.signup-image1 {
    margin: 0 0 40px 0px;
}

.signup-span {
    display: list-item;
    text-align: center;
    font-size: 20px;
    color: black;
}

.signup-li {
    text-align: center;
    margin-bottom: 10px;
    width: 150px;
}

.signup-icon {
    margin: 5px 0 0 10px;
    align-items: center;
}

.common-section {
    background-color: #5b9dc2;
    color: white;
    padding: 20px 0 0 0;
}

.common-header {
    margin: 0 0 24px 0px;
    text-align: center;
    padding: 30px 0 40px 0;
    font-size: 40px;
}

.common-row {
    padding: 0 0 25px 0;
    width: 100%;
}

.common-para {
    font-size: 23px;
    text-align: center;
    padding: 0 0 40px 0;
    width: 70%;
    margin: auto;
}

.common-col {
    margin: 0 0 20px 0;
    padding: 0px 0 50px 65px;
}

.common-p {
    text-align: left;
    font-size: 14px;
    font-weight: 100;
    margin: 25px 0 0 0px;
    color: #e2eef5;
    width:110%
}

.categories {
    justify-content: center;
}

.common-btn {
    color: #e63c2c;
    background-color: white;
    border-radius: 9px;
    /* font-size: 12px; */
    padding: 13px 45px 13px 45px;
    font-weight: bold;
}

.common-btn:hover {
    background-color: #ee3d23;
    color: white;
}

.common-box {
    width: 80px;
    height: 70px;
    background-color: white;
    border-radius: 10px;
}

.common-align {
    display: flex;
    align-items: center;
}

.common-h5 {
    margin: 0 0px 0 20px;
    font-size: 21px;
}

.common-img {
    width: 45px;
    margin: 13px 0 0 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.header-button {
    padding: 40px 0 0px 0;
}

.header-btn {
    background-color: #ee3d23;
    color: white;
    border-radius: 10px;
    padding: 13px 35px 13px 45px;
}

.header-btn:hover {
    background: none;
    color: #ee3d23;
    border-radius: 10px;
    border: 1px solid;
}

.header-col {
    position: absolute;
    text-align: left;
    margin-top: -355px;
    color: white;
}

.header-text {
    position: absolute;
    bottom: 26%;
    left: 0;
    right: 0;
    text-align: left;
}

.header-flex {
    position: relative;
    overflow: hidden;
}

.menu-ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
    flex-wrap: wrap;
    width: 94%;
    margin-left: 35px;
}

.menu-round {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin: 0 56px 0 0;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    box-shadow: 0px 0px 7px 1px #ade1fe;
    // box-shadow: 4px 4px 3px -1px #61a0c2, -4px -4px 3px -1px #addd86
}

.menu-image {
    margin: 28px 0 0 0;
    width: 45px;
}

.menu-span {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 25px;
    margin-right: 59px;
}

.arrow-icon {
    height: 2.8em;
    width: 2.8em;
    display: block;
    padding: 0.5em;
    margin: 1em auto;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    &.rotate {
        transform: rotate(-180deg);
    }
}

.left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 8px;
    width: 27px;
    height: 8px;
    display: block;
    transform: rotate(35deg);
    float: right;
    border-radius: 2px;
    &:after {
        content: "";
        background-color: rgb(0, 0, 0);
        width: 27px;
        height: 8px;
        display: block;
        float: right;
        border-radius: 6px 10px 10px 6px;
        transition: all $duration $easing;
        z-index: -1;
    }
}

.right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 26px;
    width: 27px;
    height: 8px;
    display: block;
    transform: rotate(-35deg);
    float: right;
    border-radius: 2px;
    &:after {
        content: "";
        background-color: rgb(0, 0, 0);
        width: 27px;
        height: 8px;
        display: block;
        float: right;
        border-radius: 10px 6px 6px 10px;
        transition: all $duration $easing;
        z-index: -1;
    }
}

.items {
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
}

.items.active {
    background: rgba(255, 255, 255, 0.3);
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
}

.item {
    display: inline-block;
    // background: skyblue;
    min-height: 250px;
    min-width: 400px;
    margin: 2em 1em;
    @media screen and (max-width: 500px) {
        min-height: 200px;
        min-width: 200px;
    }
}

.udbhavimg{
    border-radius: 30px;
}

.open {
    .left-bar:after {
        transform-origin: center center;
        transform: rotate(-70deg);
    }
    .right-bar:after {
        transform-origin: center center;
        transform: rotate(70deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

ul.menu-ul li {
    width: 180px;
    margin: 0 0 40px 0;
    text-align: center;
    display: inline-block;
}

.categories ::-webkit-scrollbar {
    width: 1px;
    scrollbar-width: thin;
    /* width of the entire scrollbar */
}

.categories ::-webkit-scrollbar-track {
    background: #fff;
    width: 1px;
    scrollbar-width: thin;
    /* color of the tracking area */
}

.categories ::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255);
    /* color of the scroll thumb */
    border-radius: 2px;
    scrollbar-width: thin;
    width: 1px;
    /* roundness of the scroll thumb */
    border: 1px solid #fff;
    /* creates padding around scroll thumb */
}

@media(max-width: 1200px) {
    .header-h1 {
        font-size: 48px;
    }
    .header-para {
        font-size: 23px;
    }
}

@media(max-width: 992px) {
    .header-h1 {
        font-size: 38px;
        margin-bottom: -5px;
        line-height: 43px;
    }
    .header-para {
        font-size: 19px;
    }
    .header-bnr {
        margin-top: px;
    }
}

@media(max-width: 764px) {
    .header-h1 {
        font-size: 43px !important;
    }
    .header-para {
        font-size: 20px;
    }
    .feature-section {
        margin: 0;
    }
    .heading-bnr {
        margin-top: 70px;
    }
}

@media(max-width: 575px) {
    .heading-bnr {
        margin: 100px 0 0 0;
    }
    .header-h1 {
        line-height: 45px !important;
        font-size: 32px !important;
    }
    .header-para {
        font-size: 15px;
    }
    .header-h1 {
        font-size: 29px !important;
        margin-top: 0px !important;
        text-align: center;
    }
    .header-res {
        font-size: 18px;
    }
    .header-para {
        text-align: center;
    }
    .features {
        img {
            width: 130px;
        }
    }
    .header-button {
        display: flex;
        justify-content: center;
    }
    .header-flex {
        img {
            width: auto;
            height: 500px;
        }
    }
    .heading-bnr {
        margin-top: 166px;
    }
    .common-align {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    .common-box {
        display: inline-block;
    }
    .common-img {
        padding-top: 0;
    }
    .common-h5 {
        text-align: center;
        margin-top: 15px;
    }
    .common-p {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 20px;
    }
}

@media(max-width: 480px) {
    .signup-btn {
        color: white;
        background-color: #e63c2c;
        border-radius: 9px;
        font-size: 12px; 
        box-shadow: 0 0 6px 2px #ccc;
        padding: 10px 15px 10px 15px;
    }
    .menu-ul {
        display: flex;
        list-style-type: none;
        justify-content: center;
        flex-wrap: wrap;
        width: 124%;
        margin-left: -10px;
    }
}