.refund-heading{
    h2{
    margin: 30px 0px 20px 0px;
    padding-top: 60px;
}
}   
.refund-paragraph {
p{
font-size: 12px;
margin: 0px 0px 20px 0px;
padding: 0px 0px 0px 0px;
font-weight: 600;
}
}
