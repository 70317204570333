.main-menu {
    background         : radial-gradient(#64a3c7, #3b7ea4);
    // background-color: #64a3c7; 
    position           : relative;
    overflow           : hidden;
    z-index            : 33;
}

.menu-img {
    position: fixed;
}

.menucolor {
    position: relative;
    z-index : 2;
}

//.menucolor{
//background: radial-gradient(#64a3c7, #3b7ea4);
//}
.menucontent {
    display        : flex;
    color          : white;
    justify-content: center;
    font-size      : 35px;
    padding        : 28px 0 57px 0;
    margin         : 0px 80px;
    justify-content: space-around;

    li {
        list-style   : none;
        margin-bottom: 30px;
        cursor       : pointer;
        color        : #fff;
    }

    ul {
        text-align: start;
    }

    a {
        text-decoration: none;
        color          : #fff;
    }
}

.logoimg {
    display        : flex;
    justify-content: space-between;
}

.roundclose {
    font-size: 40px;
    color    : white;
    float    : right;
}

.menuimg {
    margin     : 45px 65px;
    padding-top: 60px;
}

html,
body,
#root,
#root>div,
#root .main-menu {
    height: 100%;
}

@media(max-width: 964px) {
    .logoimg {
        img {
            transform: scale(0.65);
        }
    }
}


@media(max-width: 764px) {
    .menucontent {
        display  : block;
        font-size: 30px;
        margin: 0;


        li {
            list-style   : none;
            margin-bottom: 20px;
            text-align   : center;
        }
    }

    .menuimg {
        margin     : 0;
        padding-top: 60px;
    }

    .logoimg {
        img {
            transform: scale(0.5);
        }
    }
}


@media(max-width: 564px) {
    .logoimg {
        img {
            transform: scale(0.475);
        }
    }
}