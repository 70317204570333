.about-top-main{
    .register-width-style{
        width: 38% ;

        .arrowCss {
            color: $a-orange-color;
            position: absolute;
            top: 24px;
            right: 5%;
            font-size: 20px;
        }
    }
    .checkout-below{
        .checkoutlogo{
            .bottomicon-home{
                .icon-play-app-store{
                    width: 22%;
                    height: 45px;
                    margin-right: 10px;
                }
            }
        }
    }
}

.aboutamealio-section {
    // padding: 130px 0 0 0;
}

.aboutamealio-speechimg {
    text-align: center;
}

// .amealio-headline{
//     border-top: 1px solid #00000069;
//     margin-top: 30px;
// }
.aboutamealio-ul {
    padding        : 120px 0 0 0;
    list-style-type: none;
}

.p-voice-text {
    text-align: center;
    font-size: x-large;
    margin: 20px 0 0 0px;
}

.aboutamealio-li {
    margin: 25px 0 0 0;

    button {
        width: 222px;
        color: black;
    }
}

.amealia-logo {
    position: absolute;
    top     : 10px;
    width   : 165px;
    z-index : 30;
}

.about-amealio1 {
    // min-width: 75%;
    // height: auto;
    // font-size: 16px;
    // font-family: "Muli";
    // font-style: normal;
    // font-feature-settings: normal;
    // font-variant: normal;
    // font-weight: 600;
    // border-radius: 15px;
    // box-shadow: 0px 4px 6px #00000041;
    min-width: 150px;
    min-height: 42px;            
    border-radius: 50px;
    font-family: 'Muli'; 
    font-size: 16px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 700;
    padding: 0 40px;
    background-color: #fff;
    border: 4px solid $a-red-color;
}
.amealio-butn{
    background-color: #feb92a;
    border: none;
    // box-shadow: 0px 4px 6px #00000041;
}

.about-amealio1:active {
    background: #feb92a 0% 0% no-repeat padding-box;
    font-size: 17px;
    box-shadow: 0px 4px 6px #00000069;
    border: none;
}

.about-amealio1:hover {
    background: #feb92a 0% 0% no-repeat padding-box;
    font-size: 17px;
    box-shadow: 0px 4px 6px #00000069;
    border: none;
}

.about-amealio1:active:focus {
    outline: none;
    border: none;
}

.aboutamealio-audio {
    height            : 100%;
    z-index           : 9999;
    color             : white;
    position          : fixed;
    top               : 0;
    bottom            : 0;
    float             : left;
    left              : 0;
    right             : 0;
    left              : 100%;
    width             : 100%;
    background        : #0000008c;
    -webkit-transition: all 2s ease;
    -moz-transition   : all 2s ease;
    -o-transition     : all 2s ease;
    -ms-transition    : all 2s ease;
    transition        : all 2s ease;
}

.aboutamealio-audio-active {
    height    : 100%;
    z-index   : 9999;
    color     : white;
    position  : fixed;
    top       : 0;
    bottom    : 0;
    float     : left;
    left      : 0;
    right     : 0;
    left      : 0;
    width     : 100%;
    background: #0000008c;
}

.aboutamealio-p {
    // margin     : 200px 0 15px 0;
    text-align : center;
    width      : 100%;
    font-size  : 25px;
    font-weight: 500;
}

.aboutamealio-h6 {
    text-align     : center;
    font-size      : 17px;
    margin  : 30px auto;
    color          : white;
    text-decoration: none;
}

.aboutamealio-micaccess {
    display        : flex;
    justify-content: center;
    margin-top     : 40px;

}

.aboutamealio-btn {
    border       : 1px solid white;
    color        : white;
    font-size    : 23px;
    margin       : 0 30px 0 30px;
    padding      : 6px 24px 6px 24px;
    border-radius: 10px;
}

.aboutamealio-btn:hover {
    border          : 1px solid white;
    background-color: white;
    color           : black;
    font-size       : 24px;
    // margin          : 0 30px 0 30px;
}

.aboutamealio-center {
    text-align: center;
    // margin-top: 70px;
}

.aboutamealio-background {
    background-color: white;
}

.aboutamealio-row {
    margin-top: 0px;
}

.voice-section {
    padding: 50px 0 0px 0;
}

.voice-h1 {
    text-align : center;
    font-size  : 40px;
    font-weight: 600;
    padding    : 0 0 20px 0;
    width      : 66%;
    margin     : auto;
    line-height: 52px;
}

.voice-p {
    font-size: 20px;
}

.aboutamealio-headflex {
    display        : flex;
    justify-content: space-between;
    margin         : 30px 0px 0 0;
}

.button-active-focus:active:focus{
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
}

.aboutamealio-icon {
    font-size: 30px;
    cursor   : pointer;
}

.aboutamealio-headcenter {
    font-size  : 42px;
    font-weight: 700;
    text-align: center;
    font-family: 'Muli';  
    font-style: normal; 
    font-variant: normal;
}

.aboutamealio-headcenter-paragraph{
    font-size  : 20px;
    font-weight: 500;
    text-align: center;
    font-family: 'Muli';  
    font-style: normal; 
    font-variant: normal;
    padding: 0px 115px;
}

.aboutamealio-slide {
    height            : 100%;
    z-index           : 99999;
    position          : fixed;
    top               : 0;
    bottom            : 0;
    float             : left;
    left              : 0;
    right             : 0;
    left              : 100%;
    width             : 100%;
    background-color  : white;
    -webkit-transition: all 2s ease;
    -moz-transition   : all 2s ease;
    -o-transition     : all 2s ease;
    -ms-transition    : all 2s ease;
    transition        : all 2s ease;

    .top-right-image-fixed{
        position : fixed;
        top: 15%;
        right: 1%;  
        width: 8%;
    }

    .bottom-left-image-fixed{
        position : fixed;
        left : 0%;
        bottom : 0;
        width : 8%;
    }
}

.aboutamealio-slide-active {
    height          : 100%;
    z-index         : 99999;
    position        : fixed;
    top             : 0;
    bottom          : 0;
    float           : left;
    left            : 0;
    right           : 0;
    left            : 0;
    width           : 100%;
    background-color: white;
    overflow: auto;
}

.aboutamealio-textdecoration:hover {
    text-decoration: none;
    cursor         : pointer;
}

.aboutamealio-multi-modal {
    display        : flex;
    justify-content: space-between;
    margin         : 15px 0 0 0;
}

.aboutamealio-multi-modal-h1 {
    text-align : center;
    width      : 60%;
    margin     : auto;
    font-weight: 600;
    padding    : 0 0px 18px 0;
    color      : black;
}

.aboutamealio-multi-modal-p {
    text-align: center;
    width     : 71%;
    margin    : auto;
    color     : black;
}

.aboutamealio-headimg {
    margin  : 0 0;
    z-index : 999999;
    position: relative;
}

.aboutamealio-multi-slide {
    height            : 100%;
    z-index           : 99999;
    position          : fixed;
    top               : 0;
    bottom            : 0;
    float             : left;
    left              : 0;
    right             : 0;
    left              : 100%;
    width             : 100%;
    background-color  : white;
    -webkit-transition: all 2s ease;
    -moz-transition   : all 2s ease;
    -o-transition     : all 2s ease;
    -ms-transition    : all 2s ease;
    transition        : all 2s ease;
}

.aboutamealio-multi-slide-active {
    height          : 100%;
    z-index         : 99999;
    position        : fixed;
    top             : 0;
    bottom          : 0;
    float           : left;
    left            : 0;
    right           : 0;
    left            : 0;
    width           : 100%;
    background-color: white;
}

.aboutamealio-multi-modal-img {
    margin: 0px 0 0 0;
}

.aboutamealio-mic {
    text-align: center;
    z-index   : 9999999;
    position  : relative;
    margin    : 30px 0 0 0;

    p {
        margin: 0px 0 10px 0;
    }
}

.aboutamealio-headimg1 {
    height            : 100%;
    z-index           : 99999;
    position          : fixed;
    top               : 0;
    bottom            : 0;
    float             : left;
    left              : 0;
    right             : 0;
    left              : 100%;
    width             : 100%;
    -webkit-transition: all 2s ease;
    -moz-transition   : all 2s ease;
    -o-transition     : all 2s ease;
    -ms-transition    : all 2s ease;
    transition        : all 2s ease;
}

.aboutamealio-headimg2 {
    height            : 100%;
    z-index           : 99999;
    position          : fixed;
    top               : 0;
    bottom            : 0;
    float             : left;
    left              : 0;
    right             : 0;
    left              : 100%;
    width             : 100%;
    -webkit-transition: all 2s ease;
    -moz-transition   : all 2s ease;
    -o-transition     : all 2s ease;
    -ms-transition    : all 2s ease;
    transition        : all 2s ease;
}

@media (max-width: 480px) {
    .aboutamealio-btn{
        margin: 0 30px 0px 30px;
        padding: 5px 20px 5px 20px;
        font-size: 20px;
    }
    .aboutamealio-micaccess{
        margin-top: 40px
    }
}

/////// Mouse Scrolling Animation
.position-of-mouse-about{

    #scrolling-mouse {
        position: relative;
        display: inline-block;
        height: 50px;
        width: 30px;
        border: solid 2px #707070;
        border-radius: 20px;
        text-align: center;
      }
      #scrolling-mouse::before {
        content: "";
        position: absolute;
        display: block;
        height: 10px;
        top: 32%;
        left: 50%;
        width: 10px;
        border-right: solid 2px #707070;
        border-bottom: solid 2px #707070;
        animation-name: arrow;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
      }
      #scrolling-mouse::after {
        content: "";
        position: relative;
        display: inline-block;
        height: 5px;
        width: 3px;
        background-color: #000;
        border-radius: 2px;
        animation-name: scroll;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
      }

      @keyframes scroll{
        0%{
          height: 2px;
          opacity: 0;
          transform: translateY(0px);
        }
        20%{
          height: 8px;
          opacity: 1;
        }
        80%{
          height: 8px;
        }
        100%{
          height: 2px;
          opacity: 0;
          transform: translateY(15px);
        }
      }
      @keyframes arrow{
        0%{
          opacity: 0;
          transform: translateX(-50%) translateY(0px) rotate(45deg);
        }
        20%{
          opacity: 1;
        }
        100%{
          opacity: 0;
          transform: translateX(-50%) translateY(20px) rotate(45deg);
        }
      }
}

///about top
.top-about-right-image{
    position: absolute;
    right: 5%;
    width: 30%;
    height: auto;
}

.top-sprial-image{
    width: 100%;
    position: absolute;
    top: -20px;
    left: 0;
}

.top-sprial-image-bg{
    width: 100%;
    position: absolute;
    top: -20px;
    left: 0; 
}

///About Section Two
.about-amealio-section-two{
    position: relative;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    padding: 48px 88px;

    .top-left-image{
        position: absolute;
        left: 0%;
        top: 5%;
        width: 8%;
    }

    .about-redefine-head1{
        font-family: 'Muli'; 
        font-size: 42px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: bold;
        color: #FFFFFF;
        margin: 0 !important;
    }

    .about-redefine-head2{
        font-family: 'Muli'; 
        font-size: 25px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 700;
        color: #FFFFFF;
        margin: 0 !important;
    }

    .about-redefine-para{
        font-family: 'Muli'; 
        font-size: 18px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 400;
        color: #FFFFFF;
    }

    .about-amealio-col-right{
        // display: flex;
        // align-items: center;
        // justify-content: end;

        .right-mobile-image-redefine{
            position: absolute;
            width: 100%;
            bottom: 35%;
            left: -75px;
        }
    }
}

///About Section four
.about-revolution-banner{
    position: relative;
    width: 100%;
    height: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    padding: 48px 88px;
    
    .aboutbanner5{

        h1{
            font-family: 'Muli'; 
            font-size: 34px; 
            font-style: normal; 
            font-variant: normal; 
            font-weight: 700;
            color: #FFFFFF;
            margin: 0 !important;
        }

        .aboutbanner5-small{
            font-family: 'Muli'; 
            font-size: 18px; 
            font-style: normal; 
            font-variant: normal; 
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 !important;
            width: 75%;
        }
    }
}

///About Section Our Time Line
.ourtimeline-bg{
    position: relative;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 48px;

    .top-sprial-image-right {
        position: absolute;
        right: 0;
        top: -21px;
        z-index: 1;
    }

    .top-our-timeline-img{
        position: absolute;
        top: 2%;
        left: 0;
        width: 12%;
    }

    .bottom-our-timeline-img{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 8%;
    }
}

.main-amealia-home{
    position: relative;
    .top-right-image{
        position: absolute;
        top: 0;
        right: 0;
        width: 8%;
    }

    .bottom-left-image{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 8%;
    }
}

.aboutamealia-main{
    position: relative;
    height: auto;

    .aboutamealia-heading-one{
        font-family: 'Muli'; 
        font-size: 50px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 700;
        color: #000000;
    }

    .aboutamealia-heading-two{

        // h2{
        //     font-family: 'Muli';
        //     font-size: 18px;
        //     line-height: 24px;
        //     font-style: normal; 
        //     font-variant: normal; 
        //     font-weight: 600;
        //     color: #000000;
        // }

        // p{
        //     font-family: 'Muli'; 
        //     font-size: 16px; 
        //     line-height: 22px;
        //     font-style: normal; 
        //     font-variant: normal; 
        //     font-weight: 500;
        //     color: #000000;
        // }
    }

    .aboutamealia-heading-three{
        position: relative;
        // height: 74vh;

        .center-right-image{
            position: absolute;
            // bottom: 0;
            top: 0;
            right             : 0;
            width: 55%;
        }
    }
}

@media(max-width: 992px) {
    ///about top
    .about-top-main{
        padding-bottom: 0 !important;

        .about-top-main-left{
            text-align: center !important;
        }

        .user-details .input input{
            font-size: 16px !important;
            font-weight: 500 !important;
        }

        .register-width-style{
            width: 85% !important;

            .already-add-number{
                font-size: 16px;
                font-family: Muli;
                top: 26px
            }
    
            .arrowCss {
                color: $a-orange-color;
                position: absolute;
                top: 28px;
                right: 8%;
                font-size: 22px;
            }
        }
        .checkout-below{
            .checkoutlogo{
                .bottomicon-home{
                    margin-top: 20px !important;
                    .icon-play-app-store{
                        width: 42%;
                        height: 45px;
                        margin-right: 5px;
                        margin-left: 5px;                    
                    }
                }
            }
        }
        
    }
    .top-about-right-image{
        position: static;
        left: 50%;
        right: 50%;
        width: 100%;
        padding-top: 45px;
    }

    /// Section Two
    .about-amealio-section-two{
        padding: 75px 0px;

        .top-left-image{
            top: 0%;
            width: 22%;
        }

        .about-redefine-head2, .about-redefine-para{
            text-align: center;
        }

        .top-sprial-image{
            top: -18px;
        }

        .about-amealio-col-left{
            text-align: center !important;
        }

        .about-amealio-col-right{
            img{
                width: 100% !important;
                // padding-top: 55px !important;
            }
        }
    }

    //Section three
    .ourtimeline-bg{
        padding: 75px 0px;
        background-position: center;
        min-height: 100vh; 
        background-size: 140% 148%;

        .top-sprial-image-right{
            top: -18px !important;
        }

        .top-our-timeline-img{
            width: 20% !important;
        }

        .bottom-our-timeline-img{
            width: 20% !important;
        }

        .bottom-our-timeline{
            right: 10px !important;
            bottom: 8% !important;

            h2{
                font-size: 28px !important;
            }
            h4{
                font-size: 18px !important;
            }
            p{
                font-size: 14px !important;
            }
        }
    }

    //Section four
    .aboutbanner4 .img-ourtimeline-three{
        width: 70% !important;
    }

    .aboutbanner4 .passingmaintane{
        height: 300px !important;
    }

    .about-revolution-banner{
        padding: 75px 0px;
        height: auto;

        .aboutbanner5{
            background-color: transparent;
            padding: 0 !important;
            margin: 0 !important;

            .aboutbanner5-small{
                width: 100% !important;
            }
        }       

        .top-sprial-image{
            top: -18px !important;
        }

        .center-go-web-btn{
            text-align: center !important;
        }
    }

    .aboutamealia-main{
        .aboutamealia-heading-one{
            text-align: center ;
        }

        .service-takeaway-data{
            text-align: center !important;
        }

        .aboutamealia-heading-two{
            h2, p{
                text-align: center;
            }
        }

        .aboutamealia-heading-three{
            height: auto;

            .center-right-image{
                position: static;
                padding-top: 45px;
                padding-bottom: 45px;
            }
        }
    }

    .aboutamealio-slide{
        .aboutamealio-headcenter{
            font-size: 35px;
        }

        .aboutamealio-headcenter-paragraph{
            padding: 0 !important;
        }
    }
}

