@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 1000px) {
    .bannerconfigation .bannerexplanations {
        bottom: 0;
        width: 90%;
    }
    .container {
        max-width: 100%;
        width: 100%;
    }
    .aboutbanner6 .tab-content ul.ngo-aline5 {
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 992px) {
    // header {
    //     position: inherit;
    // }
    .fas.fa-align-right {
        color: #000;
    }
    .logo {
        margin: 6px 0px 0px 0px;
    }
    .tophandling {
        margin: 0px 0px 26px 0px;
    }
    .categories-belt {
        display: flex;
    }

    .scrollcat {
        display: none;
    }
    .bottommanegement {
        margin: 0;
    }
    .layer-adjest {
        width: 50%;
    }
   
}
@media screen and (max-width: 746px) {
    // .colorheader{
    //     position: absolute;
    // }
    .header-text {
        top: 0;
    }
    .header-h1 {
        margin-top: 30px;
        font-size: 30px;
    }
   
    .bannerconfigation .bannerexplanations h1 {
        font-size: 20px;
        margin: 0;
    }
    .bannerconfigation .bannerexplanations p {
        font-size: 14px;
    }
    .bannerconfigation .bannerexplanations img {
        width: 60px;
    }
    .deliverycondition h1 {
        font-size: 20px;
        margin: 40px 0px 0px 0px;
    }
    h1,
    .h1,
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3 {
        font-size: 20px;
    }
    .row.social-orgin {
        text-align: center;
        flex-direction: column-reverse;
    }
    .udbhavlogo {
        float: none;
    }
    .bottommanegement {
        margin: 0;
    }
    .soclogo{
        padding-top: 50px;
    }
    .slide1 {
        padding-top: 50px;
    }
    .community-insta ._2R-kh{
        display: flex;
    }
    .community-insta > div > div {
        flex-basis: 46%;
    }
}
@media screen and (max-width: 600px) {
    // .colorheader{
    //     position: absolute;
    // }
}
@media screen and (max-width: 586px) {
    // .colorheader{
    //     position: absolute;
    // }
    .common-col{
        margin: 0 0 20px 0;
        padding: 0 20px;
    }
    .testimonial li {
        width: 100%;
    }

    .appbuttonhandling {
        font-size: 14px;
    }
    .categories-belt-section {
        margin: 50px 10px;
    }
    .testimonial-section {
        margin: 50px 10px;
    }
    .header-button {
        padding: 6px 0 0px 0;
    }
    .signup-li {
        margin-bottom: 50px;
    }
    .signup {
        justify-content: center;
    }
    .header-h1 {
        font-size: 20px;
        line-height: 26px;
    }
    .header-para {
        font-size: 14px;
    }
    h2 {
        font-size: 20px;
    }
    .feature-text {
        font-size: 20px;
    }
    .signup-header {
        font-size: 20px;
    }
    .testimonial-header {
        font-size: 20px;
        margin: 0;
    }
    .testimonial-section {
        padding: 0;
    }
    .common-header {
        font-size: 20px;
    }
    .common-para {
        font-size: 14px;
    }
    .common-h5 {
        font-size: 20px;
    }
    .common-p {
        font-size: 14px;
    }
    .categories-label {
        font-size: 14px;
    }
    .testimonial-card {
        width: 100%;
        height: auto;
    }
    .signup-round {
        display: none;
    }
    header .menulisticon a {
        margin-left: 5px;
    }
    .bottomlayer.layer-adjest.social-adjestlink {
        width: 100%;
    }
    .sociallink a{
        font-size: 30px;
        width: 50px;
        height: 50px;
        padding-top: 10px;
    }
}
@media screen and (max-width: 480px) {
    header .logo {
        width: 100px;
        margin-top: 28px;
    }
    header .appbuttonhandling {
        padding: 5px 5px;
        margin: 34px 0px 0px 0px;
    }
    header .logo img {
        margin-top: 7px;
        
    }
    .header-btn {
        padding: 2px 24px;
    }

    // .layer-adjest {
    // }
    .bottomlayer li {
        font-size: 14px;
    }
    .bottomlayer h2 {
        font-size: 20px;
    }
  
    .social-adjestlink {
        .layer-adjest {
            width: 100%;
        }
    }
    .allrightsdescribe p {
        font-size: 12px;
    }
    .conditiondescribe ul {
        justify-content: center;
    }
    .bannerfeature img {
        
        /* background-size: 673px; */
        height: 659px;
        /* background-repeat: no-repeat; */
        background-size: 730px;
        width: 365px;
        height: 841px;
        /* background: transparent url(img/overhead-view-female-s-hand-holding-social-network-app-symbol-blocks-lawn.png) 0% 0% no-repeat padding-box; */
        /* opacity: 0.3; */
    }
    .bannerconfigation .bannerexplanations p {
        font-size: 20px;
    }
    .bannerconfigation .bannerexplanations h1 {
        font-size: 30px;
        margin: 15px 0 15px 0;
    }
    .bannerconfigation .bannerexplanations img {
        width: 94px;
    }
    .bannerconfigation .bannerexplanations {
        /* bottom: 0; */
        width: 90%;
        margin: 0 0px 100px 0;
    }
    .deliverycondition h1 {
        font-size: 29px;
        margin: 40px 0px 0px 0px;
        text-align: center;
        line-height: 36px;
        color: #000;
    }
    .conditiondescribe h1 {
        font-size: 30px;
        text-align: center;
        margin: 50px 0px 85px 0px;
        color: #212121;
        font-weight: 600;
    }
    .mobileappdescribe h1 {
        display: flow-root;
        text-align: center; 
        line-height: 44px;
        font-size: 24px;
        margin: 0px 0 0px 0;
        font-weight: 600;
        margin: -482px 0 450px 0;
    }
    .mobileappdescribe span {
        font-size: 19px;
        text-align: center;
        color: black;
        flex-wrap: wrap;
        display: flex;
        // justify-content: end;
        justify-content: flex-end;
     margin: 0 0 11px 0;
    }
    .mobilevideoappdescribe h1 {
        /* margin: 130px 0px 0px 0px; */
        text-align: center;
        margin: auto;
        font-size: 32px;
        width: 94%;
        font-weight: 600;
        line-height: 46px;
    }
    .mobilevideoappdescribe h1 {
        /* margin: 130px 0px 0px 0px; */
        text-align: center;
        margin: auto;
        font-size: 32px;
        width: 94%;
        font-weight: 600;
        line-height: 46px;
    }
    .mobilevideoappdescribe p {
        font-size: 18px;
        margin: 325px 0 -8px 0;
        text-align: center;
        /* width: 98%; */
    }

    .videoapp-bttn {
        padding: 10px 15px 10px 15px;
        color: aliceblue;
        border-radius: 10px;
        font-size: 18px;
        margin-top: 38px;
    }
    .mobileapp img {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .amealiomobilevideo{
        width: 100%;
        margin: -500px 0 278px 0;
        display: flex;
        justify-content: flex-end; 
    }
    .hotel .hotelbannerdiscribe .hoteldescribe h1 {
        font-size: 22px;
        color: #fff;
        margin: 0px 0px 20px 0px;
    }
    .hotel .hotelbannerdiscribe .hoteldescribe .private {
        font-size: 14px;
        color: #fff;
        background-color: #ee3d23;
        border: 1px solid #ee3d23;
        border-radius: 5px;
        padding: 15px 20px 15px 20px;
        margin: 0 auto;
        /* width: 60%; */
    }
    .hotel .hotelbannerdiscribe .hoteldescribe h6 {
        font-size: 12px;
        color: #fff;
        margin: 14px 0px 0px 0px;
        position: relative;
        text-decoration-line: underline;
    }
    .conditiondescribe {
        margin: 0px 0px 100px 0px;
    }
    .col-md-6.mobileappdescribe span {
        text-align: center;
    }
  
}

@media screen and (max-width: 360px) {
    .bottommanegement {
        display: block;
        padding: 15px 0px 15px 0px;
    }
    .bottomlayer{
        li{
            margin: 10px 0px 5px -6px;
        }
        h2{
            margin: 20px 0px;
        }
    }
    .foot {
        display: block;
        text-align: center;
        width: 100%;
    }
    .allrightsdescribe img{
        height: 0%;
    } 
}
