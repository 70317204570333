.demo-section {
    display        : flex;
    height         : 100vh;
    justify-content: center;
    align-items    : center;
    background     : grey;
    position       : relative;
    // z-index        : 30;
    z-index        : -1;
    overflow       : hidden;
}

.container1 {
    max-width    : 480px;
    max-height   : 1700px;
    width        : 100%;
    background   : white;
    padding      : 25px 30px;
    border-radius: 5px;
    position     : relative;
    z-index      : 1;
}

.container1 .title {
    font-size  : 25px;
    text-align : center;
    font-weight: 200;
    color      : red;
}

.container1 form .details {
    display  : flex;
    flex-wrap: wrap;
}

form .details .inputbox {
    margin: 5px 0 0 0;
    width : 100%;
}

.details .inputbox .details {
    font-weight: 500;
    margin     : 15px 0 4px 0;
}

.details .inputbox input {
    height       : 30px;
    width        : 420px;
    outline      : none;
    border-radius: 5px;
    border       : 1px solid #ccc;
    font-size    : 14px;
}

.button-submit { 
    margin: 10px 0;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.btn-submit {
    display: inline-block;
    background-color: #ee3d23;
    color           : white;
    border          : none;
    padding         : 10px 30px 10px 30px;
    border-radius   : 10px;
}

.flex {
    display: flex;
}

.flex input {
    width : 200px;
    margin: 0 0 0 0;
}

.flex .inputbox input {
    width: 200px;
}

.s {
    justify-content: space-between;
    width          : 100%;
}

.cls {
    float : right;
    cursor: pointer;
}

.loader {
    position       : fixed;
    background     : #000000c7;
    top            : 0;
    bottom         : 0;
    left           : 0;
    z-index        : 33;
    right          : 0;
    text-align     : center;
    justify-content: center;
    align-items    : center;
    display        : flex;

    img {
        height: 100px;
        width : 100px;
    }
}
@media(max-width: 480px){
    .flex.s{
        display: inline-block;
    }
    .details .inputbox input{
        width: 100%;
    }
    .container1 {
        padding: 20px;
        max-width: 94%;
    }
}