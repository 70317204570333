.explore-fronttitle{
    text-align: center;
    margin: 20px 0px 60px 0px;
}
.explore-section{
    background-color: white;
}
.explore-firstblock{
   margin:  30px 0px 30px 0px ;
}
.expore-voice-section{
    margin: 50px 0 0 0;
    // 115px
}
.explore-content1{
    padding-top: 50px;
    // h2{
    //     font-weight: 600;
    //     font-size: 30px;
    //     color: #212121;
    // }
    // p{
    //     font-size: 18px;
    //     color: #212121;
    //     font-weight: 500;
    // }
}
.explore-content5{
    padding-top: 75px;
    // h2{
    //     font-weight: 600;
    //     font-size: 30px;
    //     color: #212121;
    // }
    // p{
    //     font-size: 18px;
    //     color: #212121;
    //     font-weight: 500;
    // }
}
.explore-fronttitle{
    // h1{
    //     font-size: 40px;
    //     font-weight: 600;
    //     color: #212121;
    // }
}
.explore-title{
    // h3{
    //     font-size: 40px;
    //     color: #212121;
    // }
}
.explore-content4{
    // h2{
    //     font-weight: 600;
    //     font-size: 30px;
    //     color: #212121;
    // }
    // p{
    //     font-size: 18px;
    //     color: #212121;
    //     font-weight: 500;
    // }
}
.explore-secondblock{
    margin:  30px 0px 60px 0px ;
}
.explore-secondblock .explore-content4{
    padding-top: 50px;
}
.explore-thirdblock {
    margin:  30px 0px 60px 0px ;
}
.explore-content5{
    padding-top: 50px;
}
.explore-bottomtitle{
    background-color:#f3f3f3;
    padding: 40px 0 40px 0px;

}
.explore-title{
    text-align: center;
    padding: 6px 0px 10px 0px;
}
.explore-logo{
    align-items: center;
    text-align: center;
}
.explore-frntpage{
    align-items: center;
    margin: 80px 0px;
}
@media(max-width: 1200px){
    .explore-content4 {
        //padding-left: 70px;
    }
}
@media(max-width: 992px){
    .explore-content4{
        //margin-left: 1px;
    }
}
@media(max-width: 764px){
.explore-content5{
    padding-top: 0px;
}
.explore-secondblock{
    margin: 30px 0px 0px 0px;
}
}
@media(max-width: 600px){

}
@media(max-width: 480px){
    .explore-content1{
        order: 2;
        padding-top:25px;
        h2{
            font-size: 24px;
        }
        p{
            font-size: 17px;
        }
    }
    .explore-content4{
        padding-top: 25px;
        h2{
            font-size: 24px;
        }
        p{
            font-size: 17px;
        }
    }
    .explore-content5{
        order: 2;
        h2{
            font-size: 24px;
        }
        p{
            font-size: 17px;
        }
    }
    .explore-fronttitle{
        h1{
            font-size: 24px;
        }
    }
    .explore-secondblock{
        margin:0px;
    }
    .explore-thirdblock{
        margin: 0px;
    }
    .explore-content6{
        margin-top: 30px;
    }
    .explore-secondblock .explore-content4{
        padding-top:30px;
    }
    .explore-thirdblock .explore-content5{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .explore-title{
        h3{
            font-size: 25px;
        }
    }
    .exp-logo{
        img{
            width: 100%;
        }
    }
}