.backgroundpic {
    position: fixed;
    z-index: -1;
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    width: 100%;
}

.socbackgroundblack {
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}
.row.social-orgin{
    margin-top: 70px;
}
.soc-head {
    font-size: 51px;
    color: black;
}

.soc-para {
    font-size: 22px;
    color: black;
    margin: 15px 0 0 4px;
}

.soc {
    font-size: 33px;
}

.slide1 {
    // padding: 115px 0 0 0;
}

.slidecontent {
    position: relative;
    z-index: 5;
    margin: -99px 0 0 0px;
    font-size: 24px;
    color: white;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 2px;
}

.imgcontent {
    position: relative;
    z-index: 4;
}

.udbhavlogo {
    float: right;
    width: 70%;
}

.Socialposts {
    padding: 60px 0;
}

.Socialpost-header {
    text-align: center;
    font-size: 32px;
}

.social-head {
    // margin-top: -39px;
}

.socialpost {
    margin-top: 45px;
    display: flex;
    justify-content: center;
}

.socialpost-div {
    height: 327px;
    border-radius: 21px;
    border-color: #efac0c;
    border-style: solid;
    margin-bottom: 33px;
}

.button1 {
    background-color: #fab516;
    color: black;
    width: 142px;
    border-radius: 9px;
    margin: 8px 0 0 0px;
    align-items: center;
    margin-top: 28px;
    font-size: 19px;
    a {
        color: black;
    }
    a:hover {
        text-decoration: none;
    }
}

.joinus {
    margin: 41px 0 0 0px;
    font-size: 20px;
    color: black;
    text-align: center;
}

// .button1:hover {
//     background: white;
//     color: #fab516;
//     width: 142px;
//     border-radius: 9px;
//     margin: 8px 0 0 0px;
//     align-items: center;
//     border: 1px solid;
// }
.button1-know {
    background-color: #ee3d23;
    color: white;
    width: 142px;
    border-radius: 9px;
    font-size: 20px;
    margin: -40px 42px 22px 38px;
}

.button1-know:hover {
    background-color: white;
    color: #ee3d23;
    width: 142px;
    border-radius: 9px;
    margin: -40px 42px 22px 38px;
    border: 1px solid;
}

.item active {
    width: 100%;
}

.item {
    width: 100%;
}

.item1 {
    background-color: white;
}

.socialbackgroundeffect{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -30px;
    z-index: 4;
    background: #00000045;
}
@media(max-width: 480px) {
    
    .imgcontent {
        position: relative;
        z-index: 4;
        width: 100%!important;
        height: 347px!important;
    }
    
    .col-md-4 {
    padding: 18px 20px 0px 20px;
    }
    .udbhavlogo {
        width: 45%;
    }
    .soc-head {
        font-size: 20px;
        color: black;
        padding: 5px 0 0 0;
    }
    .soc-para {
        font-size: 16px;
        color: black;
        margin: 10px 0 0 4px;
    }
    .socialbackgroundeffect {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -48px;
        z-index: 4;
        background: #00000045;
    }
    .Socialposts {
        padding: 20px 0;
    }
    .joinus{
        margin: 40px 0 0 0px;
        font-size: 17px;
    }
    .slidecontent {
        position: relative;
        z-index: 5;
        margin: -160px 0 0 0px;
        font-size: 18px;
        color: white;
        text-align: center;
    }
    .socialcommunity {
        display: block;
        margin-bottom: 0px;
        padding-bottom: 50px;
    }
    .Socialpost-header{
        font-size: 20px;
    }
    .button1-know{
        margin: 0px 42px 22px 38px;
    }   

}
 