@import "abstracts/color";
@import "layout/footer";
@import "pages/fastfood";
@import "pages/social";
@import "layout/header";
@import "layout/error404";
@import "pages/fastfood";
@import "pages/terms";
@import "pages/about";
@import "pages/community";
@import "pages/business";
@import "layout/header";
@import "layout/features";
@import "pages/policy";
@import "pages/demo";
@import "pages/help";
@import "pages/checkout";
@import "pages/exploreheader";
@import "pages/responsive";
@import "pages/amealio";
@import "pages/businessterms";
@import "pages/ususerterm";
@import "pages/usprivacy";
@import "pages/usbusinessterms";
@import "pages/features";
@import "pages/menu";
@import "pages/businessreferral";
@import "pages/offerreferral";
@import "pages/refundcancellation";
@import "pages/usertouser";
@import "pages/aboutamealio";
@import "pages/microphone";
@import "pages/message";
@import "pages/mainhomepage";
@import "version4/general";
@import "version4/mobilegeneral.scss";

body {
  margin: 0;
  font-family: "Heebo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper-wrapper {
  justify-content: center;
}

dl,
ol,
ul {
  padding: 0;
}

.bg-black {
  background-color: black;
}

:root {
  --bg-black: #0f0f0f;
  --text: #f8f8f8;
  --mobile-box-shadow: #b0b0b229 0px -5px 6px 0px inset,
    #0000003d 4px 6px 11px 1px inset;
  --mobile-bg: #272b2d;
  --white-bg: #f8f8f8;
  --bg-green: #027757;
  --bg-gray: #272727;
  --link-blue: #7ea2ec;
  --bg-light-gray: #272b2d6e;
  --bg-red: #ee2f4c;
  --bg-purple: rgb(90, 30, 203);
  --bg-blue: rgb(26, 97, 233);
}






//paddings //

.pd-x4 {
  padding-left: 4vw;
  padding-right: 4vw;
}

// texts //
.df12-700 {
  color: black;
  font-size: 20px;
  font-weight: 700;
}
.wf20-700 {
  color: rgb(255, 255, 255);
  font-family: "Mulish", sans-serif;
  font-size: 7.8rem;
  font-weight: 900;
  line-height: 8rem;
  // letter-spacing: 2px;
}
.wf2-700 {
  color: white;
  font-family: "Mulish", sans-serif;
  font-size: 2.8rem;
  font-weight: 900;
}
.wf5-700 {
  color: white;
  font-family: "Mulish", sans-serif;
  font-size: 5rem;
  font-weight: 900;
}
.wf7-700 {
  color: white;
  font-family: "Mulish", sans-serif;
  font-size: 7rem;
  font-weight: 900;
}
.wf6-700 {
  color: white;
  font-family: "Mulish", sans-serif;
  font-size: 6rem;
  font-weight: 900;
}
.wf2-400 {
  color: #ffffff;
  font-family: "Mulish", sans-serif;
  font-size: 2rem;
  font-weight: 400;
}
.wf12-500 {
  color: #ffffff;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.greencolor {
  color: #00d6a0;
}
.hotdealsbtn {
  color: #ef4937;
}
.pd-text {
  padding-left: 10vh;
  padding-top: 12vh;
}

.pd-y12{
padding: 12vh 0;
}
.button-hotdeals {
  color: #ef4937;
  border-radius: 50px;
  padding: 6px 30px;
  border: 4px solid #4e4e50;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 700;
}
.button-download {
  color: #00d6a0;
  border-radius: 50px;
  padding: 12px 30px;
  border: 4px solid #00d6a0;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 700;
}
.button-white {
  color: #ffffff;
  border-radius: 50px;
  padding: 12px 30px;
  border: 4px solid #ffffff;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 900;
  letter-spacing: 2px;
}

// background images //@at-root

.bg-redimg {
  background-image: url("https://media.istockphoto.com/photos/baked-fish-picture-id1137778399?k=20&m=1137778399&s=612x612&w=0&h=FZVyMBfLUNPIMfB3KdbiM-TOHN_rvrjqU2uvn7DPBfk=");
  background-size: cover;
  // opacity: 0.4;
}
.bg-couple-eating-red {
  background-image: url("../../../src/images/new/coupleeatingred.png");
  background-size: cover;
}
.selifeimage {
  background-image: url("../../../src/images/new/newselife.png");
  background-size: cover;
  height: 30vw;
}
.ratingsbg {
  background-image: url("../../../src/images/new/r1.png");
  background-size: cover;
  height: 40vw;
}
.girl-eating-pizza {
  background-image: url("../../../src/images/new/girleatingpizza.png");
  background-size: cover;
  height: 100vh;
}

.ratingssection {
  padding: 10rem 1rem 2rem 16rem;
}
.fleximp {
  display: flex !important;
}
.mb-parallax {
  background-color: black;
  //  width: 100%;
  //  height: 100%;
  //  border: 1px solid red;
  // position: fixed;
  padding: 100px 0 100px 0;
}

.hot-deals {
  padding: 6vh 0 5vh 5vh;
}

//mobile scroll //@at-root

.mobile-scroll {
  position: relative;
  padding-top: 70px;
  padding-bottom: 170px;
}

.scroll-full-screen-wrapper {
  width: 50%;
}

.scroll-full-screen {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mobile-mockup-wrapper {
  width: 50%;
}

.mobile-mockup {
  position: sticky;
  top: 150px;
  transform: translateX(30%);
  width: 350px;
  height: 600px;
  box-shadow: var(--mobile-box-shadow);
  border-radius: 46px;
  padding: 16px 14px;
  max-height: 100vh;
}

.mobile-mockup-screen {
  background-color: var(--mobile-bg);
  overflow: hidden;
  height: 100%;
  border-radius: inherit;
}

.mobile-screen-img {
  height: 100%;
  object-fit: contain;
}

.screen-text {
  opacity: 0;
  padding-bottom: 80px;
}

.text-visible {
  opacity: 1;
  transition: 0.1s;
}

.screen-heading {
  color: white;
  font-family: "Mulish", sans-serif;
  font-size: 8rem;
  font-weight: 900;
  line-height: 80px;
}

.screen-description {
  color: rgb(165, 165, 165);
  font-size: 20px;
  font-weight: 400;
  margin-top: 52px;
}

@media screen and (max-width: 1279px) {
  .mobile-scroll {
    padding-bottom: 30px;
  }
  .mobile-mockup {
    width: 272px;
    height: 480px;
  }
  .screen-heading {
    font-size: 70px;
    line-height: 70px;
  }
  .mobile-mockup {
    transform: translateX(10%);
  }

  .screen-description {
    font-size: 18px;
    margin-top: 44px;
  }
}

@media screen and (max-width: 767px) {
  .mobile-mockup {
    width: 272px;
    height: 480px;
  }
  .screen-heading {
    font-size: 50px;
    line-height: 50px;
  }

  .scroll-full-screen-wrapper {
    width: 100%;
  }
}

@media screen and (min-width: 766px) {
  .only-mobile {
    display: none !important;
  }
  .lg-w-50{
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .max-width {
    max-width: 90%;
  }

  .non-mobile {
    display: none !important;
  }
}

//animations //
.slide-in-right {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 21:27:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: var(--text);
}

.hamburger-box {
  width: 35px;
  height: 21px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 35px;
  height: 4px;
  background-color: var(--text);
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.scale-in-bottom {
  -webkit-animation: scale-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-22 14:47:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
.slider-scroll-bottom {
  .slick-list {
    .slick-slide {
      width: 120px !important;
    }
  }
}

//text animations //@at-root

.tracking-out-contract-bck-top {
  animation: tracking-out-contract-bck-top 0.8s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes tracking-out-contract-bck-top {
  0% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    transform: translateZ(-500px) translateY(-300px);
    opacity: 0;
  }
}

.fade-out-transition {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
}



///mobile css //
/// media queries //


@media screen and (max-width: 767px) {
  .button-white {
    color: #ffffff;
    border-radius: 50px;
    padding: 12px 30px;
    border: 4px solid #ffffff;
    font-family: "Mulish", sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: 900;
    letter-spacing: 2px;
  }
  .wf5-700 {
    font-size: 2rem;
   
}
}