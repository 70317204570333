.microphone-heading {
    h2 {
        color      : #212121;
        font-weight: 600;
        margin     : 40px 0px 20px 0px;
        // padding-top: 60px;
    }
}

.microphone-paragraph {
    ul {
        font-size  : 20px;
        margin     : 0px 2px 20px -10px;
        padding    : 5px 25px 0px 10px;
        font-weight: 500;
    }

    li {
        padding: 20px 0 0 0;
    }
}

.microphone-buttoncontent {
    display        : flex;
    justify-content: flex-end;
}

.microphone-button {
    background-color: #212121;
    color           : white;
    padding         : 5px 25px 5px 25px;
    margin          : 10px 0px 30px 0;
    border-radius   : 5px;
}