.bannerconfigation {
    position: relative;

  

    .bannerexplanations {
        width   : 70%;
        position: absolute;
        bottom  : 60px;

        img {
            width: 130px;
        }

        h1 {
            font-size  : 41px;
            color      : #fff;
            font-weight: 700;
        }

        p {
            font-size: 31px;
            color    : #fff;
            width    : 80%;
        }
    }
}

.deliverycondition {
    h1 {
        color      : black;
        text-align : center;
        line-height: 51px;
        font-size  : 43px;
        color      : #000;
        margin     : 100px 0px 0px 0px;
    }
}
.bannerfeature{
    overflow: hidden;
    img{
        width: 100%;
    }
}
.conditiondescribe {
    margin: 0px 0px 30px 0px;

    img {
        width: 100px;
    }

    h1 {
        font-size  : 30px;
        margin     : 50px 0px 40px 24px;
        color      : #9d9d9d;
        font-weight: 600;
    }

    ul {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: space-between;
        margin-bottom  : 30px;
        text-align     : center;
        position       : relative;
    }

    li {
        list-style   : none;
        width        : 190px;
        margin-bottom: 40px;
        position     : relative;

        .benefit-outlet {
            border          : 3px solid #ee3d23;
            border-radius   : 50%;
            padding         : 68px 28px;
            background-color: #fff;
            position        : relative;
            z-index         : 1;
        }

        img {
            padding: 10px 10px 10px 10px;
        }

        &::after {
            content   : "";
            height    : 1px;
            width     : 120px;
            border-top: 1px #000 dashed;
            display   : block;
            position  : absolute;
            top       : 53px;
            right     : -96px;
        }

        &:last-child::after {
            display: none;
        }
    }

    p {
        font-size: 20px;
        margin   : 40px 0px 0px 0px;
    }
}

.mobileapplication {
    display: flex;
    margin : 60px 0px 30px 0px;
}

.videoapp-bttn {
    padding      : 10px 15px 10px 15px;
    color        : aliceblue;
    border-radius: 10px;
    font-size    : 18px;
    margin-top   : 80px;

    &:hover {
        color: #fff;
    }

    i {
        margin-left: 10px;
    }
}

.mobileappdescribe {
    margin : 80px 0px 0px 0px;
    padding: 64px 0px 0px 54px;

    h1 {
        display    : flex;
        text-align : end;
        line-height: 44px;
    }

    span {
        font-size      : 22px;
        flex-wrap      : wrap;
        display        : flex;
        justify-content: flex-end;
        margin         : 0 0 11px 0;
    }

    li {
        font-size: 26px;
    }
}

.mobilevideoappdescribe {
    h1 {
        margin     : 130px 0px 0px 0px;
        font-size  : 38px;
        font-weight: 600;
    }

    p {
        font-size: 20px;
    }
}

.mobileapp {
    padding: 0;

    img {
        width          : 88%;
        display        : flex;
        justify-content: flex-end;
    }
}

.explane-back {
    background-color: #0000005c;
    position        : absolute;
    top             : 0;
    bottom          : 0;
    left            : 0;
    right           : 0;
}

.hotel {
    display        : flex;
    max-width      : 100%;
    justify-content: space-between;
    margin-bottom  : 60px;
    border-top     : 1px solid #8c8a8a;
    padding        : 70px 0px 0px 0px;

    .hotelbannerdiscribe {
        position     : relative;
        margin-bottom: 30px;

        .hotelbanner {
            position: relative;

            img {
                width        : 100%;
                border-radius: 15px;
            }
        }

        .hoteldescribe {
            text-align      : center;
            left            : 0;
            right           : 0;
            position        : absolute;
            bottom          : 0;
            top             : 0;
            background-color: #00000096;
            border-radius   : 15px;
            padding-top     : 0;
            flex-direction  : column;
            display         : flex;
            vertical-align  : middle;
            text-align      : center;
            justify-content : center;

            h1 {
                font-size: 40px;
                color    : #fff;
                margin   : 0px 0px 20px 0px;
            }

            h6 {
                font-size             : 20px;
                color                 : #fff;
                margin                : 14px 0px 0px 0px;
                position              : relative;
                text-decoration-line  : underline;
                // &::after {
                //     content: "";
                //     height: 1px;
                //     width: 100px;
                //     left: 215px;
                //     justify-content: center;
                //     position: absolute;
                //     border-bottom: 1px solid #e7eaf3;
                //     top: 19px;
                // }
            }

            .private {
                font-size       : 22px;
                color           : #fff;
                background-color: #ee3d23;
                border          : 1px solid #ee3d23;
                border-radius   : 5px;
                padding         : 15px 40px 15px 40px;
                margin          : 0 auto;
                width           : 60%;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .conditiondescribe li {
        &::after {
            display: none;
        }
    }
}

@media screen and (max-width: 1000px) {}

@media screen and (max-width: 992px) {
    .mobileapplication {
        display: block;
    }

    .mobileapp {
        max-width: 100%;
        display  : contents;
    }

    .mobileappdescribe {
        max-width: 100%;
        display  : contents;
    }

    .mobilevideoappdescribe {
        max-width : 100%;
        text-align: center;
        display   : inline-block;
        width     : 100%;
    }

    .mobileapplication .mobilevideoappdescribe h1 {
        margin: 0;
    }

    img.amealiomobilevideo {
        width: 100%;
    }

    .mobileappdescribe h1,
    .mobileappdescribe span {
        text-align: center !important;
        display   : inline-block;
        width     : 100%;
    }
}

@media screen and (max-width: 746px) {
    .bannerconfigation .bannerfeature img {
        width : auto; 
    }
}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 586px) {}

@media screen and (max-width: 480px) {}