.bg-ash {
  background-color: #3a3a3a;
}
.bg-semi-black {
  background-color: #171717;
}
.white-bg{
    background-color: #ffffff;
}

.amealiocolor {
  color: #ef4937 !important;
}

.pd-phone-img{
    padding: 3rem 2rem 2rem 18rem;
}

.pd-x4{
    padding: 0 4rem;
}

.yellow-text {
  color: #fab516;
  font-size: 6rem;
  font-family: "Mulish", sans-serif;
  font-weight: 900;
}

.yellow-text2 {
    color: #fab516;
    font-size: 2.8rem;
    font-family: "Mulish", sans-serif;
    font-weight: 700;
  }

.sharing-text{
    color: #ef4937;
    font-family: "Mulish", sans-serif;
    font-size: 14rem;
    font-weight: 1000;
}
.list-text{
    font-size: 2rem;
    color: #7C7C7C;
}
.ratings-text{
    font-size: 1.4rem;
    color: #ffffff;
    padding: 3rem 2rem;
}
.wf5-900 {
  color: white;
  font-family: "Mulish", sans-serif;
  font-size: 5rem;
  font-weight: 900;
}
.wf10-900{
    color: white;
    font-family: "Mulish", sans-serif;
    font-size: 8rem;
    font-weight: 900;

}

.wf7-900 {
    color: white;
    font-family: "Mulish", sans-serif;
    font-size: 7rem;
    font-weight: 900;
  }

.wf4-900{
    color: white;
    font-family: "Mulish", sans-serif;
    font-size: 4.4rem;
    font-weight: 900;

}

.sharing-align{
    display: flex;
    justify-content: end;
    align-items: center;
}



.red-button {
    color: #ffffff;
    border-radius: 50px;
    padding: 12px 30px;
    border: 4px solid #ef4937;
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    line-height: 24px;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: 700;
  }

  .sharing-meals-slider{
    img{
        padding: 2rem;
        border-radius: 40px;
        width: 380px;
    }
  }



.shareexperience-card{
    color: black;
    padding: 5rem 4rem;
    width: 75%;
    border-radius: 20%;
}
.card-text{
    background-color: #ffffff;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 14px;
    height: 12vh;
}
.amealio-hashtag{
    background-color: #ffffff;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 18px;
    padding-top: 1rem;
}

.expcard-text{
    background-color: #ffffff;
    // border-radius: 40%;
    color: black;
    width: 34rem;
    text-align: center;
    padding: 3rem 0 1rem 0;
    border-radius: 0 0 26px 26px;
 
}
.bg-cardone{
    background-image: url("../../../../src/images/new/s4.png");
    background-size: cover;
    height: 24rem;
    width: 34rem;
    display: flex;
    align-items: flex-end;
}
.bg-cardtwo{
    background-image: url("../../../../src/images/new/burger.jpg");
    background-size: cover;
    height: 24rem;
    width: 34rem;
    display: flex;
    align-items: flex-end;
}
.bg-cardthree{
    background-image: url("../../../../src/images/new/events.jpg");
    background-size: cover;
    height: 24rem;
    width: 34rem;
    display: flex;
    align-items: flex-end;
}

.bg-memcardone{
    background-image: url("../../../../src/images/new/memcard1.jpg");
    background-size: cover;
    height: 24rem;
    width: 34rem;
    display: flex;
    align-items: flex-end;
}
.bg-memcardtwo{
    background-image: url("../../../../src/images/new/memcard2.jpg");
    background-size: cover;
    height: 24rem;
    width: 34rem;
    display: flex;
    align-items: flex-end;
}
.bg-memcardthree{
    background-image: url("../../../../src/images/new/memcard3.png");
    background-size: cover;
    height: 24rem;
    width: 34rem;
    display: flex;
    align-items: flex-end;
}

.ratings-section{
    background-color: rgb(43 11 7);
    ;
}

.media-icons{
    width: 30px;
    padding: 5px;
}
.icons-media{
    width: 120px;
    padding: 40px;
}
.email-last{
    font-size: 22px;
}


// media queries //@at-root

@media screen and (max-width: 767px) {
.red-button {
    color: #ffffff;
    border-radius: 50px;
    padding: 12px 30px;
    border: 4px solid #ef4937;
    font-family: "Mulish", sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: 700;
  }
  .pd-phone-img{
    padding: 3rem 2rem 2rem 6rem;
}
.wf7-900 {

    font-size: 5.4rem;
 
  }
  .sharing-text{
    font-size: 8rem;
}
.sharing-align{
   display: flex;
    justify-content: end;
    align-items: center;
}
.shareexperience-card{
    padding: 5rem 1rem;
}
}

