header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 30;
  top: 0;

  .logo {
    width: 150px;

    img {
      width: 100%;
      margin-top: 20px;
    }
  }

  .tophandling {
    display: flex;
    justify-content: space-between;
  }

  .appbuttonhandling {
    background-color: $secondary-color;
    color: #000;
    border: 1px solid #fff;
    border-radius: 5px;
    font-size: 18px;
    padding: 5px 10px 5px 10px;
    margin: 24px 15px 0px 10px;
  }

  .condition {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 18px;
    padding: 5px 10px 5px 10px;
    margin: 24px 0px 0px 10px;
    width: 120px;
  }

  .buttonmaintance {
    display: flex;
  }

  .dropdown-menu a {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    float: left;
    text-decoration: none;
    color: #000;
    padding: 4px 10px;

    &:hover {
      color: orange;
    }
  }

  .menulisticon a {
    margin: 30px 0px 0px 15px;
    font-size: 24px;
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }
}

.header-logo-img {
  width: 55%;
  height: auto;
}

.dropdown-menu .dropdown-item.active,
.dropdown-item:active {
  background-color: $a-red-color !important;
  color: #fff !important;
}

header.whiteHeader {
  .menulisticon a {
    color: #000;
  }
}

.voiceicon > img {
  padding: 33px 0px 0 15px;
}

.business-menulist {
  color: orange !important;
}

.popupclosebutton {
  display: none;
}

.header-dropdown-button {
  width: 175px !important;
  height: auto !important;
  font-size: 14px !important;
  color: #000 !important;
  background-color: transparent !important;
  font-weight: bold !important;
  font-family: "Muli" !important;
  padding: 10px auto !important;
  border-radius: 50px !important;
  border: 2px solid #000000 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;

  i {
    font-size: 20px !important;
  }

  .drop-text-size {
    font-size: 16px !important;
    color: #000 !important;
    font-weight: bold !important;
    font-family: "Muli" !important;
    background-color: transparent !important;
    border-bottom: 1px solid #ccc !important;
  }
}

.header-download-button {
  width: 175px;
  height: auto;
  background-color: $a-download-color;
  border: 1px solid #20ce3a;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  font-family: "Muli";
  padding: 10px auto;
  border-radius: 50px;
}

.mobile-hamburger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .header-logo-img {
    width: 40% !important;
  }

  .navbar-brand {
    margin-right: 0rem !important;
  }

  .mobile-hamburger {
    position: fixed;
    top: 0;
    z-index: 19;
    background-color: #fff;
    justify-content: center;
    text-align: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .dropdown-menu {
    left: 8% !important;
    right: 8% !important;

    .drop-text-size {
      .dropdown-item {
        padding: 12px !important;
      }
    }
  }

  .custom-header {
    display: flex;
    align-items: center;
    // justify-content: start;
    justify-content: flex-start;
  }

  .active-menu-item-dropdown {
    border-bottom: 4px solid $a-orange-color;
    margin: 0 50px;
  }

  .menu__box {
    .active-menu-item {
      padding: 0 75px !important;

      .menu__item {
        border-bottom: 2px solid $a-orange-color;
      }
    }
  }
}

@media (max-width: 550px) {
  .application {
    display: block;
    position: fixed;
    z-index: 25;
    left: 38%;
    bottom: 5%;
  }

  .mobilescreengetapp {
    display: none;
  }

  .dropdown-toggle.appbuttonhandling {
    font-size: 14px;
    margin: 0px !important;
    padding: 5px 5px 5px 5px;
  }

  header .appbuttonhandling {
    background-color: black;
    color: white;
    border: black solid;
    border-radius: 20px;
    font-size: 13px;
    margin: 0px;
  }
  .dropdown .dropdown-menu .dropdown-item.active,
  .dropdown-item:active {
    background-color: $a-red-color !important;
    color: #fff !important;
  }

  // .dropdown-menu {
  //     position                  : absolute;
  //     top                       : 100%;
  //     left                      : 0;
  //     z-index                   : 1000;
  //     display                   : none;
  //     min-width                 : 230px;
  //     padding                   : 5px 0;
  //     margin                    : 2px 0 0;
  //     font-size                 : 14px;
  //     text-align                : center;
  //     list-style                : none;
  //     background-color          : #fff;
  //     -webkit-background-clip   : padding-box;
  //     background-clip           : padding-box;
  //     border                    : 1px solid #ccc;
  //     border                    : 1px solid rgba(0, 0, 0, .15);
  //     border-radius             : 4px;
  //     // -webkit-box-shadow     : 0 6px 12px rgb(0 0 0 / 18%);
  //     // box-shadow             : 0 6px 12px rgb(0 0 0 / 18%);
  // }

  // .dropdown-menu {
  //     transform: translate3d(-63px, -314px, 0px) !important;
  // }

  // .dropdown-menu .show {
  //     position   : absolute;
  //     transform  : translate3d(-17px, -208px, 0px);
  //     top        : 0px;
  //     left       : 0px;
  //     will-change: transform;
  // }

  .popupclosebutton {
    display: block;

    button {
      font-size: 14px;
      margin: 0px;
      padding: 0px 12px;
      position: absolute;
      bottom: -40px;
      left: 32%;
      background-color: black;
      color: white;
      border: black solid;
      border-radius: 20px;
      opacity: 1;
    }
  }

  header .dropdown-menu a {
    font-size: 17px;
    font-weight: 300;
    width: 100%;
    float: left;
    text-decoration: none;
    color: #000;
    padding: 5px 10px;
  }
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  border-bottom: 3px solid $a-orange-color;
}

#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}
.menu__btn {
  position: absolute;
  top: 42px;
  right: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: $a-orange-color;
  // background: rgb(221,86,66);
  // background: linear-gradient(172deg, rgba(221,86,66,1) 0%, rgba(241,127,110,1) 50%, rgba(238,184,78,1) 100%);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.25s;
}
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
}
.menu__item:hover {
  background-color: #cfd8dc;
}
