.offer-heading{
    h2{
    margin: 30px 0px 20px 0px;
    padding-top: 60px;
}
}
.offer-paragraph{
    p{
font-size: 12px;
margin: 0px 2px 20px -10px;
padding: 5px 25px 0px 10px;
font-weight: 600;
}
}