.f65-h1{
    font-family: 'Muli'; 
    font-size: 65px;
    line-height: 85px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: bold;
    color: #212121;
}

.f50-h2{
    font-family: 'Muli'; 
    font-size: 50px;
    line-height: 65px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: bold;
    color: #212121;
}

.f42-h3{
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: 'Muli'; 
    font-size: 42px;
    line-height: 52px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: bold;
    color: #212121;
}

.f32-h4{
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: 'Muli'; 
    font-size: 32px;
    line-height: 45px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: bold;
    color: #212121;
}

.f24-h5{
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: 'Muli'; 
    font-size: 24px;
    line-height: 32px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: bold;
    color: #212121;
}

.f18-h6{
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: 'Muli'; 
    font-size: 18px;
    line-height: 24px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: bold;
    color: #212121;
}

.f24-p1{
    font-family: 'Muli'; 
    font-size: 24px;
    line-height: 38px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: bold;
    color: #212121;
}

.f20-p{
    font-family: 'Muli'; 
    font-size: 20px;
    line-height: 29px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 500;
    color: #212121;
}


.f18-p2{
    font-family: 'Muli'; 
    font-size: 18px;
    line-height: 24px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 500;
    color: #212121;
}

.f16-p3{
    font-family: 'Muli'; 
    font-size: 16px;
    line-height: 22px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 500;
    color: #212121;
}

.f14-p4{
    font-family: 'Muli'; 
    font-size: 14px;
    line-height: 19px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 500;
    color: #212121;
}

.f12-p3{
    font-family: 'Muli'; 
    font-size: 12px;
    line-height: 17px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 500;
    color: #212121;
}

.mobileexperience {
    background-color: #5c9dc2;
    color           : white;
    text-align      : center;
    padding         : 30px 0 0px 0;
}

.mobile-mockup1 {
    display: none;
}

.explore-foodresize {
    width: 100%;
}

.slidervideo-section {
    // padding: 25px 0 60px 0;

// p{
//     color: #283136;
//     font-size: 14px;
//     font-weight: 600;
//     margin-top: 25px;
//     text-align: center;
//     // font-family: emoji;
//     // letter-spacing: 1px;
// }
}
.slidervideo {
    text-align: -webkit-center;
    margin    : 45px 0 0 0;
}

.slidervideo1 {
    text-align: -webkit-center;
    margin    : 45px 0 0 0;
}

.slidervideo2 {
    text-align: -webkit-center;
    margin    : 45px 0 0 0;
}

// .slick-prev {
//     position: absolute;
//     display: none;
//     padding : 0;
//     left    : 0px;
// }

.slick-next {
    right: -19px;
}

// .slick-next,
// .slick-prev {
//     display: none;
// }

.slick-next:before, .slick-prev:before {
    font-size: 25px;
    line-height: 1;
    opacity: .75;
    color: #252121;
}

.mobileexperience-h1 {
    font-size  : 38px;
    font-weight: bold;
    margin     : 0 0 20px 0;
}

.mobileexperience-p {
    font-size: 17px;
}

.mobileexperience-h3 {
    margin     : -19px 0 0px 0;
    padding    : 0px 0 42px 0;
    font-size  : 36px;
    font-weight: 600;
}

.explorefeatures-img {
    margin: -100px 0 0px 0;
}

// .explore-round {
//     width        : 150px;
//     height       : 150px;
//     background   : transparent;
//     border       : 2px dashed #488cb2;
//     border-radius: 100px;
// }

.explore-round2 {
    width        : 110px;
    height       : 110px;
    background   : white;
    margin       : auto;
    margin       : 15px 15px 0px 0px;
    /* padding   : 16px; */
    border-radius: 56px;
    border       : 2px solid #488cb2;
}

.explore-align {
    text-align: -webkit-right;
    margin    : -80px 50px 0px 0;
}

.explorefeature {
    background: transparent radial-gradient(closest-side at 51% 50%, #9ef259 0%, #9cf155 51%, #6fd21e 100%) 0% 0% no-repeat padding-box;
    color     : white;
}

.explore-h1 {
    font-size  : 38px;
    font-weight: 600;
    margin     : 60px 0 60px 0;
    width      : 75%;
}

.explore-p {
    font-size: 18px;
    padding  : 0 70px 25px 0;
}

.features-button {
    background-color: #ee3d23;
    color           : white;
    padding         : 10px 40px 10px 40px;
    border-radius   : 10px;
    font-size       : 18px;
    margin          : 50px 0 32px 0;
    border          : none;
}

.ctrl-nxt {
    padding-left: 170px;
}

.ctrl-prv {
    padding-right: 170px;
}

.explore-btn {
    background-color: #ee3d23;
    color           : white;
    padding         : 10px 40px 10px 40px;
    border-radius   : 10px;
    font-size       : 18px;
    margin          : 32px 0 32px 0;
}

.whyamealio-i {
    font-size: 30px;
    color    : black;
}

.whyamealio-ul {
    display        : flex;
    list-style-type: none;
    justify-content: space-around;
    flex-wrap      : wrap;
    padding: 75px 0px
}

.whyamealio-card {
    width           : 400px;
    background-color: $a-red-color;
    margin: 0 auto;
}

.whyamealio-card1 {
    width           : 400px;
    background-color: $a-red-color;
}

.whyamealio-box {
    background-color: $a-red-color;
    margin          : 0;
    // box-shadow      : 4px 3px 6px 1px #ccc;
    position: relative;

    .bottom-thinkfood-sprial{
        position: absolute;
        bottom: -12px;
    }
}

.whyamealio-h3 {
    text-align : center;
    font-weight: 600;
    /* margin  : 35px 0 25px 0; */
    margin     : auto;
    width      : 70%;
    padding    : 30px 0 25px 0;
    color      : black;
}

.whyamealio-p {
    font-size : 16px;
    text-align: center;
    padding   :28px 40px 35px 40px;
    color     : #fff;
}

.vl {
    border-left: 3px solid #75b0d2;
    height     : 420px;
}

.whyamealio-align {
    text-align: -webkit-center;
    margin    : -78px 0px 0px 0;
}

.whyamealio-round {
    width        : 110px;
    height       : 110px;
    background   : #ffffff;
    border       : 2px dashed $a-red-color;
    border-radius: 100px;
}

.whyamealio-round2 {
    width        : 90px;
    height       : 90px;
    background   : white;
    margin       : auto;
    margin       : 10px 0px 0px 0px;
    padding   : 16px;
    border-radius: 56px;
    // border       : 3px solid #9ef259;
}

.whyamealio-align1 {
    text-align: -webkit-center;
    margin    : -58px 0px 0px 0;
}

.whyamealio-round1 {
    width        : 110px;
    height       : 110px;
    background   : #ffffff;
    border       : 2px dashed $a-red-color;
    border-radius: 100px;
}

.whyamealio-round21 {
    width        : 90px;
    height       : 90px;
    background   : white;
    margin       : auto;
    margin       : 10px 0px 0px 0px;
    padding   : 16px;
    border-radius: 56px;
    // border       : 3px solid #75b0d2;
}

.whyamealio-h1 {
    text-align : center;
    font-weight: 600;
    padding    : 20px 0 26px 0;
    color      : black;
}

.whyamealio-li {
    padding: 70px 0 0 0;
}

.whyamealio-li1 {
    padding: 70px 0 0 0;
}

.mobileexperience-imghover {
    position: absolute;
}

// .whyamealio-li1:hover {
//     background-color: rgba(147, 155, 231, 1);
//     width           : 45%;
//     text-align      : -webkit-center;
// }

// .whyamealio-li:hover {
//     background-color: rgba(198, 234, 255, 1);
//     width           : 45%;
//     text-align      : -webkit-center;
// }

.vlslider {
    border-left: 3px solid #75b0d2;
    height     : 420px;
    margin     : 0px 0px 0 175px;
}

.connect-h1 {
    text-align : center;
    width      : 80%;
    margin     : auto;
    padding    : 35px 0 15px 0;
    font-weight: 600;
    color      : black;
}

.discover-section {
    background: transparent radial-gradient(closest-side at 50% 50%, #939be7 0%, #979ee6 53%, #5b64c4 100%) 0% 0% no-repeat padding-box;
    padding   : 50px 0 50px 0;
    color     : white;
}

.discover-box {
    width           : 115px;
    height          : 80px;
    background-color: white;
    border-radius   : 10px;

    img {
        padding-top: 12px;
        margin-left: 28px;
    }
}

.experience-card {
    border       : none;
    box-shadow   : 0px 0px 6px 2px #9fddff;
    border-radius: 18px;
}

.experience-row {
    text-align: center;
    margin    : 0;
}

.experience-section {
    padding: 30px 0 40px 0px;
}

.experience-col {
    padding: 35px 0 50px 0;
}

.experience-h1 {
    font-weight: 600;
    color      : black;
}

.experience-head {
    text-align : center;
    font-weight: 600;
    padding    : 0px 0 50px 0;
    color      : black;
}

.experience-btn {
    background-color: #fab516;
    color           : white;
    padding         : 13px 40px 13px 40px;
    border-radius   : 11px;
    box-shadow      : 0px 1px 5px 1px #ccc;
    margin          : 50px 0 0 0;
    font-size       : 18px;
}

.mobileexperience-align {
    position: relative;
    z-index : 2;
}

.mobileexperience-img {
    position: absolute;
}

.carousel-indicators li {
    display         : inline-block;
    width           : 12px;
    height          : 12px;
    margin          : 1px;
    text-indent     : -999px;
    cursor          : pointer;
    border          : none;
    background-color: #dadada;
    border-radius   : 13px;
}

.carousel-indicators .active {
    width           : 12px;
    height          : 12px;
    margin          : 1px 2px 0px 2px;
    background-color: white;
}

.foundation-section {
    padding   : 75px 0 75px 0;
    background: transparent radial-gradient(closest-side at 50% 50%, var(--unnamed-color-ffffff) 0%, #C6EAFF 78%, #A0DDFF 100%) 0% 0% no-repeat padding-box;
    background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #C6EAFF 78%, #A0DDFF 100%) 0% 0% no-repeat padding-box;
    opacity   : 1;
}

.foundation-logo {
    padding: 0 0 20px 0;
}

.foundation-h1 {
    font-size  : 30px;
    font-weight: bold;
    padding    : 0 0 10px 0;
    color      : black;
}

.foundation-p {
    font-size: 18px;
    padding  : 0 0 47px 0;
    color    : black;
}

.foundation-btn {
    background-color: #fab516;
    color           : white;
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    width     : 100%;
    margin    : 75px 0 0 0;
    background: transparent radial-gradient(closest-side at 50% 50%, var(--unnamed-color-ffffff) 0%, #C6EAFF 78%, #A0DDFF 100%) 0% 0% no-repeat padding-box;
    background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #C6EAFF 78%, #A0DDFF 100%) 0% 0% no-repeat padding-box;
    opacity   : 1;
}
.carousel-inner>.active{
    margin: 0%;
}

.explorefeatures-image {
    width : 75px;
    margin: 22px 16px 0px 0;
}

.location-section {
    background-color: white;
    padding         : 30px 0 30px 0;
}

.location-h1 {
    text-align : center;
    font-weight: bold;
    padding    : 0 0 10px 0;
    color      : black;
}

.location-p {
    font-size : 20px;
    text-align: center;
    padding   : 0 0 30px 0;
    color     : black;
}

/////// Mouse Scrolling Animation
.position-of-mouse{
    position: relative;
    bottom: 170px;
    left: 49%;

    #scrolling-mouse {
        position: relative;
        display: inline-block;
        height: 40px;
        width: 25px;
        border: solid 2px #707070;
        border-radius: 20px;
        text-align: center;
      }
      #scrolling-mouse::before {
        content: "";
        position: absolute;
        display: block;
        height: 8px;
        top: 25%;
        left: 50%;
        width: 8px;
        border-right: solid 2px #707070;
        border-bottom: solid 2px #707070;
        animation-name: arrow;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
      }
      #scrolling-mouse::after {
        content: "";
        position: relative;
        display: inline-block;
        height: 5px;
        width: 3px;
        background-color: #000;
        border-radius: 2px;
        animation-name: scroll;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
      }

      @keyframes scroll{
        0%{
          height: 2px;
          opacity: 0;
          transform: translateY(0px);
        }
        20%{
          height: 8px;
          opacity: 1;
        }
        80%{
          height: 8px;
        }
        100%{
          height: 2px;
          opacity: 0;
          transform: translateY(15px);
        }
      }
      @keyframes arrow{
        0%{
          opacity: 0;
          transform: translateX(-50%) translateY(0px) rotate(45deg);
        }
        20%{
          opacity: 1;
        }
        100%{
          opacity: 0;
          transform: translateX(-50%) translateY(20px) rotate(45deg);
        }
      }
}

.animate-slider-div {
    margin  : -155px 0 45px 0;
    position: relative;
    z-index : 9;
}

.animate-slider {
    display        : flex;
    justify-content: center;
    list-style-type: none;
    z-index        : 9;

    li {
        background-color: gray;
        width           : 12px;
        height          : 12px;
        border-radius   : 20px;
        cursor          : pointer;
        margin          : 0 3px 0 0;
    }

    .active{
        background-color:rgb(255, 255, 255);
        color:rgb(255, 255, 255);
        border: 1px solid #000;
        border-radius: 50px;
    }
}



.whyamealio-img1 {
    margin: 16px 0 0 0;
}

.whyamealio-img2 {
    margin: 20px 0 0 0;
}

.connect-p {
    font-size : 30px;
    text-align: center;
    color     : black;
}

.discover-h1 {
    font-weight: bold;
    line-height: 55px;
}

.discover-p {
    font-size: 18px;
    padding  : 0 0 40px 0;
}

.discover-col {
    text-align: left;
    font-size : 18px;
    padding   : 0 0 40px 15px;
}

.discover-para {
    p {
        width    : 105%;
        font-size: 17px;
    }

}

img.disimg {
    display: none;
}

h1.discover-h1.disp {
    display: none;
}

.slide-button {
    top : 650px;
    left: 335px;
}

.slide-img {
    float      : right;
    width      : 100%;
    overflow   : hidden;
    padding-top: 200px;
}

.slide-carousal {
    width: 100%;
}

.amealia-pad {
    img {
        width     : 100%;
        padding   : 0;
        margin-top: -105px;
    }
}

.whyamealio {
    color    : black;
    font-size: 35px;
}

.discover-col2 {
    text-align: -webkit-center;
    font-size : 18px;
    padding   : 0 0 40px 0;
    margin    : -25px 0 0 0;
}

.discover-img {
    margin: 0 0 0 20px;
    height: 60%;
    width : 125%;
}

.discover-amealia {
    height: 30%;
    width : 60%;
    margin: 41px 0 0 270px;
}

.discover-img2 {
    margin: -200px 0 0 0;
}

.slick-next,
.slick-prev {
    // display: none !important;
    color: #000;
}

.discover-btn {
    background-color: #f1b516;
    color           : white;
    margin-left     : 5px;
}

.discover-btn:hover {
    border          : 2px solid #f1b516;
    color           : white;
    background-color: transparent;
}

.happycustomer-section {
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    background-color: #F6F6F6;
    height: auto;
    width: 100%;
    position: relative;
    padding-top: 40px;

    .happycustomer-hours-slider{
        width: 90%;
        margin: auto;

        .slick-list{
            padding: 30px 50px 50px 50px !important;
        }
    }
}

.happycustomer-h1 {
    text-align : center;
    font-weight: bold;
    color      : black;
    padding    : 0px 0 25px 0;
}

.happycustomer-align {
    padding   : 20px 20px 14px 20px;
    color     : black;
    text-align: center;

    .happycustomer-align-para{
        font-family: 'Muli';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #000;
    }

    .happycustomer-align-paraBig{
        font-family: 'Muli';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #FFF;
        text-align: center;
    }

    .paragraph-color-purple{
        color : $primary-color;
    }

    .paragraph-color-white{
        color : $secondary-color;
    }

}

.happycustomer-align1 {
    padding  : 20px 20px 14px 20px;
    color    : white;
    font-size: 16px;
}

.happycustomer-row {
    align-items: center;
}
.happycustomer-tabindex{
    width: 100%;
    display: inline-block;
}

.slick-dots li {
    position: relative;
    display : inline-block;
    width   : 15px;
    height  : 20px;
    margin  : 0px;
    padding : 0;
    cursor  : pointer;
}

.slick-dots li button {
    font-size  : 0px;
    line-height: 0;
    display    : block;
    width      : 20px;
    /* height  : 2px; */
    padding    : 5px;
    cursor     : pointer;
    color      : transparent;
    border     : 0;
    outline    : 0;
    background : 0 0;
}

.slick-dots li button:before {
    font-size  : 12px;
    line-height: 20px;
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 20px;
    height     : 20px;
    content    : '•';
    text-align : center;
    opacity    : .25;
    color      : #000;
}

.happycustomer-imgcenter {
    height: 306px;
}

.happycustomer-imgalign {
    z-index   : 22;
    position  : relative;
    text-align: center;
    width     : 64%;
    font-size : 13px;
    margin    : auto;
    margin-top: -212px;
    padding   : 0 0 70px 0px;
    color     : white;
}


.slick-list {
    overflow: hidden;
    margin  : 0;
    padding : 0;
    padding : 20px 0 0px 0;
}

.happycustomer-box {
    background-color: white;
    border-radius   : 25px;
    width           : 80%;
    text-align      : center;
    margin          : auto;
}

.happycustomer-box1 {
    background   : #ee3d23 0% 0% no-repeat padding-box;
    box-shadow   : 8px 8px 14px #00000029;
    border-radius: 25px;
    color        : white;
    width        : 130%;
}

.happycustomer-box2 {
    background-color: white;
    box-shadow      : 8px 8px 14px #00000029;
    border-radius   : 25px;
    width           : 45%;
    text-align      : center;
    padding         : 20px 0px 20px 0;
    margin          : 0 0 0 130px;
}

.happycustomer-box4 {
    background-color: white;
    box-shadow      : 8px 8px 14px #00000029;
    border-radius   : 25px;
    width           : 45%;
    text-align      : center;
    padding         : 20px 0px 20px 0;
    margin          : -100px 0 0px -170px;
}

.happycustomer-box3 {
    background-color: #FFFFFF !important;
    border-radius   : 50% !important;
    width           : 300px !important;
    height          : 300px !important;
    padding : 20px !important;
    // box-shadow : 0px 3px 6px #00000029 !important;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.happycustomer-box5 {
    background   : $a-orange-color 0% 0% no-repeat padding-box;
    // box-shadow   : 8px 8px 14px #00000029;
    border-radius: 25px;
    color        : white;
    width        : 400px !important;
    height : 400px !important;
    padding : 20px !important;
    border-radius   : 50% !important;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.happycustomer-top-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 22%;
    height: auto;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
}

.happycustomer-bottom-img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 22%;
    height: auto;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
}

.happycustomer-col1 {
    margin : 30px 0 0 0;
    padding: 0px 0 100px 35px;
}

.happycustomer-col2 {
    margin : -30px 0 0 0;
    padding: 0px 0 30px 20px;
}

.happycustomer-col3 {
    padding: 80px 0 0 0;
}

.happycustomer-col4 {
    margin: 0 0px 0px -150px;
}

.happycustomer-img {
    width  : 60px;
    padding: 10px 0px 10px 0;
}

.gif {
    padding-top: 50px;
    height     : 100vh;

    .Chilli {
        display        : flex;
        justify-content: space-around;

        .icon1 {
            img {
                width              : 200px;
                opacity            : 0;
                animation          : leaf;
                animation-duration : 4s;
                animation-fill-mode: forwards;
                animation-delay    : 2s;
                position           : absolute;
            }
        }

        .icon2 {
            img {
                width              : 150px;
                padding-top        : 60px;
                opacity            : 0;
                animation          : leaf;
                animation-duration : 4s;
                animation-fill-mode: forwards;
                animation-delay    : 2s;
                float              : right;
            }
        }
    }

    .Leaf1 {
        float: right;

        img {
            padding-top        : 50px;
            width              : 60px;
            transform          : rotateY(180deg);
            opacity            : 0;
            animation          : leaf;
            animation-duration : 4s;
            animation-fill-mode: forwards;
            animation-delay    : 2s;
        }
    }

    .Leaf2 {
        padding-top: 50px;

        img {
            width              : 60px;
            opacity            : 0;
            animation          : leaf;
            animation-duration : 4s;
            animation-fill-mode: forwards;
            animation-delay    : 2s;
            float              : left;
        }
    }
}

.banner {
    h1 {
        font-size   : 100px;
        font-weight : bolder;
        color       : #717171;
        padding-top : 100px;
        padding-left: 50px;
        margin      : 0;
        animation   : banner 4s forwards;
        position    : absolute;
    }
}

.Car {
    float  : right;
    z-index: 25;

    img {
        width                    : 200px;
        opacity                  : 0;
        animation-name           : car;
        animation-duration       : 11s;
        animation-fill-mode      : forwards;
        animation-timing-function: ease-in-out;
        animation-delay          : 6s;
        position                 : absolute;
        padding-top              : 180px;
        right                    : 0;
        z-index                  : 25;
    }
}

@keyframes banner {
    0% {
        transform: scale(0, 0);
        opacity  : 1;
    }

    100% {
        transform: scale(1, 1);
        opacity  : 1;
    }
}

@keyframes leaf {
    0% {
        transform: scale(0, 0);
        opacity  : 1;
    }

    100% {
        transform: scale(1, 1);
        opacity  : 1;
    }
}

@keyframes Sashmi {
    0% {
        transform: scale(0, 0);
        opacity  : 1;
    }

    100% {
        transform: scale(1, 1);
        opacity  : 1;
    }
}

@keyframes car {
    0% {
        transform: scale(0, 0);
        right    : 0%;
        opacity  : 1;
        z-index  : 25;
    }

    10% {
        transform: scale(1, 1);
        opacity  : 1;
        right    : 24%;
        width    : 460px;
        z-index  : 25;
    }

    90% {
        transform: scale(1, 1);
        opacity  : 1;
        right    : 24%;
        width    : 460px;
    }

    100% {
        transform: scale(1, 1);
        opacity  : 1;
        right    : 100%;
        width    : 460px;
    }
}

.bagcontent {
    padding-left       : 800px;
    padding-top        : 60px;
    animation-name     : bag-x-axis;
    animation-duration : 5s;
    animation-fill-mode: forwards;
    position           : absolute;
    animation-delay    : 7s;
}

.bag {
    padding-top        : 200px;
    position           : absolute;
    animation-name     : bag-y-axis;
    animation-duration : 5s;
    animation-fill-mode: forwards;
    animation-delay    : 7s;

    img {
        width              : 100px;
        animation          : bagimage;
        animation-duration : 5s;
        animation-fill-mode: forwards;
        animation-delay    : 7s;
        position           : absolute;
        opacity            : 0;
    }
}

@keyframes bag-x-axis {
    100% {
        transform: translatex(-440px);
    }
}

@keyframes bag-y-axis {
    50% {
        transform: translatey(-200px);
    }

    100% {
        transform: translatey(-150px);
    }
}

@keyframes bagimage {
    0% {
        width  : 0px;
        opacity: 1;
    }

    98% {
        width  : 230px;
        opacity: 1;
    }

    100% {
        width  : 230px;
        opacity: 0;
    }
}

.Pancakecontent {
    animation-name     : Pancake-x-axis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    position           : relative;
    animation-delay    : 10s;
    width              : 200px;
    z-index            : 21;
}

.Pancake {
    position           : relative;
    float              : right;
    animation-name     : Pancake-y-axis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    animation-delay    : 10s;
    z-index            : 21;

    img {
        width              : 100%;
        opacity            : 0;
        animation          : Sashmi;
        animation-duration : 5s;
        animation-delay    : 4s;
        animation-fill-mode: forwards;
        position           : relative;
    }
}

@keyframes Pancake-x-axis {
    80% {
        opacity: 1;
    }

    100% {
        transform: translate(-540px);
        z-index  : 5;
        width    : 100px;
        opacity  : 0;
    }
}

@keyframes Pancake-y-axis {
    50% {
        transform: translatey(-75px);
    }

    100% {
        transform: translatey(140px);
    }
}

.Sashmicontent {
    animation-name     : Sashmi-x-axis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    position           : relative;
    animation-delay    : 10s;
    z-index            : 21;
}

.Sashmi {
    animation-name     : Sashmi-y-axis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    animation-delay    : 10s;
    z-index            : 3;
    width              : 200px;
    z-index            : 21;

    img {
        width              : 100%;
        padding-top        : 330px;
        opacity            : 0;
        animation          : Sashmi;
        animation-duration : 4s;
        animation-fill-mode: forwards;
        animation-delay    : 4s;
        z-index            : 21;
    }
}

@keyframes Sashmi-x-axis {
    80% {
        opacity: 1;
    }

    100% {
        transform: translate(460px, 80px);
        z-index  : 21;
        width    : 100px;
        opacity  : 0;
    }
}

@keyframes Sashmi-y-axis {
    50% {
        transform: translatey(-300px);
        z-index  : 21;
    }

    100% {
        transform: translatey(-280px);
        z-index  : 21;
    }
}

.Coffecontent {
    animation-name     : Coffee-x-axis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    position           : relative;
    animation-delay    : 10s;
}

.Coffee {
    position           : relative;
    animation-name     : Coffee-y-axis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    animation-delay    : 10s;
    width              : 200px;
    z-index            : 21;

    img {
        width              : 100%;
        margin-left        : 400px;
        opacity            : 0;
        animation          : Coffeeimage;
        animation-duration : 4s;
        animation-fill-mode: forwards;
        animation-delay    : 4s;
    }
}

@keyframes Coffeeimage {
    0% {
        transform: scale(0, 0);
        opacity  : 1;
    }

    100% {
        transform: scale(1, 1);
        opacity  : 1;
    }
}

@keyframes Coffee-x-axis {
    80% {
        opacity: 1;
    }

    100% {
        transform: translatex(60px);
        z-index  : 4;
        width    : 100px;
        opacity  : 0;
    }
}

@keyframes Coffee-y-axis {
    50% {
        transform: translatey(150px);
    }

    100% {
        transform: translatey(260px);
    }
}

@media screen and (max-width: 767px) {
    .restaurants-col img {
        width: 90%;
    }

    .happycustomer-col1 {
        margin    : 30px 0 0 0;
        /* padding: 0px 0 100px 35px; */
        padding   : 0px;
    }

    .happycustomer-box1 {
        background   : #ee3d23 0% 0% no-repeat padding-box;
        box-shadow   : 8px 8px 14px #00000029;
        border-radius: 25px;
        color        : white;
        width        : auto;
    }

    .happycustomer-col2 {
        margin : -30px 0 0 0;
        padding: 0px 0 30px 20px;
        display: none;
    }

    .happycustomer-col3 {
        padding: 30px 0 0 0;
    }

    .happycustomer-box3 {
        background-color: white;
        box-shadow      : 8px 8px 14px #00000029;
        border-radius   : 25px;
        /* width        : 280px; */
        width           : auto;
    }

    .happycustomer-col5 {
        display: none;
    }

    .happycustomer-col4 {
        /* margin: 0 0px 0px -150px; */
        margin : 0px;
        padding: 30px 0 0 0;
    }
}

.Curbsidecontent {
    padding-left       : 430px;
    animation-name     : Curbside-x-axis;
    animation-duration : 6s;
    animation-fill-mode: forwards;
    position           : absolute;
    animation-delay    : 12s;
}

.Curbside {
    padding-top        : 150px;
    position           : absolute;
    animation-name     : Curbside-y-axis;
    animation-duration : 5s;
    animation-fill-mode: forwards;
    animation-delay    : 12s;

    img {
        width              : 160px;
        animation          : Curbsideimage;
        animation-duration : 5s;
        animation-fill-mode: forwards;
        animation-delay    : 12s;
        position           : absolute;
        opacity            : 0;
    }
}

@keyframes Curbside-x-axis {
    100% {
        transform: translatex(440px);
        opacity  : 1;
    }
}

@keyframes Curbside-y-axis {
    50% {
        transform: translatey(-30px);
    }

    100% {
        transform: translatey(80px);
    }
}

@keyframes Curbsideimage {
    0% {
        opacity: 1;
    }

    80% {
        width  : 50px;
        opacity: 0;
    }

    100% {
        width  : 0px;
        opacity: 0;
    }
}

// home page animation 2
.banneranime2 {
    position: absolute;
    z-index: 99;
}

.dinning {
    height     : 800px;
    padding-top: 100px;
    // position   : relative;

    h1 {
        width      : 50%;
        font-size  : 50px;
        padding-top: 150px;
        font-weight: 700;
        color      : black;
    }

    p {
        font-size    : 25px;
        color        : black;
        margin-bottom: 2rem;
    }

    button {
        padding      : 10px 30px;
        border-radius: 10px;
        background   : black;
        z-index: 20;
    }
}

.Table {
    position    : absolute;
    padding-left: 530px;
    padding-top : 100px;
    z-index     : 0;

    img {
        width: 480px;
    }
}

button.btn.btn-dark:hover {
    color: #fff;
}
.Burger {
    padding-left       : 410px;
    position           : absolute;
    animation-name     : Burgeraxis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    animation-delay    : 3s;
    z-index            : 3;

    img {
        width    : 130px;
        animation: Burgeraxisimage 2s forwards 2;
    }
}

@keyframes Burgeraxisimage {

    50% {
        transform: translateY(-20px);
    }

}


@keyframes Burgeraxis {

    30% {
        transform: translate(0px, 0px);
    }

    40% {
        opacity: 1;
    }

    60% {
        transform: translate(300px, 196px);
    }


    100% {
        opacity  : 0;
        transform: translate(300px, 196px);
    }
}


.Pasta {
    padding-top        : 400px;
    padding-left       : 160px;
    position           : absolute;
    animation-name     : Pastaaxis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    animation-delay    : 3s;
    z-index            : 2;

    img {
        width    : 105px;
        animation: Pastaaxisimage 2s forwards 2;
    }
}

@keyframes Pastaaxisimage {

    50% {
        transform: translateY(20px);
    }

}


@keyframes Pastaaxis {


    30% {
        transform: translate(0px, 0px);
    }

    30% {
        opacity: 1;
    }


    60% {
        transform: translate(490px, -201px);
    }

    100% {
        opacity  : 0;
        transform: translate(490px, -201px);
    }
}


.Dessert {
    position           : absolute;
    float              : right;
    animation-name     : Dessertaxis;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    animation-delay    : 3s;
    z-index            : 2;
    right              : 0px;

    img {
        width    : 51px;
        animation: Dessertaxisimage 2s forwards 2;
    }
}


@keyframes Dessertaxisimage {

    50% {
        transform: translateY(20px);
    }

}


@keyframes Dessertaxis {

    30% {
        transform: translate(0px, 0px);
    }

    40% {
        opacity: 1;
    }


    60% {
        transform: translate(-269px, 182px);
    }

    100% {
        transform: translate(-269px, 182px);
        opacity  : 0;
    }
}

////anime3
.anime3 {
    position: relative;
    height  : 96vh;
    // height  : 700px;
    // margin  : 100px 0 0 0;
}

.anime3-leaf1 {
    position   : absolute;
    padding-top: 120px;

    img {
        width: 60px;
    }
}

.anime3-coffee {
    padding-left: 410px;
    position    : absolute;

    img {
        width: 200px;
    }
}

.anime3-chilli1 {
    position    : absolute;
    padding-top : 30px;
    padding-left: 720px;

    img {
        transform: scaleX(-1);
        width    : 110px;
    }
}

.anime3-leaf2 {
    position   : relative;
    padding-top: 60px;
    float      : right;
    right      : 0;

    img {
        width    : 60px;
        transform: scaleX(-1);
    }
}

.anime3-banner {
    position   : absolute;
    padding-top: 200px;

    h1 {
        font-size    : 62px;
        font-weight  : 600;
        margin-bottom: 2rem;
    }

    p {
        font-size: 26px;
    }
}

.anime3-chilli2 {
    position    : absolute;
    padding-top : 530px;
    padding-left: 300px;

    img {
        width: 140px;
    }
}

.anime3-chilli3 {
    position    : absolute;
    padding-top : 550px;
    padding-left: 830px;

    img {
        width    : 80px;
        transform: rotate(333deg);
    }
}

.anime3-Car {
    float  : right;
    z-index: 25;

    img {
        width                    : 500px;
        opacity                  : 0;
        animation-name           : anime3-Car;
        animation-duration       : 4.5s;
        animation-fill-mode      : forwards;
        animation-timing-function: ease-in-out;
        position                 : absolute;
        top                      : 350px;
        right                    : 0;
        z-index                  : 25;
    }
}

@keyframes anime3-Car {
    0% {
        right    : -30%;
        opacity  : 1;
        z-index  : 25;
        transform: scale(0, 0);
    }

    30% {
        right    : 30%;
        opacity  : 1;
        z-index  : 25;
        transform: scale(1, 1);
    }

    75% {
        right    : 30%;
        opacity  : 1;
        z-index  : 25;
        transform: scale(1, 1);
    }

    100% {
        right    : 109%;
        opacity  : 1;
        transform: scale(1, 1);
        z-index  : 25;
    }
}

.anime3-Curbside {
    position           : absolute;
    padding-left       : 1090px;
    padding-top        : 690px;
    animation-name     : anime3Curbside;
    animation-duration : 1s;
    position           : absolute;
    animation-fill-mode: forwards;
    animation-delay    : 1.5s;
    z-index            : 1;

    img {
        width              : 100px;
        opacity            : 0;
        animation-fill-mode: forwards;
        animation-delay    : 1.5s;
        animation-name     : anime3Curbsideimg;
        animation-duration : 1s;
        position           : absolute;
    }
}

@keyframes anime3Curbside {
    100% {
        transform: translate(-437px, -515px);
    }
}

@keyframes anime3Curbsideimg {
    0% {
        opacity: 1;
    }

    98% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        width  : 330px;
    }
}

.anime3-Curbside-2 {
    position           : absolute;
    padding-left       : 734px;
    padding-top        : 228px;
    animation-name     : anime3-Curbside-2;
    animation-delay    : 2.5s;
    animation-duration : 1s;
    animation-fill-mode: forwards;
    z-index            : 1;
    opacity            : 0;

    img {
        width              : 260px;
        opacity            : 1;
        animation-fill-mode: forwards;
        animation-delay    : 2.5s;
        animation-duration : 1s;
        animation-name     : anime3-Curbside-img-2;
        position           : absolute;
    }
}

@keyframes anime3-Curbside-2 {
    0% {
        opacity: 1;
    }

    100% {
        transform: translate(-70px, 180px);
        opacity  : 1;
    }
}

@keyframes anime3-Curbside-img-2 {
    0% {
        opacity: 1;
    }

    50% {
        width  : 100px;
        opacity: 1;
    }

    75% {
        opacity: 0;
        width  : 100px;
    }

    100% {
        opacity: 0;
        width  : 100px;
    }
}

.anime3-sasmi {
    position           : absolute;
    top                : 460px;
    padding-left       : 40px;
    animation-name     : anime3-sasmi;
    animation-duration : 0.5s;
    animation-delay    : 2s;
    animation-fill-mode: forwards;
    z-index            : 5;

    img {
        width              : 200px;
        animation-name     : anime3-sasmi-img;
        animation-duration : 0.5s;
        animation-delay    : 2s;
        animation-fill-mode: forwards;
    }
}

@keyframes anime3-sasmi {
    100% {
        transform: translate(740px, -190px);
    }
}

@keyframes anime3-sasmi-img {
    0% {
        opacity: 1;
    }

    98% {
        opacity: 1;
    }

    100% {
        width  : 100px;
        opacity: 0;
    }
}

.anime3-pancake {
    position           : relative;
    padding-top        : 150px;
    float              : right;
    right              : 0;
    animation-name     : anime3-pancake;
    animation-duration : 0.5s;
    animation-delay    : 2s;
    animation-fill-mode: forwards;
    z-index            : 5;

    img {
        width              : 200px;
        animation-name     : anime3-pancake-img;
        animation-duration : 0.5s;
        animation-delay    : 2s;
        animation-fill-mode: forwards;
    }
}

@keyframes anime3-pancake {
    100% {
        transform: translate(-132px, 110px);
    }
}

@keyframes anime3-pancake-img {
    98% {
        opacity: 1;
    }

    100% {
        width    : 130px;
        transform: rotate(6deg);
        opacity  : 0;
    }
}

.anime3-coffee {
    padding-left       : 410px;
    position           : absolute;
    animation-name     : anime3-coffee;
    animation-duration : 0.5s;
    animation-delay    : 2s;
    animation-fill-mode: forwards;
    z-index            : 5;

    img {
        width              : 200px;
        animation-name     : anime3-coffee-img;
        animation-duration : 0.5s;
        animation-delay    : 2s;
        animation-fill-mode: forwards;
    }
}

@keyframes anime3-coffee {
    100% {
        transform: translate(378px, 260px);
    }
}

@keyframes anime3-coffee-img {
    98% {
        opacity: 1;
    }

    100% {
        width    : 130px;
        transform: rotate(6deg);
        opacity  : 0;
    }
}

//anime2
.anime-2 {
    height     : 800px;
    position   : relative;
    padding-top: 100px;
}

.anime-2-Strawberry-1 {
    padding-left: 380px;
    position    : absolute;

    img {
        transform: scaleX(-1);
    }
}

.anime-2-Strawberry-2 {
    position    : absolute;
    padding-top : 272px;
    padding-left: 140px;

    img {
        width: 26px;
    }
}

.anime-2-Strawberry-3 {
    padding-left: 465px;
    position    : absolute;
    padding-top : 380px;

    img {
        transform: scaleX(-1);
        width    : 24px;
    }
}

.anime-2-Strawberry-4 {
    padding-left: 1000px;
    position    : absolute;
    padding-top : 550px;

    img {
        width: 35px;
    }
}

.anime-2-banner {
    position   : absolute;
    padding-top: 140px;

    h1 {
        font-size : 51px;
        color     : black;
        margin-top: 0;

        span {
            font-weight: 600;
        }
    }

    h2 {
        font-size : 51px;
        color     : black;
        margin-top: 0;

        span {
            font-weight: 600;
        }
    }

    p {
        margin-top: 20px;
        font-size : 24px;
        color     : black;
    }
}

.anime-2-button {
    position   : absolute;
    padding-top: 451px;
    z-index: 9;

    button {
        padding      : 10px 30px;
        border-radius: 10px;
        background   : black;
    }
}

.anime-2-Take-Away-Box-opened {
    position           : absolute;
    animation          : Take-Away-Box;
    animation-duration : 2s;
    animation-fill-mode: forwards;
    z-index            : 1;

    img {
        width              : 0px;
        animation-name     : Take-Away-Box-img;
        animation-duration : 3s;
        animation-fill-mode: forwards;
    }
}

@keyframes Take-Away-Box {
    0% {
        transform: translate(1700px, 800px);
    }

    50% {
        transform: translate(600px, 160px);
    }

    100% {
        transform: translate(600px, 160px);
    }
}

@keyframes Take-Away-Box-img {

    0% {
        opacity: 1;
    }

    95% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        width  : 410px;
    }
}

.anime-2-Take-Away-Box-closed {
    position           : absolute;
    animation          : Take-Away-closed-Box;
    animation-duration : 3s;
    animation-fill-mode: forwards;
    animation-delay    : 3s;
    padding-top        : 270px;
    padding-left       : 640px;
    z-index            : 5;

    img {
        width              : 410px;
        animation-name     : Take-Away-Box-closed-img;
        animation-duration : 3s;
        animation-fill-mode: forwards;
        animation-delay    : 3s;
        opacity            : 0;
    }
}

@keyframes Take-Away-closed-Box {
    0% {
        transform: translate(0px, 0px);
        opacity  : 1;
    }

    30% {
        opacity: 1;
    }

    100% {
        transform: translate(-820px, 140px);
        opacity  : 0;
    }
}

@keyframes Take-Away-Box-closed-img {
    0% {
        opacity: 1;
    }

    98% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.anime-2-Cupcake {
    position           : absolute;
    padding-top        : 180px;
    padding-left       : 1030px;
    animation          : Cupcake;
    animation-duration : 1s;
    animation-fill-mode: forwards;
    animation-delay    : 2.2s;
    z-index            : 2;

    img {
        width    : 90px;
        animation: Cupcakeaxisimage 2s forwards 2;
    }
}

@keyframes Cupcakeaxisimage {

    50% {
        transform: translateY(20px);
    }
}



@keyframes Cupcake {
    30% {
        opacity: 1;
    }

    100% {
        transform: translate(-180px, 180px);
        opacity  : 0;
    }
}


.anime-2-Breakfast {
    position           : absolute;
    padding-top        : 60px;
    padding-left       : 480px;
    animation          : Breakfast;
    animation-duration : 1s;
    animation-fill-mode: forwards;
    animation-delay    : 2.2s;
    z-index            : 2;

    img {
        width    : 120px;
        animation: Breakfastaxisimage 2s forwards 2;

    }
}

@keyframes Breakfastaxisimage {

    50% {
        transform: translateY(-20px);
    }
}


@keyframes Breakfast {
    30% {
        opacity: 1;
    }


    100% {
        transform: translate(300px, 350px);
        opacity  : 0;
    }
}


.anime-2-Hotdog {
    position           : absolute;
    padding-top        : 417px;
    padding-left       : 190px;
    animation          : Hotdog;
    animation-duration : 1s;
    animation-fill-mode: forwards;
    animation-delay    : 2.2s;
    z-index            : 2;

    img {
        width    : 130px;
        animation: Hotdogaxisimage 2s forwards 2;
    }
}

@keyframes Hotdogaxisimage {

    50% {
        transform: translateY(20px);
    }
}


@keyframes Hotdog {
    30% {
        opacity: 1;
    }

    100% {
        transform: translate(540px, -70px);
        opacity  : 0;
    }
}


.anime-2-Blueberry {
    position    : absolute;
    padding-left: 1100px;
    padding-top : 100px;

    img {
        width: 36px;
    }
}


.megaphone {
    text-align   : end;
    padding-right: 20px;
    cursor: pointer;

    img {
        margin: 0 0 0 0;
        width: 8%;
        
    }
}

.explore {
    padding   : 0 0 0 0;
    text-align: center;
    padding   : 0 0 10px 0;
}

.explore-amealio {
    background-color: #5195BB;
    color           : white;
    padding         : 10px 15px 10px 15px;
    border-radius   : 5px 5px 0px 0px;
    font-weight     : 400;
}

.foodresize {
    margin-top: -20px;
}



.carouseldiscover {
    .slide-button {
        top : 700px;
        left: 280px;
    }


    .carousel-indicators {
        position: absolute;
        bottom  : -42px;
    }

    .slide-button {
        top : 700px;
        left: 280px;
    }

    .glyphicon-chevron-right {
        display: none;
    }

    .glyphicon-chevron-left {
        display: none;
    }

    .carousel-control {
        opacity: 0;
    }
}

@media(max-width: 1200px) {
    .carouseldiscover .slide-button {
        top : 590px;
        left: 230px;
    }

}

@media(max-width: 1030px) {
    .megaphone img {
        width: 50px;
        cursor: pointer;
    }

    .dinning {
        height: 500px;
    }

    .anime3 {
        height: auto;
    }

    .anime-2 {
        height: 500px;
    }

    .dinning h1 {
        padding: 0;
    }

    .anime3-Car {
        display: none;
    }

    .Pasta {
        display: none;
    }

    .Table {
        display: none;
    }

    .anime-2-Take-Away-Box-opened {
        display: none;
    }

    .anime-2-Take-Away-Box-closed {
        display: none;
    }

    .anime3-Curbside {
        display: none;
    }

    .anime3-Curbside-2 {
        display: none;
    }

    .anime3-sasmi {
        display: none;
    }

    .anime3-banner {
        position   : absolute;
        padding-top: 0px;

        h1 {
            font-size    : 32px;
            font-weight  : 600;
            margin-bottom: 2rem;
        }

        p {
            font-size: 16px;
        }
    }

    .anime-2-banner {
        position   : absolute;
        padding-top: 10px;

        h1 {
            font-size: 31px;
            color    : black;

            span {
                font-weight: 600;
                text-align : center;
            }
        }

        h2 {
            font-size: 31px;
            color    : black;

            span {
                font-weight: 600;
                text-align : center;
            }
        }

        p {
            margin-top: 20px;
            font-size : 16px;
            color     : black;
        }
    }

    .Burger {
        display: none;
    }

    .Dessert {
        display: none;
    }

    .Pasta {
        display: none
    }

    .anime-2-Strawberry-1 {
        display: none;
    }

    .anime-2-Strawberry-3 {
        display: none;
    }

    .anime-2-Strawberry-4 {
        display: none;
    }

    .anime-2-Breakfast {
        display: none;
    }

    .anime-2-Cupcake {
        display: none;
    }

    .anime-2-Hotdog {
        display: none;
    }

    .anime-2-Blueberry {
        display: none;
    }

    .anime3-leaf1 {
        display: none;
    }

    .anime3-coffee {
        display: none;
    }

    .anime3-chilli1 {
        display: none;
    }

    .anime3-leaf2 {
        display: none;
    }

    .anime3-pancake {
        display: none;
    }

    .anime3-sasmi {
        display: none;
    }

    .anime3-chilli2 {
        display: none;
    }

    .anime3-chilli3 {
        display: none;
    }

    .anime3-Curbside {
        display: none;
    }

    .anime3-Curbside-2 {
        display: none;
    }

    .dinning {
        position   : relative;
        padding-top: 140px;

        p {
            font-size    : 25px;
            color        : black;
            margin-bottom: 2rem;
        }

        button {
            padding      : 10px 30px;
            border-radius: 10px;
            background   : black;
        }
    }

    .anime-2-button {
        margin: 0 0 0 200px;
    }

    .tableanimationcenter {
        h1 {
            width : 100%;
            margin: auto;
        }
    }
}

@media(max-width: 992px) {
  
    .f50-h2{
        font-family: 'Muli'; 
        font-size: 32px;
        line-height: 45px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: bold;
        color: #212121;
    }
    
    .animate-slider-div {
        margin  : -100px 0 45px 0;
    }
    
    .navbar-toggler-icon{
        width: 2.5em;
        height: 2.5em;
    }

    .top-main-slider{
        padding: 0 !important;

        .slider-home-page-left-one{
            background-color: #DD5642;
            padding-top: 30px;
            padding-bottom: 30px;
            height: 75vh !important;

            h2, h3, p{
                color: #fff !important;
                background-color: #DD5642 !important;
            }

            .h-p-s-o-padding{
                margin-bottom: 30px !important;
                margin-top: 100px !important;
            }
    
            .h-s-two-css{
                margin-bottom: 0px !important;
            }

            .top-main-button-one-black{
                color: #fff !important;
                border: 4px solid #FFFFFF !important;
            }

            .paragraph-width-home{
                width: 100% !important;
                // margin-bottom: 52px !important;
            }

            .top-main-button-one-red{
                border: 4px solid #FFFFFF !important;
                color: #FFFFFF !important;
            }
        }

        .bottomicon-home {
            justify-content: space-between !important;
            width: 85% !important;
            margin: 0 auto !important;
        }

        .top-align-center{
            text-align: center !important;
        }

        .register-width-style{
            width: 85% !important;
            margin: auto !important;
        }

        .icon-play-app-store{
            margin-right: 0 !important;
        }

        .arrow{
            color: $a-orange-color !important;
            top: 24px !important;
            right: 20px !important;
        }

        .slick-slider{
            .slick-dots{
                bottom: 10px !important;
            }
        }

        .slider-first-image-resize{
            padding-top: 40px !important;
            img{
                height: 475px !important;
            }
        }

        .slider-second-image-resize{
            position: absolute !important;
            left: 0% !important;
            top: 23px !important;
            width: 260px !important;
            right: 0% !important;
            margin: 0 auto;

            img{
                height: 475px !important;
            }
        }

        .slider-third-image-resize{
            position: absolute !important;
            left: 0% !important;
            top: 20px !important;
            right: 0% !important;
            width: 275px !important;
            margin: 0 auto;
        }

        .home-second-slider{
            padding: 0 !important;
        }

        .home-section-superapp-superior{
            position: static !important;
            top: 20px !important;
            width: 100% !important;
            padding: 30px 0px !important;

            img{
                height: 475px !important;
            }
        }

    }

    .top-main-button-one{
        min-width: 75% !important;
    }

    .extramargin-mobile{
        margin-bottom: 80px !important;
    }

    .enjoy-bottom-button-height{
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .happycustomer-imgalign {
        z-index   : 22;
        position  : relative;
        text-align: center;
        width     : 56%;
        font-size : 13px;
        margin    : auto;
        margin-top: -216px;
        padding   : 0 0 80px 0;
        color     : white;
    }

    .vlslider {
        display: none;
    }

    .carousel-indicators .slide-button {
        top : 590px;
        left: 220px;
    }

    .footer-amealio-wrapper{
        background-color: #262626;
        position: relative;
        padding: 35px 0px;
    }

    .top-image-sprial, .top-image-right-sprial{
        width: 100% !important;
    }

    .food-partners-main .foodtop-image-left-bg {
        position: absolute;
        top: 10px !important;
        right: 5px !important;
        width: 10% !important;
    }

    .common-head{
        font-size: 35px !important;
    }

    .home-section-main{
        padding-top: 20px;
        padding-bottom: 80px;

        .home-back-bottom-img{
            bottom: 0 !important;
            width: 35% !important;
        }

        .home-back-bottom-middle-img {
            position: absolute;
            bottom: 0px !important;
            width: 22% !important;
            left: 0% !important;
        }
    
        .home-backTwo-bottom-img{
            width: 32% !important;
            bottom: 0 !important;
        }

        .animation-box{
            height: auto !important;

            .home-enjoy-scene {
                bottom: 25% !important;
                left: 46% !important;

                .home-enjoy-square {
                    width: 40% !important;
                }
            }

            .home-enjoy-background {
                top: 25% !important;
                right: 0% !important;
                width: 100% !important;
            }

            .home-enjoy-background-left {
                left: 0% !important;
                width: 20% !important;
                top: 36% !important;
            }

            .home-enjoy-background-right{
                right: 3% !important;
                width: 20% !important;
                top: 35% !important;
            }
        }

        .home-para2{
            width: 100% !important;
            text-align: center !important;
        }

        .onboarded-buttonOutline{
            margin-top: 35px !important;
        }

        .onboarded-button{
            margin: 25px auto 35px auto; 
        }

        .home-head1{
            padding-top: 0px !important;
            margin-top: 0px;
            font-size: 42px !important;
            text-align: center;
        }

        .home-head2{
            text-align: center !important;
            line-height: 28px !important;
        }

        .home-para1{
            text-align: center !important;
        }

        .box-line{
            width: 20% !important;
            margin: 0 auto;
        }

        .numSec{
            width: 50% !important;
            margin: 0 auto !important;
        }

        .check{
            text-align: center;
        }

        .user-details .input input{
            height: 45px;
        }

        .checkoutlogo{
            margin: 0 auto !important;

            .bottomicon{
                align-items: center;
                justify-content: space-evenly;
                width: 100%;
                margin: 15px 0px;
            }
        }

        .home-section-img-column{
            height: 350px;
            padding: 15px !important;
        }

        .home-section-main-table-right-box{
            height: 450px;
            text-align: center;

            .home-super-app-mobile{
                width: 80%
            }
        }
    }

    .position-of-mouse {
        position: relative;
        bottom: 120px !important;
        left: 0% !important;
        text-align: center;
    }

    .who-are-we-main{
        height: auto !important;

        .who-head{
            margin-bottom: 20px;
        }

        .who-paragraph{
            padding: 0 !important;
            // margin-bottom: 50px !important;
        }
    }

    .think-food-think-amealio{
        height: auto !important;

        .whyamealio-li1{
            padding: 160px 0 0 0 !important;
        }

        .think-food-h1-font{
            font-size: 30px !important;
            margin-top: 40px !important;
        }

        .top-image-left-bg{
            width: 18% !important;
            top: 25px !important;
            left: 10px !important;
        }

        .top-image-right-bg{
            top: 115px !important;
            width: 50% !important;
        }

        .bottom-image-left-bg{
            width: 15% !important;
            left: 0 !important;
        }

        .bottom-image-right-bg{
            bottom: 30px !important;
            width: 15% !important;
            right: 15px !important;
        }
    }

    .amealio-features-main {
        height: auto !important;
        padding-bottom: 40px !important;

        .features-p-paragraph{
            font-size: 18px !important;
            margin-top: 25px !important
        }

        .top-image-center-leaf{
            left: 85% !important;
            width: 14% !important;
        }

        .features-left-section{
            padding-top: 0px !important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            text-align: center !important;

            .features-button-slider {
                margin: 40px 0px !important;
                width: 100% !important;
            }

            .futures-slider-btn{
                width: 100px !important;
                height: 60px !important;
                border-radius   : 50px !important;
            }
        }

        .features-right-section {
            text-align: center !important;

            .slick-slide img{
                height: 175px !important;
            }

            .features-right-section-image {
                img{
                    width: 75% !important;
                }
            }

            .outter-features{
                left: 5% !important;
                width: 62% !important;
            }
        }
    }

    .join-experience-main{
        .join-top-right-img{
            top: 10px !important;
            right: 0 !important;
            width: 25% !important;
        }

        .join-medium-right-img{
            top: 260px !important;
            left: 10px !important;
            width: 75px !important;
        }

        .join-bottom-left-img{
            bottom: 25px !important;
            left: 0px !important;
            width: 20% !important;
        }

        .bottom-image-position{
            bottom: 25px !important;
            right: 0% !important;
            width: 60px !important;
        }

        .box-design-experience{  
            padding: 10px 14px !important;  
            .join-h1-experience-Heading{
                font-size: 21px !important;
              }
        }

        .join-us-button{
            min-width: 300px !important;
        }
    }

    .introduce-amealio-verse{
        // background-size: auto !important;
        background-position: center !important;
        height: auto !important;
        padding: 45px 0px 55px 0px !important;
        // background-size: 100% 100% !important;

        .introduce-h1-font {
            font-size: 55px !important;
            text-align: center;
        }

        .introduce-p-paragraph{
            margin: 50px 0px !important;
            padding: 0 !important;
            text-align: center !important;
        }

        .introduce-right{
    
            .amealioVerse-slider-img{
                width: 60% !important;
            }
    
            .introduce-verse-slider{
                text-align: center; 
    
                .introduce-amealio-div-imgone{
                    width: 100% !important;
                    height: auto !important;

                    img{
                        height: 425px !important;
                    }
                }
            }
        }

        .launching-fall-f {
            position: static !important;
            font-size: 55px !important;
            line-height: 65px !important;
            padding-top: 20px !important;
        }               

        .introduce-amealio-head-div{
            width: 100% !important;
            margin: auto;     
            
            // .introduce-amealio-div-imgone{
            //     width: 100% !important;
            //     height: auto !important;
            //     margin: 20px auto !important;
            //     // padding-bottom: 150px;
    
            //     img{
            //         height: 400px !important;
            //     }
            // }

            // .introduce-amealio-div-imgtwo{
            //     width: 100% !important;
            //     height: auto !important;
            //     margin: 20px auto !important;
            //     // padding-bottom: 150px;
    
            //     img{
            //         height: 400px !important;
            //     }
            // }

            // .introduce-amealio-head-divThree{
            //     padding: 0 !important;
            // }

            .introduce-amealio-div-imgThree{
                width: 100% !important;
                height: auto !important;
                margin: 20px auto !important;
                // padding-bottom: 150px;
    
                img{
                    height: 400px !important;
                }
            }
        } 

        .introduce-amealio-head-divOne{
            width: 100% !important;
            margin: auto; 
            // padding-left: 85px; 
        }

        .introduce-amealio-head-divTwo{
            width: 100% !important;
            margin: auto;  
            // padding-left: 35px;
        }

        .introduce-amealio-head-divThree{
            width: 100% !important;
            margin: auto;  
            // padding: 0px 65px 0px 150px;
        }

        .learn-more-center{
            margin-bottom: 40px;
            text-align: center !important;
        }
    }

    .happycustomer-hours-slider{
        width: 100% !important;

        .common-head{
            margin-bottom: 40px !important;
        }

        .slick-slide .happycustomer-top-img{
            height: auto !important;
            width: 23% !important;
            left: 5%;
        }

        .slick-slide .happycustomer-bottom-img{
            height: auto !important;
            width: 23% !important;
            bottom: 23px;        
        }

        .happycustomer-box3 {
            background-color: #FFFFFF !important;
            width           : 100% !important;
            height          : auto !important;
        }
        
        .happycustomer-box5 {
            background   : $a-orange-color 0% 0% no-repeat padding-box;
            width: 100% !important;
            height: 325px !important;
            z-index: 1 !important;
        }
    }

    .happycustomer-section .happycustomer-hours-slider .slick-list{
        padding: 0 !important;
    }

    .slidervideo-section .slick-slider .slick-list{
        padding: 0 !important;
    }

    .happycustomer-section {
        padding: 50px 10px 20px 10px !important;

        .top-image-left-bg{
            width: 22% !important;
        }

        .top-image-right-bg{
            top: 0px;
            right: 0px;
            width: 20%;
        }

        .top-image-medium-bg {
            bottom: 42%;
            right: 0px;
            width: 20%;
        }
    }

    .happycustomer-align{
        .happycustomer-align-paraBig{
            font-size: 14px !important;
        }
    }

    .slider-home-page-one{
        height: auto !important;

        .outter-features{
            top: 6% !important;
            left: 28% !important;
            width: 44% !important;
        }

        .features-right-section-image{
            position: static !important;
            width: 55% !important;
            height: auto !important;
            top: 0% !important;
            right: 0% !important;
            left: 0% !important;
            margin: 0px auto 30px auto;

            img {
                height: auto !important;
            }

            .download-button-css{
                width: 64% !important;
                left: 18% !important;
                bottom: 30% !important;
                text-align: center;   

                .button-design{
                    min-width: 140px !important;
                    height: 35px !important;
                    font-size: 15px !important;
                }
            }
        }        

        .features-slider .features-amealio-div-img{
            width: 84% !important;
        }

        // .features-slider .features-amealio-head-divTwo .features-head{  
        //     font-size: 18px !important;
        // }  

        .features-slider .features-amealio-head-divTwo{  
            font-size: 18px !important;
        }  
    }

    #slider-video-pedding{
        .slick-slider{
            .slick-list{
                padding: 30px 0px 0px 0px !important;
            }
        }
    }

    // .margin-second-css{
    //     margin-top: 13px !important;
    // }

    // .margin-third-css{
    //     margin-top: 95px !important;
    // }
}

@media(max-width: 743px) {
    .whyamealio-ul {
        display        : flex;
        list-style-type: none;
        justify-content: center;
        flex-wrap      : wrap;
    }

    .whyamealio-li:hover {
        padding         : 70px 0 0 0;
        background-color: rgb(255, 255, 255);
        width           : auto;
        height          : auto;
        text-align      : center;
    }

    .whyamealio-li1:hover {
        padding         : 70px 0 0 0;
        background-color: rgb(255, 255, 255);
        width           : auto;
        height          : auto;
        text-align      : center;
    }
}

@media(max-width: 764px) {
    .explore-mobcenteralign {
        text-align: center;
        padding   : 0 35px 80px 35px;
    }

    .foodresize {
        margin-top: -20px;
        overflow  : hidden;
    }

    .explore-h1 {
        font-size  : 38px;
        font-weight: 600;
        margin     : 60px 0 40px 0;
        width      : 100%;
        line-height: 55px;
    }

    .explore-p {
        font-size: 18px;
        padding  : 0px;
    }

    .explore-align {
        text-align: -webkit-right;
        display   : none;
        margin    : -80px 50px 0px 0;
    }

    .explore-col {
        text-align: center;
    }

    .explore-foodresize {
        width : auto;
        height: 350px;
    }

    .explorefeatures-img {
        width : 55%;
        margin: -180px 0 0 -50px;
    }

    .vl {
        display: none;
    }

    .hide-slide {
        display: none;
    }

    .slide-img {
        float      : right;
        width      : 100%;
        overflow   : hidden;
        padding-top: 105px;
    }

}

@media(max-width: 600px) {
    .experience-col {
        padding: 35px 0 10px 0;
    }

    .vl {
        display: none;
    }

    .foundation-p {
        font-size: 18px;
        padding  : 0 0 47px 0;
        color    : black;
        margin   : 515px 0 0 0;
    }

    .foundation-col {
        margin: -699px 0 0 0;
    }

    .foundation-row {
        text-align: center;
    }

    .foundation-btn {
        background-color: #fab516;
        color           : white;
        z-index         : 2;
        position        : relative;
    }
    .carouseldiscover .slide-button {
        top : 635px;
        left: 285px;
    }


}

@media(max-width: 480px) {

    .think-food-slider{

        .whyamealio-card {
            width           : 350px;

            .whyamealio-align{
                margin: -65px 0 0 0;
            }

            .bottom-thinkfood-sprial{
                bottom: -11px;
            }
        }
    }

    .amealia-pad {
        img {
            width: 100%;
        }
    }

    .carouseldiscover .slide-button {
        top : 475px;
        left: 225px;
    }

    .animate-slider li {
        width : 8px;
        height: 8px;
    }

    .mobile-mockup1 {
        display: inline;
        width  : 70%;
        margin : 0 0 0 -75px;
    }

    .service-mobview {
        margin: 0 20px 0 0px;
    }

    .mobile-mockup {
        display: none;
    }

    .aboutamealio-ul {
        padding: 20px 0 0;
    }

    .aboutamealio-headimg {
        width : 100%;
        margin: 0;
    }

    .whyamealio-p {
        font-size: 14px;
    }

    .anime-2-button {
        padding: 160px 0 0 0;
    }

    .slick-list {
        padding: 0;
    }

    .discover-section {
        padding: 0;
    }

    .discover-p {
        font-size : 14px;
        margin    : 0;
        padding   : 00 0 20px 0;
        text-align: center;
    }

    .disimgrow {
        display        : flex;
        justify-content: center;
    }

    .imgdis {
        display: none;
    }

    img.disimg {
        display: block;
        width  : 185px;
        padding: 20px 0px;
    }

    h1.discover-h1.disp {
        display   : block;
        font-size : 16px;
        text-align: center;
    }

    .features-button {
        margin: 10px 0 0 0;
    }

    .explore-foodresize {
        height: 190px;
    }

    .explore-p {
        font-size: 14px;
    }

    .slick-slide img {
        display: block;
        // height : 260px;
        margin : 0;
        width  : 100% !important;
    }

    .mobileexperience-h1,
    .mobileexperience-h3,
    .explore-h1 {
        font-size: 20px;
    }

    .explore-h1 {
        font-size  : 20px;
        line-height: 24px;
        margin     : 0 0 30px 0;
    }

    .connect-p {
        font-size: 14px;
    }

    .mobileexperience-p {
        font-size: 14px;
    }

    .discover-col {
        font-size: 14px;
    }

    .banneranime2 {
        font-size: 40%;
    }

    .anime-2-button {
        margin: 50px 0 0 0;
    }

    .discover-h1 {
        font-weight: bold;
        line-height: 30px;
    }

    .discover-box {
        width  : 90px;
        height : 75px;
        display: inline-block;
    }

    .discover-col {
        font-size : 14px;
        text-align: center;
        padding   : 0 10px 10px 15px;
    }

    .discover-box img {
        margin-left: 4px;
    }

    .dis-btn {
        justify-content: center;
        display        : flex;
    }

    .vl {
        display: none;
    }

    .experience-col {
        padding: 35px 0 10px 0;
    }

    .foundation-col {
        margin: -535px 0 0 0;
    }

    .foundation-h1 {
        font-size: 20px;
    }

    .foundation-p {
        font-size: 14px;
        padding  : 0 0 25px 0;
        color    : black;
        margin   : 320px 0 0 0;
    }

    .foundation-section {
        padding: 30px 0 25px 0;
    }

    .dinning h1 {
        width      : 50%;
        font-size  : 40px;
        padding-top: 0px;
        font-weight: 700;
        color      : black;
    }

    .tableanimationcenter h1 {
        width    : 100%;
        margin   : 0px;
        font-size: 34px;
    }

    .dinning p {
        font-size: 16px;
    }

    .hide-slide {
        display: none;
    }

    .discover-colorder {
        order: 2;
    }

    .slide-img {
        float      : right;
        width      : 100%;
        overflow   : hidden;
        padding-top: 90px;
    }

    .happycustomer-imgalign {
        z-index   : 22;
        position  : relative;
        text-align: center;
        width     : 56%;
        font-size : 13px;
        margin    : auto;
        margin-top: -222px;
        padding   : 0 0 80px 0;
        color     : white;
    }

    .location-p {
        font-size: 14px;
    }

    .location-section {
        padding: 20px 0 20px 0;
    }

    .slick-dots {
        bottom: -25px;
    }

    .slidervideo-section {
        padding: 0px 0 60px 0;
    }

    .happycustomer-h1 {
        padding: 10px 0 15px 0;
    }

    .happycustomer-imgalign {
        padding: 0 0 30px 0;
    }

    .happycustomer-section {
        padding: 0 0 40px 0;
    }

    .experience-head {
        font-size: 20px;
        padding  : 0px 0 25px 0;
    }

    .experience-btn {
        padding  : 10px 35px 10px 35px;
        margin   : 35px 0 0 0;
        font-size: 14px;
    }

    .experience-section {
        padding: 15px 0 25px 0px;
    }

    .whyamealio-h1 {
        padding: 10px 0 10px 0;
    }

    .mobileexperience-h3 {
        padding: 15px 0 30px 0;
    }

    .restaurants-section {
        background-color: #8884EC;
        padding: 15px 0px 40px 0px;
    }

    .restaurants-h1 {
        margin: 10px 0px 15px 0px;
    }

    .carousel-indicators {
        bottom: -40px;
    }

    ol.carousel-indicators {
        bottom: -35px;
    }

    .explore-mobcenteralign {
        padding: 0 35px 30px 35px;
    }

    .discover-para p {
        font-size: 14px;
    }

    section.expresize {
        overflow: hidden;
    }
}

.slidervideo-section .slick-current+.slick-active img {
    width : 100% !important;
    // margin: -25px 0px 38px 0;
}


@media (max-width: 600px) {

    .slick-next,
    .slick-prev {
        // display: none !important;
    }

}
@media(max-width: 360px){
    .carouseldiscover .slide-button{
        top: 345px;
        left: 165px;
    }

}
.slick-track{
    display: flex;
    align-items: center;
}
.locationpart {
    position : relative;
}
.locationpart>img {
    width: 100%;
}
.locationiconpart {
    position: absolute;
    top     : 46%;
    left    : 68%;
    right   : 0px;
    width: 23%;
}
// .locationiconpart>img:hover{
//     // width: 10%;
//     margin: 25px 0 5px 11px;
// }
.address_box span {
    background-color: #FFFFFF;
    border: none;
    border-radius: 5px;
    padding: 8px 8px 8px 8px;
    // margin-bottom: 15px;
    font-size: 18px;
    position: relative;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
  

 }

.address_box span:before{
    position: absolute;
    content: "";
    height: 17px;
    width: 15px;
    background-color: #fff;
    bottom: -8px;
    left: 10%;
    transform: translateX(-50%) rotate(45deg);
}

// .address_box{
//     position: relative;
// }

// .address_box >img{
//     position: absolute;
// }

// .address_box >span{
//     position: absolute;
//     display: none;
// }
.locationiconpart:hover .address_box {
    opacity: 1;
}
.address_box {
    opacity: 0;
}

/************************************
 *@DESC Button Class
************************************/
.join-us-button{
    min-width: 350px;
    min-height: 42px;
    background-color: $a-orange-color;
    color: #FFFFFF;    
    border-radius: 50px;
    font-family: 'Muli'; 
    font-size: 19px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 700;  
    text-transform: uppercase;
}

.join-us-button:hover{
    box-shadow: 0px 3px 6px $a-red-color;
    border: 2px solid $a-red-color;
}

.common-head{
    font-family: 'Muli'; 
    font-size: 48px; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: bold;
    color: #000;
    text-align: center;
}

.common-paragraph{
    font-family: 'Muli'; 
    font-size: 19px; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: 500;
    color: #000;
    text-align: center;
}

.common-backtotop-button{
    font-family: 'Muli'; 
    font-size: 14px; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: 500;
    color: #707070;
    border: none;
    display: flex;
    align-items: center;

    i{
        font-size: 25px;
    }
}

.custom-header{
    min-height: 100px;

    .all-menu-link{
        color: #9A9595;
        font-family: 'Muli'; 
        font-size: 16px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 600;
        text-decoration: none;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

/************************************
 *@DESC Home Section
************************************/
.home-section-main{

    .home-back-bottom-img{
        position: absolute;
        bottom: 60px;
        right: 0px;
        width: 15%;
    }

    .home-back-bottom-middle-img{
        position: absolute;
        bottom: 48px;
        width: 8%;
        left: 32%;

        img{
            box-shadow: 18px 5px 45px #00000069;
            border-radius: 50%;
        }
    }

    .home-backTwo-bottom-img{
        position: absolute;
        bottom: 60px;
        right: -12px;
        width: 12%;
    }

    .home-section-img-column{
        padding: 0;
    }

    .animation-box2{
        height: 50vh;
    }
    .animation-box{
        height: 50vh;

        .home-enjoy-background{
            position: absolute;
            // right: -25%;
            // width: 100%;
            right: -20%;
            width: 110%;
            top: -30px;        
        }

        .home-enjoy-background-left{
            position: absolute;
            left: 4%;
            width: 30%;
            top: 26px;
        }

        .home-enjoy-background-right{
            position: absolute;
            right: -21%;
            width: 30%;
            top: 5px;
        }

        .home-enjoy-scene{
            position: absolute;
            bottom: -28px;
            left: 60%;
    
            .home-enjoy-square{
                width: 100%;
                height: auto;
                text-align:center;
            }
    
            .home-enjoy-z-axis{
                animation: z-axis-animation 3s ease-in-out 0s infinite alternate;
            }

            // .home-enjoy-z-axis:hover{
            //     animation: z-axis-animation 3s ease-in-out 0s alternate;
            // }
    
            @keyframes z-axis-animation {
                from {transform: rotateZ(55deg)}
                to {transform: rotateZ(-55deg)}
            }
        }
    }    

    .home-super-app{
        position: absolute;
        width: 35%;
        height: auto;
        right: 5%;
        top: -100px;
    }

    .home-head1{
        font-family: 'Muli'; 
        font-size: 50px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: bold;
        color: #000;
        padding-top: 30px;
    }
    .home-head2{
        font-family: 'Muli'; 
        font-size: 21px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 600;
        color: #000;
    }

    .home-head3{
        font-family: 'Muli'; 
        font-size: 19px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 600;
        color: #000;
    }
    .home-head4{
        font-family: 'Muli'; 
        font-size: 18px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 600;
        color: $a-orange-color;
    }
    .home-head5{
        font-family: 'Muli'; 
        font-size: 14px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: bold;
        color: $a-red-color;
    }

    .home-para1{
        font-family: 'Muli'; 
        font-size: 16px; 
        line-height: 26px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: 500;
        color: #000;
        width: 94%;
    }

    .home-para2{
        font-family: "Muli";
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 500;
        color: #000;
        width: 85%;
    }

    .box-line{
        width: 10%;
        border-radius: 14px;
        border-top: 5px solid $a-red-color;
    }

    .numSec{
        width: 38%;
    }

    .register-about-width{
        width: 32% !important;
    }

    .bottomicon{
        img{
            height: 45px;
            width: 125px;
            border-radius: 5px;
        }
    }

    .onboarded-buttonOutline{
        margin-top: 130px;
        min-width: 150px;
        height: 45px;
        box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #000;

        a{
            font-family: 'Muli'; 
            font-size: 13px; 
            font-style: normal; 
            font-variant: normal; 
            font-weight: 600;
            text-decoration: none;
        }
    }

    .onboarded-button{
        margin-top: 30px;
        width: 150px;
        height: 45px;
        background-color: #000;
        box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        a{
            color: #fff;
            font-family: 'Muli'; 
            font-size: 13px; 
            font-style: normal; 
            font-variant: normal; 
            font-weight: 600;
            text-decoration: none;
        }
    }
}

/************************************
 *@DESC Who Are We
************************************/
.who-are-we-main{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 100%;
    padding: 60px 0;
    
    .who-head{
        font-family: 'Muli'; 
        font-size: 42px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: bold;
    }

    .who-paragraph{
        font-family: 'Muli'; 
        font-size: 18px; 
        font-style: normal; 
        font-variant: normal; 
        font-weight: 500;
        padding: 0px 95px;
        letter-spacing: 1px;
    }
}

/************************************
 *@DESC Join Experience
************************************/
.join-experience-main{
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    background-color: #FFFFFF;
    height: auto;
    width: 100%;
    padding: 75px 0;
    position: relative;

    .join-top-right-img{
        position: absolute;
        top: 15px;
        right: 0;
        width: 10%;
        padding: 0;
    }

    .join-medium-right-img{
        position: absolute;
        top: 220px;
        left: 150px;
        width: 110px;    
    }

    .join-left-img{
        position: absolute;
        top: 21%;
        left: 5%;
        width: 60px;
        z-index: 1;
        transform: rotate(60deg);
    }

    .join-bottom-left-img{
        position: absolute;
        bottom: 18px;
        left: 0px;
        width: 10%;
        padding: 0;
        // transform: rotate(60deg);
    }

    .join-h1-font{
      font-size: 42px;
      font-style: normal; 
      font-variant: normal; 
      font-weight: bold;  
    }

    .join-p-paragraph{
        font-family: 'Muli'; 
        font-size: 19px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: 600;  
      }

    .box-design-experience{
        width: 190px;
        min-height: 145px;
        border: 1px solid #707070;
        border-width: 2px;
        border-style: dashed;
        border-radius: 36px;
        margin-bottom: 50px;
        padding: 10px 20px;
        word-break: break-word;

        .join-h1-experience-Heading{
            font-size: 32px;
            font-style: normal; 
            font-variant: normal; 
            font-weight: bold;
            color: $a-red-color; 
          }
    
          .join-p-experience-paragraph{
            font-family: 'Muli'; 
            font-size: 13px;
            font-style: normal; 
            font-variant: normal; 
            font-weight: 600;  
            padding: 0 15px;
          }
    }

    .box-design-experience:hover{
        background-color: $a-orange-color;
        border: 3px solid #ffffff;
        border-width: 2px;
        border-style: dashed;
        border-radius: 36px;
        word-break: break-word;
        box-shadow   : 0px 7px 18px rgba(0,0,0,0.21);

        .join-h1-experience-Heading{
            color: #FFFFFF;
          }

        .join-p-experience-paragraph{
            color: #FFFFFF; 
        }
    }

    .bottom-image-position {
        position: absolute;
        bottom: 100px;
        right: 200px;
        width: 5%;
        height: auto;
        z-index: 1;
        padding: 0;
        transform: rotate(90deg);
    }
}

/************************************
 *@DESC Introduce Amealio Verse
************************************/
.top-image-sprial{
    position: absolute;
    top: -18px;
    left: 0;
    width: 24%;
}

.top-image-right-sprial{
    position: absolute;
    top: -18px;
    right: 0;
    width: 24%;
}

.top-image-left-bg{
    position: absolute;
    top: 10px;
    left: 5px;
    width: 10%;
}

.top-image-right-bg{
    position: absolute;
    top: 10px;
    right: 20px;
    width: 10%;
}

.top-image-medium-bg{
    position: absolute;
    bottom: 335px;
    right: 0px;
    width: 6%;
}

.introduce-amealio-verse{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 75%; 
    height: auto;
    width: 100%;
    padding: 75px 84px;
    position: relative;

    .introduce-h1-font{
        font-size: 70px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: 700; 
        color: #fff; 
    }

    .introduce-h3-font{
        font-size: 28px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: 700; 
        color: #fff; 
    }
  
    .introduce-p-paragraph{
        font-family: 'Muli'; 
        font-size: 22px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: 500;  
        color: #fff;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-right: 65px;
    }

    .introduce-right{
        position: relative;
        text-align: center;
        z-index: 2;

        .amealioVerse-slider-img{
            margin: 40px auto;
            width: 38%;
            // top: 25px; 
        }

        .amealioVerse-slider-div-align{
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
        }

        .introduce-verse-slider{
            text-align: center; 

            .introduce-amealio-div-imgone{
                width: 70%;
                height: auto !important;
                margin: 45px auto 0 auto !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .slick-slider .slick-list{
            padding-top: 0px !important;
            padding-bottom: 0px !important;            
        }

        .introduce-verse-head{
            font-family: 'Muli'; 
            font-size: 18px;
            font-style: normal; 
            font-variant: normal; 
            font-weight: bold;  
            color: #fff;
            margin: 0;                
        }

        

        .introduce-amealio-head-div{
            width: 100%;
            margin: auto;                
        } 

        .introduce-amealio-head-divOne{
            width: 55%;
            margin: auto; 
            // padding-left: 85px; 
        }

        .introduce-amealio-head-divTwo{
            width: 50%;
            margin: auto;  
            // padding-left: 35px;
        }

        .introduce-amealio-head-divThree{
            width: 38%;
            margin: auto;  
            // padding: 0px 65px 0px 150px;
        }

        // .introduce-amealio-div-imgone{
        //     width: 75%;
        //     height: auto !important;
        //     margin: 20px auto !important;
        // }

        .introduce-amealio-div-imgtwo{
            width: 75%;
            height: auto !important;
            margin: 20px auto !important;
        }

        .introduce-amealio-div-imgThree{
            width: 75%;
            height: auto !important;
            margin: 20px auto !important;
        }
    }

    .launching-fall-f{
        position: absolute;
        bottom: -65px;
        color: #ffffff;
        opacity: 0.14;
        font-family: 'Muli'; 
        font-size: 132px;
        line-height: 85px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: bold;
    }

    .learn-more-button{
        min-width: 200px;
        min-height: 55px;
        background-color: transparent;
        color: $secondary-color;    
        border-radius: 50px;
        font-family: 'Muli'; 
        font-size: 16px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: 700;
        border: 4px solid $secondary-color;
    }
    
    .learn-more-button:hover{
        box-shadow: 0px 3px 6px $a-orange-color;
        border: 4px solid $a-red-color;
        background-color: $a-orange-color;
    }
}

/************************************
 *@DESC Food Partners
************************************/
.food-partners-main{
    padding: 45px 0px 45px 0px;
    position: relative;

    .foodtop-image-left-bg{
        position: absolute;
        top: 25px;
        right: 25px;
        width: 6%;
    }
}

.slider-background{
    background-color: rgba(136, 132, 236, 0);
}

.food-partners-back{
    background-color: rgba(136, 132, 236, 0.24);
    padding: 15px;
}

/************************************
 *@DESC Think Food Think Amealio
************************************/
.think-food-think-amealio{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 100%;
    position: relative;
    padding: 40px 0px;

    .top-image-left-bg{
        position: absolute;
        top: 125px;
        left: 200px;
        width: 5%;
    }

    .top-image-right-bg{
        position: absolute;
        top: 15px;
        right: 0px;
        width: 25%;
    }

    .bottom-image-left-bg{
        position: absolute;
        bottom: 20px;
        left: 2%;
        width: 6%;    
    }

    .bottom-image-right-bg{
        position: absolute;
        bottom: 75px;
        right: 150px;
        width: 5%;
        transform: rotate(175deg);
    }

    .think-food-h1-font{
        font-size: 50px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: bold;
        color: $a-red-color;
        text-align: center;  
      }
}

/************************************
 *@DESC Amealio Features
************************************/
.amealio-features-main{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 100%;
    position: relative;
    padding: 55px 0px;

    .top-image-center-leaf{
        position: absolute;
        top: 50px;
        left: 55%;
        width: 5%;
        transform: rotate(15deg);        
    }

    .features-left-section{
        margin: 75px 0 75px 0;

        .features-h1-font{
            font-size: 62px;
            font-style: normal; 
            font-variant: normal; 
            font-weight: bold; 
            color: #fff; 
        }
      
        .features-p-paragraph{
            font-family: 'Muli'; 
            font-size: 28px;
            font-style: normal; 
            font-variant: normal; 
            font-weight: 500;  
            color: #fff;
            margin: 0;
        }

        .features-button-slider{
            margin: 40px 00px;
            width: 100%;
        }

        .futures-slider-btn{
            width: 100%;
            height: 75px;
            background-image: linear-gradient(to bottom right, #EF4937, #F85914);
            // border: 2px dashed $a-pink-color;
            border: none;
            border-radius: 50px;
            padding: 5px;

            .inside-box{
                display: flex;
                align-items: center;
                justify-content: center;
                // background-color: $a-pink-color;
                color: #ffffff;
            }
        }

        .futures-slider-btn:hover{
            border: 5px solid #ffffff;
        }

        .futures-slider-btn-active{
            border: 5px solid #ffffff;

            .inside-box{
                font-weight: 700;
            }
        }
    }

    .features-right-section{
        position: relative;
        text-align: right;

        .outter-features{
            position: absolute;
            top: 30%;
            right: 5%;
            width: 42%;
            margin: auto;

            .features-slider .features-amealio-div-img{
                width: 100%;

                img{
                    width: 75%;
                    margin: 0 auto;
                }
            }
        }

        .features-right-section-image{
            width: 100%;
            z-index: 1;
            // position: absolute;
            // width: 48%;
            // z-index: 1;
            // right: 10px;
            // top: 25px; 
            
            img{
                width: 50%;
            }
        }

        .slick-slider {
            z-index: 5;
        }

        .slick-slider .slick-list{
            padding-top: 0px !important;
            padding-bottom: 0px !important;            
        }

        .features-slider{

            .features-amealio-div-img{
                width: 75%;
                margin: auto;
            }

            .features-amealio-head-divTwo{
                width: 68%;
                margin: auto;
                font-family: 'Muli'; 
                font-size: 22px;
                font-style: normal; 
                font-variant: normal; 
                font-weight: 500;  
                color: #000;
                margin: 20px auto; 
                text-align: center;
                // .features-head{
                //     font-family: 'Muli'; 
                //     font-size: 22px;
                //     font-style: normal; 
                //     font-variant: normal; 
                //     font-weight: 500;  
                //     color: #000;
                //     margin: 20px auto; 
                //     text-align: center;
                // }
            }
        }
    }

    .features-image-bottom{
        position: absolute;
        bottom: -25%;
        left: 0;
        width: 22%;
        height: auto;
        z-index: 1;

        .features-image-bottom-one{
        }

        .features-image-bottom-two{
            left: 15%;
            width: 72%;
            height: auto;
            position: absolute;
            top: 15%;
        }
    }
}

/****************************************
    *@DESC Top Main Home Slider
****************************************/
.top-main-slider{
    width: 100%;
    height: auto;
    padding: 0px 75px 45px 75px;
    // border: 1px solid #000;
    position: relative;

    .slider-home-page-left-one{
        z-index: 9;
        .paragraph-width-home{
            width: 90%;
            // margin-bottom: 125px ;
        }

        .h-p-s-o-padding{
            margin-bottom: 125px;
        }

        .h-s-two-css{
            margin-top: 125px;
            margin-bottom: 125px;
        }
    }

    .bottomicon-home{
        display: flex;
        align-items: center;
    }

    .register-width-style{
        width: 27%;
    }

    .arrow{
        right: -32px;
    }

    .icon-play-app-store{
        height: 38px;
        margin-right: 15px;
    }

    .slick-slider{
        .slick-list{
            padding-bottom: 0px !important;
        }
    }

    .top-main-slider-headone{
        font-family: "Muli";
        font-size: 60px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: bold;
        color: #000;
    }

    .top-main-slider-paraone{
        font-family: "Muli";
        font-size: 19px;
        line-height: 28px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 500;
        color: #000;
    }

    .slider-first-image-resize{
        position: relative;
        top: -20px;
        width: 250px;
        height: auto;
    }   
    
    .slider-second-image-resize{
        width: 350px;
        height: auto;
        position: absolute;
        top: 7px;   
        left: -20%;
    }

    .slider-third-image-resize{
        width: 350px;
        height: auto;
        position: absolute;
        top: 7px;   
        left: -20%;
    } 
    
    .home-second-slider{
        padding-right: 132px;
    }

    .home-section-superapp-superior{
        position: absolute;
        top: -20px;
        width: 32%;
        height: auto;
    }

    .enjoy-bottom-button-height{
        margin-top: 140px;
    }
}  

.top-main-button-one{
    min-width: 150px;
    min-height: 42px;            
    border-radius: 50px;
    font-family: 'Muli'; 
    font-size: 16px;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 700;
    padding: 0 40px;
}

.top-main-button-one-black{
    background-color: transparent;
    color: #000000;
    border: 4px solid #000000;
}

.top-main-button-one-red{
    background-color: transparent;
    color: #000000;
    border: 4px solid $a-red-color;
}

.top-main-button-one-black:hover{
    box-shadow: 0px 3px 6px $a-orange-color;
    background-color: $a-orange-color;
    border: 4px solid $a-red-color;
    color: #ffffff;
}

.top-main-button-one-red:hover{
    box-shadow: 0px 3px 6px $a-orange-color;
    background-color: $a-orange-color;
    border: 4px solid $a-red-color;
    color: #ffffff;
}

#slider-video-pedding{
    background-color: #F6F6F6;

    .slick-slider{
        .slick-list{
            padding: 30px 0px 50px 0px;
        }
    }
}

/****************************
 *@DESC Home Page Slide One
****************************/
.slider-home-page-one{
    position: relative;

        .outter-features{
            position: absolute;
            top: 5%;
            left: 5%;
            width: 90%;
            margin: auto;
        }

        .features-right-section-image{
            position: absolute;
            width: 62%;
            z-index: 1;
            right: -2px;
            top: 0px;   
            
            .download-button-css{
                position: absolute;
                bottom: 28%;
                left: 9%;
    
                .button-design{
                    min-width: 200px;
                    height:45px;
                    font-size: 16px;
                    color: #fff;
                    background-color: $a-orange-color;
                    font-weight: 500;
                    font-family: "Muli";
                    border-radius: 50px;
                }
    
                .button-design:hover{
                    min-width: 200px;
                    height: 47px;
                    font-size: 18px;
                    color: #fff;
                    background-color: $a-download-color;
                    font-weight: 700;
                    font-family: "Muli";
                    box-shadow: 0 3px 6px rgba(0,0,0,0.21);
                }
            }
        }

        .slick-slider {
            z-index: 5;
        }

        .slick-slider .slick-list{
            padding-top: 0px !important;
            padding-bottom: 0px !important;            
        }

        .features-slider{

            .features-amealio-div-img{
                width: 75%;
                margin: auto;
            }

            .features-amealio-head-divTwo{
                width: 68%;
                margin: auto;
                font-family: 'Muli'; 
                    font-size: 16px;
                    font-style: normal; 
                    font-variant: normal; 
                    font-weight: 700;  
                    color: #000;
                    margin: 20px auto; 
                    text-align: center;

                // .features-head{
                //     font-family: 'Muli'; 
                //     font-size: 16px;
                //     font-style: normal; 
                //     font-variant: normal; 
                //     font-weight: 700;  
                //     color: #000;
                //     margin: 20px auto; 
                //     text-align: center;
                // }
            }
        }
}