.error_404_section{
    .error_404_section_top{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;

        h1{
            font-size: 275px;
            margin: 0;
            line-height: 0px;
            font-family: "Muli";
            font-weight: bold;
        }

        img{
            margin: 0 30px;
            width: 250px;
            height: 250px;
        }

        .img-rotation {
            animation: rotation 15s infinite linear;
        }

        @keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(359deg);
            }
          }
    }

    .error_404_section_top_two{
        text-align: center;
        margin-bottom: 25px;

        .custom-button-data{
            background-color: $a-orange-color;
            border: 2px solid $a-orange-color;
            padding: 12px 20px;
        }
        
    }
}

@media(max-width: 992px) {
    .error_404_section{
        .error_404_section_top{
            h1{
                font-size: 100px;
            }
    
            img{
                margin: 0 10px;
                width: 110px;
                height: 110px;
            }

            @keyframes rotation {
                from {
                  transform: rotate(0deg) !important;
                }
                to {
                  transform: rotate(0deg) !important;
                }
              }
        }
    }
}