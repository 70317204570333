@media screen and (max-width: 767px) {

    .ratings-section{
        height: auto;
    }
    .graphs-section{
        height: auto;
    }
    .footer-versionf{
        padding: 40px 30px 40px 30px;
        background-color: #f8f6f1;
        
    }
    .amealio-box{
        height: 120px;
        width: 180px;
        margin: 0.5rem;
    }
    .yellow-box{
        height: 120px;
        width: 120px;
        margin: 0.5rem;
    }


    ///////////////////////
    //headings text /////
    /////////////////////
    

    .heading-h1{
        font-size: 2.6em;
    }
    .heading-h1w{
        font-size: 2.6em;
    }
    .heading-h2{
        font-size: 2em;
    }
    .heading-h4{
        font-size: 1.5em;
    }
    .heading-h5{
        font-size: 1.4em;
    }
    .heading-h5b{
        font-size: 2.6rem;
    }
    .footer-text{
        font-size: 18px;
    }
    .share-text-css{
        font-weight: 400;
        font-size: 2.6em;
    }
    .graphtext{
        font-size: 2.2rem;
        }
        .graphsubtext{
         font-size: 1rem;
       
        }


    ////////////////////////////
    /////buttons///////////////
    /// //////////////////////
    .download-btn{
        font-size: 20px;
        padding: 14px 60px;
    }

    .register-btn{
        justify-content: center;
        padding: 2rem 0;
    }
    .login-btn{
        justify-content: center;
        padding: 2rem  0;

    }
    .hallow-btn{
        font-size: 20px;
        padding: 14px 70px;
    }
    .hashtags{
        font-size: 1.6rem;
        letter-spacing: 1px;
     
    }

    /////////////////////////
    ///paddings/////////////
    /// ///////////////////
    .pd-y5{
        padding: 2rem 0;
    }
    .about-amealio-pd{
        padding: 0 2rem;
    }
    .pd-graph{
        padding: 3rem 1rem;
    }

    
    .friendOfAmealio{
        
        text-align: start;
        padding: 8rem 2rem;
    }
    .joinus-pd{
        padding: 5rem 0;
    }
    // .whyAmealio{
    //     padding: 0 2rem;
    // }
    .download-pd{
        font-size: 10px;
        padding: 14px 24px;
    }
    .hallow-pd{
        font-size: 14px;
        padding: 14px 10px;
    }
    .share-pd{
        padding: 5rem 0;
    }

    //images/////
    .star-img{
        width: 30px;
    }
    .gplay-img{
        width: 160px;
    }
    .testinomial-w{
        width: 75%;
    }
    .loginBtnsPadding{
        padding: 1rem 0;
    }
    .whyAmealio img{
        width: 40px;
        height: 40px;
    }
    .share-height{
        height: 26em;
        width: 14em !important;
    }


    ////animations///
  .animation {
    height:70px;
    margin-left: 0.5rem;

  }
    @keyframes text-animation {
        0% {margin-top: 0;}
        10% {margin-top: 0;}
        20% {margin-top: -5rem;}
        30% {margin-top: -5rem;}
        40% {margin-top: -5rem;}
        60% {margin-top: -10.24rem;}
        70% {margin-top: -10.22rem;}
        80% {margin-top: -10.22rem;}
        90% {margin-top: 0;}
        100% {margin-top: 0;}
      }
  }