//-----------------------cater Section
.away {
    display: flex;
    margin : 65px 0 0 0;
}

.cater-seclogo {
    display: flex;
    margin : 40px 0 0 50px;
}

.cater-scanlogo {
    margin : 0 35px 0 35px;
    padding: 0 0 0 0;
}

.cater-offerlogo {
    margin : 0 0 0 0;
    padding: 0 0 0 0;
}

.seclogo1 {
    margin: 0 0 0 40px;
}

.cater-des1{
    display: flex;
    align-items  : center;
}
.cater-blk1 {
    
    h1 {        
        font-family: "Muli";
        font-size: 50px;
        line-height: 65px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: bold;
        color: #000;
    }

    p {
        font-family: "Muli";
        font-size: 17px;
        line-height: 28px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 500;
        color: #000;
    }
}

.cater-blk2 {
    // margin : 50px 0 0 0;
    // padding: 58px 0 0 120px;
}

.cater-heading {
    // background: radial-gradient(#71adcf, #488cb2);
    background-color: $a-red-color;
    text-align: center;
    color     : white;
    // margin    : 50px 0 0 0;
    padding   : 40px 0;

    // h3 {
    //     margin-top   : 0px;
    //     margin-bottom: 20px;
    //     font-family: "Muli";
    //     font-size: 32px;
    //     line-height: 45px;
    //     font-style: normal;
    //     font-feature-settings: normal;
    //     font-variant: normal;
    //     font-weight: bold;
    // }

    // p {
    //     margin-bottom   : 25px;
    //     // font-size    : 25px;
    //     font-family: "Muli";
    //     font-size: 16px;
    //     line-height: 24px;
    //     font-style: normal;
    //     font-feature-settings: normal;
    //     font-variant: normal;
    //     font-weight: 500;
    //     padding         : 0 14% 0 14%;
    //     text-align      : center;
    // }

    button {
        background-color: $a-orange-color;
        color           : #000;
        border-radius   : 10px;
        font-family: "Muli";
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 500;
        margin-top: 25px;
    }

    button:hover {
        color           : #fff;
        border: 2px solid #fff;
    }
}

.modal-dialog {
    width    : 90%;
    max-width: 100%;
    /* margin: 30px auto; */
}

.mobilevideoappdescribe h1 {
    margin     : 50px 0px 0px 0px;
    font-size  : 38px;
    font-weight: 600;
}

.cater-enhanced {
    display      : flex;
    align-items  : center;
    // justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 50px;
}

.cater-search {
    display      : flex;
    align-items  : center;
    border-right: 2px solid #e6e6e6;

    img {
        width: 75px;
        height: 75px;
    }

    h4 {
        font-family: 'Muli'; 
        font-size: 16px;
        line-height: 21px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: bold;
        color: #212121;
        padding-left: 15px;
    }
}

.cater-scan {
    display      : flex;
    align-items  : center;
    padding-right: 40px;

    img {
        width: 24%;
    }

    h4 {
        margin-left: 15px;
    }
}

.cater-offers {
    display    : flex;
    align-items: center;

    img {
        width: 32%;
    }

    h4 {
        margin-left: 15px;
    }
}

//-----------------------Walkin Section
.walkin-readmore {
    display: flex;
    margin : 60px 10px;

    .heading-text{
        color: #276A90;
    }
}

.walkin-readmore .walkin-contactfree {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius   : 20px;
    backdrop-filter : blur(30px);
}

.walkin-contactfree {
    border-radius: 20px;
    box-shadow   : 0 3px 2px 0px #d6d6d6;
    border       : none;
    margin       : 0 20px 0px 0px;
    border       : none;

    h4 {
        color : #357497;
        margin: 30px 0 10px 15px;
    }

    p {
        margin: 30px 0 0 15px;
    }

    button {
        font-family: "Muli";
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 500;
    }
}

.arw1 {
    margin-top   : 35px;
    // margin-bottom: 30px;

    i {
        margin-left: 15px;
        color      : black;
    }

    button {
        font-size       : 15px;
        background-color: white;
        border          : none;
        color           : black;
    }
}

.walkin-waitlist {
    border-radius: 20px;
    box-shadow   : 0 3px 3px 0px #d6d6d6;
    border       : none;
    margin       : 0 20px 0px 0px;

    h4 {
        color : #357497;
        margin: 30px 0 10px 15px;
    }

    p {
        margin        : 10px 0 0 15px;
        padding-bottom: 23px;
    }

    button {
        font-family: "Muli";
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 500;
    }
}

.bannerconfigation .bannerfeature img {

    overflow: hidden;
}


.arw2 {
    margin-top   : 30px;
    margin-bottom: 30px;

    i {
        margin-left: 15px;
        color      : black;
    }

    button {
        font-size       : 15px;
        background-color: white;
        border          : none;
        color           : black;
    }
}

.walkin-reserve {
    border-radius: 20px;
    margin       : 0 0 0 0;
    box-shadow   : 0 3px 3px 0px #d6d6d6;
    border       : none;

    h4 {
        color : #357497;
        margin: 30px 0 10px 15px;
    }

    p {
        margin: 10px 0 0 15px;
    }

    button {
        font-size: 20px;
    }
}

.arw3 {
    margin-top   : 35px;
    // margin-bottom: 30px;

    i {
        margin-left: 15px;
        color      : black;
    }

    button {
        font-size       : 15px;
        background-color: white;
        border          : none;
        color           : black;
    }
}

// .arw3 {
//     margin-top: 12px;

//     i {
//         margin-left: 15px
//     }

//     button {
//         font-size       : 15px;
//         background-color: white;
//         border          : none;
//     }
// }

.walkin-heading {
    // background: radial-gradient(#959ce6, #5b64c4);
    background-color: #707070;
    text-align: center;
    color     : white;
    // margin    : 20px 0 0 0;
    padding   : 40px 0;

    button {
        background-color: $a-orange-color;
        color           : #000;
        border-radius   : 10px;
        font-family: "Muli";
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 500;
    }
}

.subheading-modal-content {
    width     : 90%;
    margin    : auto;
    margin-top: 70px;
}

.subheading-modal-dialog {
    margin: auto
}


.just {
    border-radius: 20px;
    box-shadow   : 0 3px 3px 0px #d6d6d6;
    margin       : 0 20px 0px 0px;
    border       : none;

    // h4 {
    //     color : #357497;
    //     margin: 10px 0 10px 15px;
    // }

    // p {
    //     margin: 10px 0 0 15px;
    // }

    button {
        font-size: 20px;
    }
}

.arw4 {
    margin-top   : 35px;
    margin-bottom: 10px;

    i {
        margin-left: 15px;
        color      : black;
    }

    button {
        font-size       : 15px;
        margin-top      : 0px;
        background-color: white;
        border          : none;
        color           : black;
    }
}

.favourite {
    border-radius: 20px;
    box-shadow   : 0 3px 3px 0px #d6d6d6;
    margin       : 0 20px 0px 0px;
    border       : none;

    // h4 {
    //     color : #357497;
    //     margin: 10px 0 10px 15px;
    // }

    // p {
    //     margin: 10px 0 0 15px;
    // }

    button {
        font-family: "Muli";
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 500;
    }
}

.arw5 {
    margin-top: 15px;

    i {
        margin-left: 15px;
        color      : black;
    }

    button {
        font-size       : 15px;
        margin-top      : 0px;
        background-color: white;
        border          : none;
        color           : black;
    }
}

.easier {
    border-radius: 20px;
    margin       : 0 0 0 0;
    box-shadow   : 0 3px 3px 0px #d6d6d6;
    border       : none;

    h4 {
        color : #357497;
        margin: 10px 0 10px 15px;
    }

    p {
        margin: 10px 0 0 15px;
    }

    button {
        font-size: 20px;
    }
}

.arw6 {
    margin-top: 35px;

    i {
        margin-left: 15px;
        color      : black;
    }

    button {
        font-size       : 15px;
        margin-top      : 0px;
        background-color: white;
        border          : none;
        color           : black;
    }
}

//-----------------------reward Section
.referral {
    color      : black;
    text-align : center;
    padding-top: 45px;

    // h2 {
    //     line-height: 50px;
    // }
}

.referlogo {
    // display        : flex;
    // justify-content: space-between;
    // align-items    : center;
    justify-content: center;
    margin         : 40px 0px 70px 0;

    // h4{
    //     font-family: "Muli";
    //     font-size: 16px;
    //     line-height: 24px;
    //     font-style: normal;
    //     font-feature-settings: normal;
    //     font-variant: normal;
    //     font-weight: 500;
    // }
}

.coupon {
    h4 {
        margin: 5px 8px;
    }
}

.loyal {
    h4 {
        margin: 10px -12px;
    }
}

.cashbacks {
    h4 {
        margin-top: 8px;
    }
}

.offers {
    h4 {
        margin: 10px 25px;
    }
}

//-----------------------navigation Section
.cnt1 {
    h2 {
        margin     : 45px 0 0 60px;
        font-size  : 24px;
        font-weight: 600;
    }

    p {
        //font-weight: 700;
        margin: 15px 0 0 60px;
    }
}

.cnt2 {
    padding-left: 110px;
    padding-top : 10px;
}

.cnt3 {
    padding-top: 10px;
}

.cnt4 {
    h2 {
        padding   : 75px 80px 0 0;
        text-align: right;
    }

    p {
        padding   : 35px 80px 0 0;
        text-align: right;
    }
}

.convenience-heading {
    // background: radial-gradient(#fee6ff, #f5dcf6);
    background-color: $a-orange-color;
    text-align: center;
    padding   : 40px 0;
    color: #fff;
    // margin    : 50px 0 0px 0;

    // h1 {
    //     margin-top   : 0px;
    //     margin-bottom: 20px;
    //     font-family: "Muli";
    //     font-size: 32px;
    //     line-height: 45px;
    //     font-style: normal;
    //     font-feature-settings: normal;
    //     font-variant: normal;
    //     font-weight: bold;
    // }

    // p {
    //     margin-top   : 30px;
    //     margin-bottom   : 30px;
    //     // font-size    : 25px;
    //     font-family: "Muli";
    //     font-size: 18px;
    //     line-height: 24px;
    //     font-style: normal;
    //     font-feature-settings: normal;
    //     font-variant: normal;
    //     font-weight: 500;
    //     padding         : 0 14% 0 14%;
    //     text-align      : center;
    // }

    .button-getapp {
        background-color: #EEB84E;
        color: #fff;
        border-radius: 50px;
        padding: 12px 30px;
        border: 4px solid #fff;
        font-family: "Muli";
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 700;
    }

    .button-getapp:hover {
        box-shadow   : 0px 3px 6px #ECECEC;
    }
}

.reward-refer>li.active>a,
.reward-refer>li.active>a:hover,
.reward-refer>li.active>a:focus {
    color           : white;
    background-color: $a-red-color;
    border-radius   : 15px;
}

.reward-refer>li>a:hover{
    color           : white;
    background-color: $a-orange-color !important;
    border-radius   : 15px;
}

.reward-refer>li {
    float        : left;
    border-radius: 15px;
    margin-bottom: 0;
    list-style   : none;
    font-size    : 23px;
    width        : 50%;
    box-shadow   : 2px 3px 0px 0px #cccc;
}

.reward-refer>li>a {
    text-align: center;
    color     : black;
}

.reward-refer {
    justify-content: space-evenly
}

.bulletin span:before {
    // content      : '';
    width        : 10px;
    height       : 10px;
    border-radius: 50%;
    background   : #212121;
    margin       : 10px 10px 0 0;
}

.mobileappdescribe {
    span {
        font-size : 19px;
        text-align: right;
        color     : black;
    }

    h1 {
        font-weight: 500;
    }
}

.videoapp-bttn {
    padding      : 10px 15px 10px 15px;
    color        : aliceblue;
    border-radius: 10px;
    font-size    : 18px;
    margin-top   : 20px;
}

.arw {
    margin-top   : 35px;
    margin-bottom: 10px;

    i {
        margin-left: 15px;
        color      : black;
    }

    button {
        font-size       : 15px;
        background-color: white;
        border          : none;
        color           : black;
    }
}

@media(max-width: 1200px) {
    .conditiondescribe ul {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: center;
        margin-bottom  : 30px;
        text-align     : center;
        position       : relative;
    }
}

@media(max-width: 992px) {
    .cater-des1{
        text-align: center;
    }
    .mobileapp img {
        width          : 55%;
        display        : flex;
        justify-content: flex-end;
        margin         : auto;
    }

    .videoapp-bttn {
        padding      : 10px 15px 10px 15px;
        color        : aliceblue;
        border-radius: 10px;
        font-size    : 18px;
        margin-top   : 20px;
    }
}

@media(max-width: 764px) {
    
    .mobileappdescribe h1 {
        font-weight: 600;
        font-size  : 24px;
    }

    .walkin-readmore {
        display        : flex;
        margin         : 60px 10px;
        flex-wrap      : wrap;
        justify-content: center;
    }

    .walkin-contactfree {
        border-radius: 20px;
        box-shadow   : 0 3px 2px 0px #d6d6d6;
        border       : none;
        margin       : 0 20px 20px 0px;
        border       : none;
    }

    .walkin-waitlist {
        border-radius: 20px;
        box-shadow   : 0 3px 3px 0px #d6d6d6;
        border       : none;
        margin       : 0 20px 20px 0px;
    }

    .walkin-reserve {
        border-radius: 20px;
        margin       : 0 20px 20px 0;
        box-shadow   : 0 3px 3px 0px #d6d6d6;
        border       : none;
    }

    .away {
        display        : flex;
        /* margin      : unset; */
        margin         : 30px 0px 0px 0;
        justify-content: center;
        flex-wrap      : wrap;
    }

    .just {
        border-radius: 20px;
        box-shadow   : 0 3px 3px 0px #d6d6d6;
        margin       : 0 20px 40px 0px;
        border       : none;
    }

    .favourite {
        border-radius: 20px;
        box-shadow   : 0 3px 3px 0px #d6d6d6;
        margin       : 0 20px 40px 0px;
        border       : none;
    }

    .easier {
        border-radius: 20px;
        margin       : 0 0 20px 0;
        box-shadow   : 0 4px 0 1px #d6d6d6;
        border       : none;
    }

    // .referral h2 {
    //     line-height: 30px;
    // }

    .referlogo {
        // flex-wrap      : wrap;
        // display        : flex;
        // justify-content: space-between;
        // align-items    : center;
        // margin         : 40px 0px 70px 0;
        // justify-content: center;
    }
}

@media(max-width: 600px) {
    .convenience-heading p {
        margin: 30px 15px;
    }
    

}

@media(max-width: 480px) {

    .reward-refer>li.active>a,
    .reward-refer>li.active>a:hover,
    .reward-refer>li.active>a:focus {
        color           : white;
        background-color: #ee3d23;
        border-radius   : 6px;
        font-size       : medium;
    }

    .reward-refer>li>a {
        text-align: center;
        color     : black;
        font-size : medium;
        padding   : 10px 10px;
    }

    .cater-blk1 h1 {
        font-size  : 20px;
        color      : black;
        margin     : 100px 0 0 0;
        line-height: 26px;
    }

    .cater-blk1 p {
        font-size: 14px;
        padding  : 0;
    }

    .walkin-contactfree p {
        margin: 10px 0 0 15px;
    }

    .cater-blk2 {
        padding   : 0;
        text-align: center;
        display   : inline-block;
        // margin    : 0 0px 0 35px;
    }

    .cater-search {
        border: none !important;
        display : block !important;
        text-align: center !important;

        h4{
            padding: 0 !important;
            font-size: 12px;
            line-height: 18px;
        }
    }

    // .cater-search img {
    //     width : 75%;
    //     margin: 0px 0 15px 14px;
    // }

    // .cater-search h4 {
    //     margin-left: 24px;
    //     font-size  : 12px;
    // }

    .cater-scan {
        align-items  : center;
        padding-right: 8px;
        padding-left : 8px;
        display      : inline;
        margin-top   : 18px;
        text-align   : center;
        border-right : 1px solid #6a6b73;
        border-left  : 1px solid #6a6b73;

    }

    .cater-scan img {
        width     : 45%;
        margin-top: 22px;
        padding   : 0px 0px 10px 0;
    }

    .cater-scan h4 {
        font-size: 12px;
        width    : 85%;
    }

    .cater-offers {
        align-items: center;
        display    : inline;
        padding    : 0 18px 0 0;
        margin-top : 15px;
        text-align : center;
    }

    .cater-offers img {
        width     : 80%;
        margin-top: 25px;
        padding   : 0px 0px 12px 5px;
    }

    .cater-offers h4 {
        font-size: 12px;
        width    : 113%;
    }

    .cater-heading {
        h3 {
            font-size  : 20px;
            line-height: 29px;
            width      : 77%;
            margin     : 16px auto;
        }

        p {
            margin-bottom: 25px;
            font-size    : 14px;
            padding      : 17px;
        }
    }

    .walkin-readmore {
        display: inline;
        margin : 60px 10px;
    }

    .walkin-waitlist {
        border-radius: 20px;
        box-shadow   : 0px 3px 2px 0px #d6d6d6;
        border       : none;
        margin       : 0 20px 70px 20px;
    }

    .walkin-reserve {
        border-radius: 20px;
        box-shadow   : 0 3px 2px 0px #d6d6d6;
        border       : none;
        margin       : 0 20px 70px 20px;

        img {
            vertical-align: middle;
        }
    }

    .walkin-heading {
        background: radial-gradient(#959ce6, #5b64c4);
        text-align: center;
        color     : white;
        margin    : 20px 0 0 0;
        padding   : 17px 10px 17px 10px;

        h3 {
            line-height: 29px;
            font-size  : 20px;
        }

        p {
            line-height: 24px;
            font-size  : 16px;
            padding    : 8px 8px;
        }
    }

    .away {
        display  : flex;
        margin   : 60px 10px;
        flex-wrap: wrap;
    }

    .walkin-contactfree {
        margin: 0 20px 70px 20px;
    }

    .just {
        border-radius: 20px;
        box-shadow   : 0 3px 2px 0px #d6d6d6;
        border       : none;
        margin       : 0 20px 70px 20px;
    }

    .favourite {
        border-radius: 20px;
        box-shadow   : 0 3px 2px 0px #d6d6d6;
        border       : none;
        margin       : 0 20px 70px 20px;
    }

    .easier {
        border-radius: 20px;
        margin       : 0 0 0 0;
        box-shadow   : 0 3px 2px 0px #d6d6d6;
        border       : none;
        margin       : 0 20px 10px 20px;
    }

    .referlogo {
        // display        : grid;
        // justify-content: center;
        // align-items    : center;
        // padding        : 45px 75px;
        // flex-wrap      : wrap;
    }

    .convenience-heading p {
        margin: 30px 15px;
    }

    .cnt2 {
        padding-left: 0px;
        padding-top : 10px;
    }

    .cnt1 h2 {
        margin: 40px 0 0 20px;
    }

    .cnt1 p {
        font-weight: 700;
        margin     : 15px 0 0 20px;
    }

    .reward-refer>li {
        float        : left;
        border-radius: 6px;
        margin-bottom: 0;
        list-style   : none;
        font-size    : 12px;
        width        : 50%;
        box-shadow   : 2px 3px 0px 0px #cccc;
    }

    .cnt4 h2 {
        padding   : 0px 0px 0 0;
        text-align: left;
    }

    .cnt4 p {
        padding   : 0 20px 0 0;
        text-align: left;
    }

    .convenience-heading h1 {
        line-height: 28px;
        color      : black;
        padding    : 0 10px;
    }

    .row.joy {
        flex-direction: column-reverse;
    }

    .vl {
        border-left: 1px solid blue !important;
        height     : 100px;
    }

    .referral {
        color        : black;
        text-align   : center;
        padding-top  : 10px;
        padding-left : 10px;
        padding-right: 10px;
    }

    .mobileappdescribe h1 {
        margin     : 10px;
        padding-top: 15px;
    }

    .mobileappdescribe span {
        font-size: 14px;
    }

    .mobilevideoappdescribe {
        max-width : 100%;
        text-align: center;
        display   : inline-block;
        width     : 100%;
    }

    img.amealiomobilevideo {
        width  : 100%;
        padding: 50% 10% 50% 10%;
    }
}

.deliverycondition p{
    font-weight: 500;
    text-align: center;
    font-size: 26px;
    margin-top: 25px;
}
