.about-amealio {
    width: 100%;

    img {
        width: 100%;
    }
}

body {
    overflow-x: hidden;
}

.about-section-margin {
    margin: 50px 0 0 0;
}

.aboutbanner1 {
    padding-top: 205px;

    h1 {
        font-size: 58px;
        color: black;
        font-weight: 600;
    }

    p {
        font-size: 22px;
        color: black;
        padding: 20px 0px 0px 0px;
    }

    img {
        width: 100%;
        margin-top: -50%;
    }
}

.topaboutbanner {
    background-color: #f391d9;
    position: relative;
}

.amealiodescribe {
    color: black;
    position: absolute;
    top: 30%;

    h1 {
        font-size: 53px;
        font-weight: 600;
    }

    p {
        font-size: 20px;
        margin-top: 40px;
    }
}

.amealiosonic {
    position: absolute;
    bottom: 0;

    img {
        width: 100%;
    }
}

.aboutbanner2 {
    background-color: #ffffff;
    position: relative;

    span {
        font-size: 20px;
        font-weight: 600;
    }

    h3 {
        font-size: 30px;
        font-weight: 600;
    }

    h2 {
        font-weight: 600;
        color: black;
        padding-top: 40px;
        font-size: 40px;
    }

    p {
        font-size: 18px;
        padding-top: 43px;
        font-weight: 500;
    }

    .banner2image {
        img {
            width: 165%;
            margin: 35px 0px 40px -45px;
        }
    }
}

.amelia-wave {
    position: relative;
    z-index: 2;
}

.ameimg {
    display: none;
}

.btn.btn-warning.btn2 {
    color: #212121;
    background-color: #fab516;
    font-weight: 500;
    font-size: 17px;
    margin-top: 22px;
}

.banner2img {
    padding-top: 23px;
    width: 19%;

    .button2 {
        padding-top: 38px;
        position: absolute;
        z-index: 1;
    }

    .btn2 {
        color: #212121;
        background-color: rgb(250, 181, 22);
        font-weight: 600;
        font-size: 17px;
    }

    .banner2image {
        width: 100%;
        padding-top: 30px;
    }
}

.aboutbanner3 {
    display: flex;
    text-align: center;
    background: radial-gradient(#6fabce, #4387ad);
    padding: 1px 0px 30px 0px;

    h1 {
        font-weight: 600;
        padding-top: 50px;
        color: #fff;
    }

    p {
        padding: 20px 0px 20px 0px;
        font-size: 19px;
        font-weight: 500;
        color: #fff;
    }
}

.maintainerblog {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 190px;
    margin: 0 24px;
}

.top-sprial-image-right{
    position: absolute;
    right: 0;
    top: -70px;

}

.passbag {
    height: 1px;
    width: 100%;
    border-bottom: 1px dotted #aca8a8f7;
    position: absolute;
    bottom: 208px;
}

.dialogtriger {
    width: 3px;
    height: 135px;
    background-color: #4b83a2;
    position: absolute;
    bottom: 100px;
}

.dialogtriger1 {
    width: 3px;
    height: 135px;
    background-color: #4b83a2;
    position: absolute;
    top: 100px;
}

span.dialogtriger1:after {
    content: "";
    height: 15px;
    display: inline-block;
    width: 15px;
    background: #4b83a2;
    position: absolute;
    bottom: 0;
    float: left;
    border-radius: 10px;
    margin: 0px 0px -8px -6px;
}

span.dialogtriger:after {
    content: "";
    height: 15px;
    display: inline-block;
    width: 15px;
    background: #4b83a2;
    position: absolute;
    top: 0;
    float: left;
    border-radius: 10px;
    margin: 0px 0px -8px -6px;
}

.aboutbanner4 {
    // background-color: #fff;

    .passingmaintane{
        height: 350px;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .passingmaintane h2 {
        font-family: "Muli";
        color: #212121;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
    }

    .passingmaintane h4 {
        color: #000000;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        font-family: "Muli";
    }

    .img-ourtimeline-two {
        width: 50%;
        height: auto;
    }

    .img-ourtimeline-three{
        width: 42%;
        height: auto;
    }

    .bottom-our-timeline{
        position: absolute;
        bottom: 12%;
        right: 15%;

        h2{
            font-family: "Muli";
            color: #212121;
            text-align: center;
            font-weight: bold;
            font-size: 42px;
        }

        h4{
            font-family: "Muli";
            color: #212121;
            text-align: center;
            font-weight: 600;
            font-size: 24px;
        }

        p{
            font-family: "Muli";
            color: #212121;
            text-align: center;
            font-weight: 500;
            font-size: 18px;
        }
    }

}
    .passingyears {
        h2 {
            font-size: 20px;
            border: 3px solid #276a90;
            width: 100px;
            height: 100px;
            border-radius: 50px;
            text-align: center;
            padding-top: 19px;
            font-weight: 600;
            z-index: 1;
            position: relative;
            background-color: #fff;
        }
    }

    .timingdescribe.menuplate {
        position: absolute;
        top: 0;
        width: 20%;
    }

    .contact-dialog {
        display: flex;
        justify-content: center;
        position: relative;
    }
// .timingdescribe.menuplate{
//     h3{
//         padding-top: 20px;
//     }
// }
.timingdescribe.menuplate h3 {
    padding-top: 100px;
    padding-right: 32px;
}
    .timingdescribe {
        text-align: center;
        margin-bottom: 75px;

        h3 {
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 1px;
        }

        p {
            font-size: 14px;
        }

        .menuplate {
            margin-bottom: 50px;
        }
    }


ul.nav.nav-pills.wallet {
    // background-color: #fff;
    // box-shadow: solid;
    // box-shadow: 3px 3px #edebeb;
    border-radius: 15px;

    li {
        margin: 0;
    }
}

.aboutbanner5 {
    // padding: 100px 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // padding: 0px 88px;

    h1 {
        color: black;
        font-weight: 600;
        margin: 0;
    }

    button {
        color: dark;
        background-color: rgb(7, 72, 109);
    }

    p {
        font-size: 23px;
        padding-top: 33px;
        font-weight: 500;
    }

    .btn5 {
        padding: 30px 0px;
    }

    button {
        font-size: 15px;
        border-radius: 10px;
        padding: 10px;
    }

    img {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding-top: 20px;
    }
}

.tab-box {
}

.aboutbanner6 {
    // background-color: #ffffff;

    img {
        // width: 150px;
        //padding-top: 40px;
    }

    h1 {
        font-family: "Muli";
        font-size: 42px;
        font-weight: 700;
        color: #212121;
        text-align: center;
        padding-top: 30px;
    }

    .banner6navbar {
        padding: 50px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: space-between;
        margin-bottom: 65px;
    }

    a {
        // font-size: 30px;
        padding: 0px;
        color: rgb(23, 23, 23);
    }
}

// li {
//     margin-left: -3px;
// }
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    background-color: transparent;
    color: #ee3d23;
    border-radius: 0px;
    border-bottom: 3px solid #ee3d23;
}

ul.nav.nav-pills.wallet {
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-around;
    border: 3px solid #707070;
    border-radius: 50px;
    padding: 12px 0px !important;

    li a {
        width: 100%;
        // font-size: 25px;
        padding: 15px 25px 15px 25px;
        // border-right: 3px solid #707070;
        border-radius: 0px !important;
    }
}

.nav-pills > li.get.active > a {
    background-color: transparent;
}

.nav-pills > li > a {
    border-radius: 9px;
}

.contentblog {
    width: 100%;
    display: flex;
    justify-content: center;
}

.passingyears {
    width: 20%;
}

.aboutbanner7 {
    display: flex;
    text-align: center;
    justify-content: center;

    img {
        height: fit-content;
    }

    a {
        font-weight: 600;
        color: rgb(87, 87, 87);
        text-decoration: none;
        font-size: 20px;
        padding: 20px 0px;
    }
}

.navbar {
    // padding-top: 0px;

    // button {
    //     font-size: 29px;
    //     background-color: rgb(87, 87, 87);
    // }
}

.col-xs-2 {
    background: #00f;
    color: #fff;
}

.col-half-offset {
    margin-left: 4.166666667%;
}

.amealioimage {
    display: none;
}

.tab-box {
}

@media screen and (max-width: 1200px) {
    .about-amealio {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .carouseldiscover .slide-button {
        top: 690px;
        left: 275px;
    }

    .amealiodescribe {
        color: black;
        position: absolute;
        top: 30%;

        h1 {
            font-size: 50px;
            font-weight: 600;
        }

        p {
            font-size: 18px;
            margin-top: 40px;
        }
    }

    .aboutbanner2 {
        background-color: #ffffff;
        position: relative;
        overflow: hidden;

        span {
            font-size: 20px;
            font-weight: 600;
        }

        h3 {
            font-size: 25px;
            font-weight: 600;
        }

        h2 {
            font-weight: 600;
            color: black;
            padding-top: 40px;
            font-size: 30px;
        }

        p {
            font-size: 18px;
            padding-top: 43px;
            font-weight: 600;
        }

        .banner2image {
            img {
                width: 100%;
                margin: 25px 0px 35px 0px;
            }
        }
    }

    .passbag {
        height: 1px;
        width: 100%;
        border-bottom: 1px dotted #aca8a8f7;
        position: absolute;
        bottom: 230px;
    }
}

@media (max-width: 992px) {
    .about-amealio {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .amealiodescribe {
        color: black;
        position: absolute;
        top: 30%;

        h1 {
            font-size: 41px;
            font-weight: 600;
        }

        p {
            font-size: 14px;
            margin-top: 40px;
        }
    }

    .aboutbanner6 ul.nav.nav-pills.wallet li a {
        width: 100%;
        font-size: 22px;
        padding: 15px 25px 15px 25px;
    }

    .aboutbanner6 img {
        // width: 95%;
        padding-top: 40px;
    }

    .passbag {
        height: 1px;
        width: 100%;
        border-bottom: 1px dotted #aca8a8f7;
        position: absolute;
        bottom: 250px;
    }
}

@media (max-width: 764px) {
    .about-amealio {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .amealiodescribe {
        color: black;
        position: absolute;
        top: 30%;

        h1 {
            font-size: 47px;
            font-weight: 600;
        }

        p {
            font-size: 19px;
            margin-top: 40px;
        }
    }

    .learn-butn {
        display: flex;
        justify-content: center;
    }

    .aboutbanner3 {
        display: flex;
        text-align: center;
        background: radial-gradient(#6fabce, #4387ad);
        margin: 50px 0px;
        padding: 1px 0px 30px 0px;

        h1 {
            font-size: 40px;
            font-weight: 450;
            padding-top: 50px;
            color: #fff;
        }

        p {
            padding: 20px 0px 20px 0px;
            font-size: 20px;
            font-weight: 350;
            color: #fff;
        }
    }

    .aboutbanner4 .timingdescribe.menuplate {
        width: 100%;
        top: -20px;
    }

    .passingyears {
        width: 100%;
    }

    .contentblog {
        display: block;
    }

    .passbag {
        border-bottom: 0px;
    }

    .contact-dialog.cls1 {
        margin: 0;
    }

    .passingyears.cls0 {
        margin-top: 242px;
    }

    .passingyears.cls2 {
        margin-top: -30px;
    }

    .contact-dialog.cls3 {
        margin: 25px;
    }

    .passingyears.cls4 {
        margin-top: 242px;
    }

    .contact-dialog.cls5 {
        margin-top: 0;
    }

    .aboutbanner6 ul.nav.nav-pills.wallet li a {
        width: 100%;
        font-size: 15px;
        padding: 15px 25px 15px 25px;
    }

    .aboutbanner6 img {
        // width: 95%;
        padding-top: 40px;
    }
}

@media (max-width: 600px) {
    .amealiosonic.imghide {
        display: none;
    }

    .aboutbanner2 .banner2image img {
        margin: 25px 0px 0px 0px;
    }

    .ressimg {
        display: flex;
        justify-content: center;
    }

    .ameimg {
        display: block;
        width: 75%;
        overflow: hidden;
    }

    .about-amealio {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .amealiodescribe {
        color: black;
        position: absolute;
        top: 30%;

        h1 {
            font-size: 30px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
            margin-top: 25px;
        }
    }

    .aboutbanner2 {
        h2 {
            text-align: center;
            font-weight: 600;
            color: black;
            padding-top: 40px;
            font-size: 25px;
        }

        h3 {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }

        p {
            text-align: center;
            font-size: 15px;
            padding-top: 43px;
            font-weight: 600;
        }
    }

    .amealio-res {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        order: 2;
    }

    .aboutbanner3 {
        display: flex;
        text-align: center;
        background: radial-gradient(#6fabce, #4387ad);
        margin: 50px 0px;
        padding: 1px 0px 30px 0px;

        h1 {
            font-size: 30px;
            font-weight: 450;
            padding-top: 50px;
            color: #fff;
        }

        p {
            padding: 20px 0px 20px 0px;
            font-size: 20px;
            font-weight: 200;
            color: #fff;
        }
    }

    .aboutbanner4 .contact-dialog {
        display: flex;
        justify-content: center;
        position: relative;
        margin: 40px 0px 0px 0;
    }

    .aboutbanner4 .timingdescribe.menuplate {
        width: 100%;
    }

    .passingyears {
        width: 100%;
    }

    .contentblog {
        display: block;
    }

    .passbag {
        border-bottom: 0px;
    }

    .contact-dialog.cls1 {
        margin: 0;
    }

    .passingyears.cls0 {
        margin-top: 242px;
    }

    .passingyears.cls2 {
        margin-top: -30px;
    }

    .contact-dialog.cls3 {
        margin: 25px;
    }

    .passingyears.cls4 {
        margin-top: 242px;
    }

    .contact-dialog.cls5 {
        margin-top: 0;
    }

    .aboutbanner5 {
        padding: 100px 0px;
        background-color: #f6e1f7;
        margin: 0px 0px;

        h1 {
            color: black;
            font-weight: 600;
            margin: 0;
            text-align: center;
        }

        button {
            color: dark;
            background-color: rgb(7, 72, 109);
        }

        p {
            font-size: 14px;
            padding-top: 33px;
            font-weight: 500;
            text-align: center;
        }

        .btn5 {
            padding: 30px 0px;
            justify-content: center;
            display: flex;
        }

        button {
            font-size: 15px;
            border-radius: 10px;
            padding: 10px;
        }

        img {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            padding-top: 20px;
        }
    }

    .aboutbanner6 ul.nav.nav-pills.wallet li a {
        width: 100%;
        font-size: 12px;
        padding: 12px 15px 12px 15px;
    }

    .aboutbanner6 ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: space-around;
    }

    .aboutbanner6 img {
        // width: 95%;
        padding-top: 40px;
    }

    .aboutbanner6 ul.nav.nav-pills.wallet li a {
        width: 100%;
        font-size: 12px;
        padding: 16px 14px 16px 14px;
    }
}

@media (max-width: 480px) {
    .amealiosonic.imghide {
        display: none;
    }

    .aboutbanner2 .banner2image img {
        margin: 25px 0px 0px 0px;
    }

    .ameimg {
        display: block;
        width: 75%;
        overflow: hidden;
    }

    .banner2img {
        padding-top: 23px;
        width: 100%;
        /* margin      : auto; */
        /* margin      : 0 0 0 58px; */
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .about-amealio {
        width: 100%;

        img {
            width: 100%;
            height: 350px;
        }
    }

    .amealiodescribe {
        color: black;
        position: absolute;
        top: 25%;

        h1 {
            font-size: 20px;
            font-weight: 600;
        }

        p {
            font-size: 15px;
            margin-top: 15px;
        }
    }

    .res-order {
        order: 2;
    }

    .aboutbanner2 {
        h2 {
            text-align: center;
            font-weight: 600;
            color: black;
            padding-top: 40px;
            font-size: 20px;
        }

        h3 {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
        }

        p {
            text-align: center;
            font-size: 14px;
            padding-top: 43px;
            font-weight: 600;
        }
    }

    .amealio-res {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        order: 2;
    }

    .aboutbanner3 {
        display: flex;
        text-align: center;
        background: radial-gradient(#6fabce, #4387ad);
        margin: 50px 0px;
        padding: 1px 0px 30px 0px;

        h1 {
            font-size: 25px;
            font-weight: 450;
            padding-top: 50px;
            color: #fff;
        }

        p {
            padding: 0px;
            font-size: 15px;
            font-weight: 200;
            color: #fff;
        }
    }

    .aboutbanner4 .contact-dialog {
        display: flex;
        justify-content: center;
        position: relative;
        margin: 95px 5px 0px 0;
    }

    .passbag {
        border-bottom: 0px;
    }

    .aboutbanner5 {
        padding: 100px 0px;
        background-color: #f6e1f7;
        margin: 50px 0px;

        h1 {
            color: black;
            font-weight: 600;
            text-align: center;
            padding-top: 50px;
            order: 2;
        }

        button {
            color: dark;
            background-color: rgb(7, 72, 109);
        }

        p {
            font-size: 19px;
            padding-top: 33px;
            font-weight: 500;
            text-align: center;
        }

        .btn5 {
            padding: 30px 0px;
            justify-content: center;
            display: flex;
        }

        button {
            font-size: 15px;
            border-radius: 10px;
            padding: 10px;
        }

        img {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            padding-top: 20px;
        }
    }

    .aboutbanner6 {
        background-color: #ffffff;

        img {
            width: 100px;
            padding-top: 40px;
        }

        h1 {
            font-size: 30px;
            font-weight: 600;
            color: #212121;
            text-align: center;
            padding-top: 30px;
        }

        .banner6navbar {
            padding: 0px 0px;
        }

        ul {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            list-style: none;
            margin-bottom: 70px;
        }

        a {
            font-size: 30px;
            padding: 0px;
            color: rgb(23, 23, 23);
        }

        .nav-pills > li.active > a,
        .nav-pills > li.active > a:hover,
        .nav-pills > li.active > a:focus {
            background-color: #ee3d23;
            color: #fff;
            border-radius: 16px;
        }

        ul.nav.nav-pills.wallet {
            flex-wrap: nowrap;
            width: 100%;
            justify-content: space-around;

            li a {
                width: 100%;
                font-size: 14px;
                padding: 13px 10px 13px 10px;
            }

            a span {
                display: none;
            }
        }

        .nav-pills > li.get.active > a {
            background-color: #ee3d23;
        }

        .nav-pills > li > a {
            border-radius: 9px;
        }
    }
}

.aboutbanner6 {
    .tab-content {
        ul.ngo-aline5 {
            justify-content: space-around;
        }
        #menu1ul {
            li {
                height: 94px;
                width: calc((100% / 5) - 26px);
            }
        }
        ul {
            li {
                // border         : 1px solid #ccc;
                // border-radius  : 11px;
                margin: 30px 0px 30px -9px;
                display: flex;
                justify-content: center;
                height: 94px;
                // width: calc((100% / 5) - 26px);
                padding: 0 6px;
                align-items: center;
                box-sizing: border-box;
                // img{
                //     width: 100%;
                // }
                // img.ngo-aline {
                //     width: 129px;
                // }
                // img.ngo-aline2 {
                //     height: 108px;
                //     width: 300px;
                //     margin-left: 135px;
                // }
                // img.ngo-aline3 {
                //     width: 240px;
                //     height: 116px;
                // }
                // img.ngo-aline4 {
                //     width: 350px;
                //     margin-left: 36px;
                // }
                // img.ngo-aline6 {
                //     margin-left: 108px;
                // }
            }
        }
    }
}
.aboutbanner6 #home{
    img{
        width: 100%;
    }
      ul li{

    }
}

.aboutbanner6 .container {
    width: 1280px;
}

 .aboutbanner6 #menu2 ul{
    
    justify-content: flex-start;

}