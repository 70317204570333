.checkout-section {
    display        : flex;
    height         : 100vh;
    justify-content: center;
    align-items    : center;
    background     : grey;
    position       : relative;
    z-index        : 33;
    overflow       : hidden;
}

.container-checkout {
    max-width    : 480px;
    max-height   : 1700px;
    width        : 100%;
    background   : white;
    padding      : 25px 30px;
    border-radius: 20px;
    position     : relative;
    z-index      : 1;
}

.container-checkout .toptitle {
    font-size  : 15px;
    font-weight: 200;
    color      : black;
    margin     : 10px 0 0 0;
}

.user-details .input {
    .already-add-number{
        position: absolute;
        font-size: 14px;
        font-family: "Muli";
        margin: 0;
        top: 23px;
        left: 10px;
    }

    input {
        height       : 35px;
        width        : 250px;
        outline      : none;
        border-radius: 10px;
        border       : 2px solid #000;
        font-size    : 14px;
        font-family: "Muli";
        margin       : 15px 0 0 0;
        padding      : 0px 40px !important;
        display      : inline-block;
    }
}

.checkout-below {
    color: black;
}

.checkoutlogo {
    display: flex;
    margin : 20px 0px 5px 2px;
}

.bottomicon {
    margin : 0px 8px 0px 0px;
    display: flex;

    img {
        height: 40px;
    }
}

.arrow {
    color   : $a-orange-color;
    position: absolute;
    top: 24px;
    right: -25px;
    font-size: 20px;
    // top     : 24px;
    // right   : 20px;
}

.checkout-close {
    float : right;
    margin: 0px 0px 0px 0px;
    font-size: 25px;
}

.checkout-close:hover {
    cursor: pointer;
    float : right;
    margin: 0px 0px 0px 0px;
}

.numSec {
    position: relative;
    width   : 50%;
    margin  : -14px 0 0 0;
}
@media(max-width: 480px) {

    .user-details .input input{
        width: 100%;
    }
    .numSec{
        width: 100%;
    }
    .arrow {
        color: red;
        position: absolute;
        top: 32px;
        right: 20px;
    }
}