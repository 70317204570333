.business-flex {
    padding: 0px 0 50px 0;
    position: relative;

    .business-banner{
        padding-top: 60px;
    }

    .business-mobviewcol{
        
        .business-top-image-bg{
            // position: absolute;

            img{
                width: 70%;
                height: auto;
            }        
        }
    }
}

.our-business-outlest{
    padding-top: 40px;
    padding-bottom: 40px;
}

.business-h1 {
    font-size  : 45px;
    font-weight: bold;
    color      : black;
}

.business-h3 {
    font-size  : 33px;
    font-weight: bold;
    color      : black;
}

.business-para {
    font-size: 24px;
    margin   : 40px 0px 40px 0px;
    color    : black;
    width    : 75%;
}

.business-btn {
    border       : 1px solid #4b7a95;
    padding      : 12px 30px 12px 30px;
    border-radius: 8px;
    margin       : 0 15px 0 0;
    color        : black;
}

.business-btn1 {
    padding         : 12px 25px 12px 25px;
    border-radius   : 8px;
    margin          : 0 15px 0 0;
    background-color: black;
    color           : white;
}

.business-btn1:hover {
    background-color: #fff;
    color           : #212121;
    border          : 1px solid #212121;
}

.business-btn:hover {
    background-color: #212121;
    color           : #fff;
}

.customer-section {
    background-color: $a-orange-color;
    color           : white;
    padding         : 35px 0 45px 0;
}

.customer-h2 {
    text-align : center;
    font-weight: bold;
    line-height: 40px;
}

.customer-para {
    text-align: center;
    margin    : 30px 0 50px 0;
    font-size : 18px;
}

.customer {
    display        : flex;
    list-style-type: none;
    justify-content: space-around;
    flex-wrap      : wrap;
    padding        : 50px 0 0 0;
}

.customer-round {
    margin          : 100px 20px 20px 20px;
    // background-color: #6ea8c8;
    width           : 30px;
    height          : 30px;
    border-radius   : 34px;
    text-align      : center;
    margin-top      : 165px;
    border-radius: 50px;
}

.customer-image {
    width   : 80px;
    margin  : 0 0 0 10px;
    padding : 5px;
    z-index : 1;
    position: relative;
    display : flex;
}

.customer-image1 {
    margin: 18px 0 24px 10px;
}

.customer-span {
    display   : list-item;
    text-align: center;
    width     : 100%;
    font-size : 18px;
    margin    : 15px 0 0 8px;
}

.customer-icon {
    // margin: 8px 0 0 0;
    // width : 15px;
    border-radius: 50px;
}

.customer-li {
    position  : relative;
    text-align: center;
}

.customer-varient {
    display : inline-block;
    height  : 130px;
    position: relative;
}

.customer-li {
    position: relative;

    &:hover {
        .customer-white {
            display: none;
        }

        .customer-orange {
            display: block;
        }
    }

    img.customer-color {
        cursor  : pointer;
        position: absolute;
        width   : 190px;
        left    : -45px;
        top     : -45px;
        bottom  : 0;
        height  : auto;
        right   : 0;
    }
}

.partnerup-boundary {
    position: absolute;
    height  : 100%;
    width   : 100%;
    bottom  : 0;
    top     : 0;
    left    : 0;
    right   : 0;
    opacity : 0.1;
}

.place-contact {
    margin: 20px 1px 10px 10px;
}

.customer-orange {
    display: none;
}

.customer-btn {
    color           : #07486d;
    background-color: #eff5f9;
    border-radius   : 9px;
    /* font-size    : 12px; */
    font-weight     : bold;
    padding         : 12px 50px 12px 50px;
    margin          : 25px 0 0 0;
}

.businessoutlet-btn {
    border-radius   : 130px;
    background-color: white;
    border          : 3px solid #6caacc;
    width           : 200px;
    height          : 200px;
    margin          : -45px 0px 0px 0px;

    &:hover {
        background-color: #d33018;
    }
}

.buss-area {
    top   : -10px;
    left  : 74px;
    right : 0;
    bottom: 0;
}

.buss-asses {
    margin: 20px 0px 0px -8px;
}

.buss-asses1 {
    margin: 4px 0px 0px 4px;
}

.buss-asses2 {
    margin: 10px 0px 0px 4px;
}

.businessoutlet-btn {
    &:hover {
        .busshotel-black {
            display: none;
        }

        .busshotel-white {
            display: inline-flex;
        }
    }
}

.businessoutlet-btn {
    .busshotel-black {
        display: block;
    }

    .busshotel-white {
        display: none;
    }
}

.businessoutlet-center {
    position  : relative;
    text-align: center;
}

.businessoutlet-btn .busshotel-black {
    display: inline-block;
}

.businessoutlet-btn .busshotel-white {
    display: none;
}

.businessoutlet-card {
    border-radius   : 8px;
    background-color: rgb(248, 248, 248);
    margin          : 70px 0 50px 0;
    border          : none;
    box-shadow      : 0px 2px 5px 0px #ccc;
}

.businessoulet-span {
    text-align : center;
    padding    : 25px 0 25px 0px;
    color: #212121;
    // font-weight: 500;
    // font-size  : 18px;
}

.businessoutlet-h2 {
    font-weight: bold;
    text-align : center;
    margin     : 55px 0 20px 0;
}

.partnerup-section {
    // background: radial-gradient(#949ce7, #5b64c4);
    background-color: $a-orange-color;
    position  : relative;
    color     : white;
    padding   : 45px 0 45px 0;
}

.partnerup-center {
    margin-bottom: 50px;
}

.partnerup-round {
    height          : 100px;
    width           : 100px;
    background-color: white;
    border-radius   : 48px;
    display: flex;
    align-items : center;
    justify-content : center;
}

.partnerup-center {
    text-align: -webkit-center;
}

.partnerup-para {
    padding: 3px 26px 4px 26px;
}

.partnerup-h2 {
    text-align : center;
    font-weight: bold;
    padding    : 40px 0 50px 0;
}

.partnerup-img {
    margin: 20px 0 0 0;
}

.partnerup-img1 {
    margin: 30px 0 0 0;
}

.partnerup-h4 {
    font-weight: 600;
}

.restaurants-section {
    background-color: #f3f3f3;
    padding         : 55px 0px 100px 0px;
}

.servrow {
    display        : flex;
    justify-content: center;
    align-items    : center;
    vertical-align : middle;
}


.restaurants-col {
    padding   : 0 0 30px 0;
    text-align: center;
}

.restaurants-h1 {
    text-align: center;
    margin    : 10px 0px 25px 0px;
}

.carousel-indicators {
    position: absolute;
    bottom  : -42px;
}

.carousel {
    margin-top: 0px;
}

.item1 {
    background-color: #f3f3f3;
}

.offer-box {
    width           : 160px;
    height          : 164px;
    background-color: rgb(255, 255, 255);
    border-radius   : 20px;
    margin          : 0px 0px 20px 0px;
    box-shadow      : 0px 0px 5px 3px #ade1fe;
    // box-shadow: 5px 3px 8px -4px #5f9ec1, -5px -4px 10px -4px #82ff1a;
}

.offer-col {
    text-align: -webkit-center;
}

.offers-main {
    padding    : 40px 0 40px 0;   
}

.offers-h1 {
    text-align : center;
    padding    : 40px 0 40px 0;
    font-size  : 40px;
    font-weight: 600;
}

.offers-lable {
    font-size: 22px;
    padding  : 0 0 12px 0;
}

.multipledevice-h1 {
    position     : absolute;
    font-weight  : bold;
    /* text-align: center; */
    padding      : 145px 0 0px 0px;
    position     : absolute;
    /* top       : 50%; */
    left         : 50%;
    transform    : translate(-50%, -50%);
}

.multipledevice img {
    // margin-bottom: 80px;
}

.multipledevice-head {
    font-weight: bold;
}

.slide {
    padding: 20px 0 0 0;
}

.offers-img {
    width : 90px;
    margin: 30px 0 0 0;
}

.offers-para {
    margin: 0 0 10px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 40px;
}

@media (max-width: 1200px) {
    .customer-li {
        position: relative;
        margin  : 0 20px 61px 20px;
    }
    .buss-area {
        top : -10px;
        left: 74px;
    }
}

@media (max-width: 992px) {

    .partner-new-slider{
        .partnerup-center{
            .partner-new-slider-two{
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    width: 55% !important;
                    height: auto;
                }
            }
        }
    }
    .business-food-establishment-rewards{
        .business-food-establishment-rewards-two{
            width: 100% !important;
            text-align: center !important;
            height: 32vh !important;            

            img{
                width: 25% !important;
                height: auto !important;
            }
        }
    }

    .business-top-image-bg{
        text-align: center !important;
    }
    .customer-li {
        position: relative;
        margin  : 0 20px 61px 20px;
    }

    .business-mobviewcol{
        margin: 50px 0 50px 0 !important;
        padding: 0 !important;
    }

    .customer {
        display        : flex;
        list-style-type: none;
        justify-content: center;
        flex-wrap      : wrap;
        padding        : 50px 0 0 0;
    }

    .buss-area {
        top      : -10px;
        /* margin: auto; */
    }
}

@media (max-width: 764px) {
    .customer-li {
        position: relative;
        margin  : 0 20px 61px 20px;
    }

    .customer {
        display        : flex;
        list-style-type: none;
        justify-content: center;
        flex-wrap      : wrap;
        padding        : 50px 0 0 0;
    }

    .buss-area {
        top   : -10px;
        left  : 74px;
        right : 0;
        bottom: 0;
        margin: 10px 0px 0px 30px;
    }
}

@media (max-width: 600px) {
    .customer-li {
        position: relative;
        margin  : 0 20px 61px 20px;
    }

    .customer {
        display        : flex;
        list-style-type: none;
        justify-content: center;
        flex-wrap      : wrap;
        padding        : 50px 0 0 0;
    }

    .buss-area {
        top   : -10px;
        left  : 74px;
        margin: 10px 0px 0px 0px;
    }
}

@media (max-width: 550px) {
    .customer-li {
        position: relative;
        margin  : 0 20px 61px 20px;
    }

    .application {
        display: none;
    }

    .customer {
        display        : flex;
        list-style-type: none;
        justify-content: center;
        flex-wrap      : wrap;
        padding        : 50px 0 0 0;
    }

    .customer-li img.customer-color {
        cursor  : pointer;
        position: absolute;
        width   : 170px;
        left    : -10px;
        top     : -45px;
        bottom  : 0;
        height  : auto;
        right   : 0;
    }

    .customer-image {
        width   : 75px;
        margin  : 0 0 0 33px;
        z-index : 1;
        position: relative;
        padding : 5px 5px 0px 15px;
    }

    .place-contact {
        margin: 20px 1px 10px 32px;
    }
}

@media (max-width: 480px) {
    .business-mobviewcol{
        margin: -525px 0 0 0;
    }
    .business-btn {
        padding: 12px 10px 12px 10px;
    }

    .partnerup-center {
        margin-bottom: 0px;
    }

    .slick-track {
        top            : 0;
        left           : 0;
        justify-content: center;
        padding        : 0 0 30px;
        vertical-align : middle;
        align-items    : center;
        display        : flex;
    }

    .business-btn1 {
        padding: 12px 15px 12px 15px;
    }

    .customer {
        display: block;
    }

    .customer-li {
        position: relative;
        margin  : 0 20px 61px 20px;
    }

    .customer-round {
        display: none;
    }

    .voiceicon {
        display: none;
    }

    .application {
        display: none;
    }

    .business-flex {
        padding: 0 0 30px 0;
    }

    .business-h1 {
        font-size: 30px;
    }

    .business-h3 {
        font-size  : 22px;
        font-weight: bold;
        color      : black;
    }

    .business-para {
        font-size: 17px;
        margin   : 15px 0px 15px 0px;
        color    : black;
        width    : 100%;
    }

    .business-btn-align {
        // margin: 490px 0px 0px 80px;
        // bottom: -31px;
        // top   : 78px;
        // position : absolute;
        z-index: 2;
        text-align: center;
        margin: 500px 0 0 0;
    }

    .businessoutlet-h2 {
        font-weight: bold;
        text-align : center;
        margin     : 30px 0 20px 0;
        font-size  : 26px;
        color      : black;
    }

    .multipledevice-h1 {
        background-color : #F4F4F4 !important;
        position     : static;
        font-weight  : bold;
        width        : 100%;
        /* text-align: center; */
        padding      : 25px 0 0px 0px;
        transform    : translate(0%, 0%);
    }

    .multipledevice img {
        margin-bottom: 0px;
    }

    .partnerup-boundary {
        position: absolute;
        height  : 100%;
        width   : 100%;
        bottom  : 0;
        top     : 0;
        left    : 0;
        right   : 0;
        opacity : 0.1;
        display : none;
    }

    .partnerup-h2 {
        text-align : center;
        margin     : auto;
        width      : 70%;
        font-weight: bold;
        padding    : 40px 0 50px 0;
    }

    .offers-h1 {
        text-align : center;
        padding    : 40px 0 40px 0;
        font-size  : 28px;
        font-weight: 600;
    }

    .offers-para {
        padding: 0 0 55px 0;
        width  : 85%;
    }

    .offers-lable {
        font-size: 19px;
        padding  : 0 0 12px 0;
    }

    .hidden-col5 {
        display: none;
    }

    .hidden-col6 {
        display: none;
    }

    .hidden-col7 {
        display: none;
    }

    .hidden-col8 {
        display: none;
    }

    .hidden-col9 {
        display: none;
    }

    .hidden-col10 {
        display: none;
    }

    .hidden-col11 {
        display: none;
    }

    .hidden-col12 {
        display: none;
    }

    .hidden-col13 {
        display: none;
    }

    .hidden-col14 {
        display: none;
    }

    .hidden-col15 {
        display: none;
    }

    .hidden-col16 {
        display: none;
    }

    .hidden-col21 {
        display: none;
    }

    .hidden-col22 {
        display: none;
    }

    .hidden-col23 {
        display: none;
    }

    .hidden-col24 {
        display: none;
    }

    .businessoutlet-btn {
        border-radius   : 130px;
        background-color: white;
        border: 3px solid #6caacc;
        width: 120px;
        height: 120px;
        margin: -45px 0px 0px 0px;
    }
    .offer-box {
        width: 140px;
        height: 140px;
        background-color: white;
        border-radius: 20px;
        margin: 0px 0px 20px 0px;
        box-shadow: 0px 0px 5px 3px #ade1fe;
    }

    .offers-img {
        width: 50%;
        margin: 30px 0 0 0;
    }

    .buss-area {
        width : 60px;
        margin: -7px 0px 0px 0px;
    }

    .businessoutlet-card {
        border-radius   : 8px;
        background-color: #f8f8f8;
        margin          : 60px 0 13px 0;
        border          : none;
        box-shadow      : 0px 2px 5px 0px #ccc;
    }

    .customer-h2 {
        text-align : center;
        font-weight: bold;
        line-height: 34px;
    }

    .customer-para {
        text-align: center;
        margin    : 15px 0 50px 0;
        font-size : 15px;
    }

    .business-banner {
        margin: 0;
        padding-top: 0 !important;
        text-align: center !important;
    }

    .customer-li img.customer-color {
        cursor  : pointer;
        position: absolute;
        width   : 170px;
        left    : -10px;
        top     : -45px;
        bottom  : 0;
        height  : auto;
        right   : 0;
    }

    .customer-image {
        width   : 75px;
        margin  : 0 0 0 33px;
        z-index : 1;
        position: relative;
        padding : 5px 5px 0px 15px;
    }

    .place-contact {
        margin: 20px 1px 10px 32px;
    }
}