/****************************
  *@Desc Footer Css
****************************/
.footer-amealio-wrapper{
    background-color: #f8f6f1 !important;
    position: relative;
    padding: 60px 70px;

    .footer-links-head{
        h2{
        font-size: 22px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: 700; 
        color: rgb(0, 0, 0); 
        margin-bottom: 30px;
        }

        ul {
            margin: 0px;
            padding: 0px 0px 0px 8px;
            
            li {
                list-style: none;
                font-size: 18px;
                display: flex;
                justify-self: start;
                align-self: start;
                color: #000000;
                margin: 15px 0px 5px -6px;
            }
            
            li:hover {
                list-style: none;
                font-size: 18px;
                display: flex;
                justify-self: start;
                align-self: start;
                color: rgb(0, 0, 0);
                margin: 15px 0px 5px -6px;
                text-decoration: underline;
                text-decoration-color: rgb(0, 0, 0);
            }
        }

        .sociallink-amealio{

            .box-col{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 38px;
                height: 38px;
                background-color: #fff;
                border-radius: 50px;
                font-size: 15px;
                text-decoration: none;
                color: #212121;
            }
        }
    }
}

.footer-amealio-wrapperTwo{
    background-color: #f8f6f1 !important;
    padding: 20px;

    .footer-amealio-wrapper-paragraph{
        font-size: 14px;
        font-style: normal; 
        font-variant: normal; 
        font-weight: 400; 
        color:  #212121;
    }
    
}