.message-section {
    display        : flex;
    height         : 100vh;
    justify-content: center;
    align-items    : center;
    background     : grey;
    position       : relative;
    z-index        : 33;
    overflow       : hidden;
}

.message {
    max-width    : 480px;
    max-height   : 1700px;
    width        : 100%;
    background   : white;
    padding      : 25px 30px;
    border-radius: 20px;
    position     : relative;
    z-index      : 1;
}

.message-sent__box .message-sent__title {
    font-size  : 15px;
    font-weight: 200;
    color      : black;
    margin     : 10px 0 30px 0;

    h4 {
        font-size  : 20px;
        font-weight: 600;
    }
}

.message-sent__subtitle {
    p {
        font-weight: 600;
        font-size  : 15px;
    }
}

.message-sent__circle {
    font-size: 30px;
    color    : #3de03d;
}

.message-sent__title {

    i {
        margin: 0 10px 10px 0;
    }
}

.message-sent__close {
    color: #212121;
    float : right;
    font-size: 25px;
}