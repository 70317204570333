.bg-main{
    background-color: #f8f6f1 !important;
}
.bg-amealio{
    background-color: #ef4937;
}
.bg-white{
    background-color: #ffffff;
}

.food-together{
    align-items: center;
    text-align: center;
    height: 100%;
    padding-bottom: 5rem;
}
.whitecolor{
    color: #FFFFFF !important;
}
.share-meals{
    background: linear-gradient(90deg, #FAB732 11.88%, rgba(250, 183, 50, 0.98) 35.78%, rgba(250, 183, 50, 0.8) 100%);

}
.share-experience{
  background: linear-gradient(90deg, #6622CC 11.88%, rgba(102, 34, 204, 0.98) 35.78%, rgba(102, 34, 204, 0.8) 100%);
}
.share-memories{
    background: linear-gradient(90deg, #2A9D8F 11.88%, rgba(42, 157, 143, 0.98) 35.78%, rgba(42, 157, 143, 0.8) 100%);

}
.ratings-section{
    background-image: url("../../../../src/images/new/ratingsbg.png");
    background-size: cover;
  //  height: 100%;
   // padding-top: 5rem;
}
.graphs-section{
    background-color: #f8f6f1;
    height: 100vh;
}
.footer-versionf{
    padding: 160px 80px 40px 80px;
    background-color: #f8f6f1;
    
}
.listen-stories{
    height: 100%;
    background-color:#FAB732;;
}

.outlets-and-reviews{
    background-color: #ffffff !important;
}
.below-footer{
    padding-top: 2rem;
    padding-bottom: 8rem;
}
.sharelist {ul {
    li {
        font-size: 18px;
        justify-self: start;
        align-self: start;
        color: #ffffff;
    }}
}


.footerlist {ul {
    li {
        padding: 10px 4px;
        font-size: 18px;
        justify-self: start;
        align-self: start;
        color: #000000;
        list-style: none;
    }
}
h4{
    font-weight: bold;
}
}
.join-form{
    border-radius: 20px;
    input{
        background-color: #EEEEEE;
        border-radius: 10PX;
        padding: 30px 12px;
        margin: 2px auto;
        width: 90%;
        border: none;
    }
}


.friendOfAmealio{

    text-align: center;
}

.joinus-width{
    width: 88%;
}
.flexend{
    display: flex;
    justify-content: end;
    align-items: center;
}

.amealio-box{
    background-color: #ef4937;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 260px;
    width: 480px;
    margin: 1rem;
}
.yellow-box{
    background-color: #FAB732;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 260px;
    width: 300px;
    margin: 1rem;
}
.social-icons-footer{
    width: 50px;
    padding: 8px;
}


//////////////////////////
//text-sizes and colors //
//////////////////////////
.heading-h1{
    font-size: 8rem;
    font-weight: 900;
   // color: black;
    font-family: "Mulish", sans-serif;
}
.heading-h1w{
    font-size: 8rem;
    font-weight: 900;
    color: white;
    font-family: "Mulish", sans-serif;
}
.heading-h2{
    font-size: 7rem;
    font-weight: 800;
    color: black;
    font-family: "Mulish", sans-serif;
}
.heading-h3{
    font-size: 6rem;
    font-weight: 800;
    color: black;
    font-family: "Mulish", sans-serif;
}
.heading-h4{
    font-size: 4rem;
    font-weight: 800;
    color: black;
    font-family: "Mulish", sans-serif;
}
.heading-h4w{
    font-size: 4rem;
    font-weight: 800;
    color: rgb(255, 255, 255);
    font-family: "Mulish", sans-serif;
}
.heading-h5{
    font-size: 3rem;
    font-weight: 800;
    color:white;
    font-family: "Mulish", sans-serif;
}
.heading-h5b{
    font-size: 3rem;
    font-weight: 800;
    color:rgb(0, 0, 0);
    font-family: "Mulish", sans-serif;
}
.footer-text{
    font-size: 20px;
    padding: 10px 4px;
    text-align: center;
}

.paragraph-text{
    font-size: 18px;
    color: #000000;
}
.share-text-css{
    font-weight: 400;
    font-size: 6em;
}

.amealiocolor {
    color: #ef4937 !important;
  }

.whyAmealio img{
    width: 70px;
    height: 70px;
}
p{
    font-weight: 500;
}

.graphtext{
font-size: 5rem;
font-weight: 800;
font-family: "Mulish", sans-serif;
}
.graphsubtext{
 font-size: 3rem;
 font-weight: 800;
 font-family: "Mulish", sans-serif;
}




//////////////////////////
//Buttons //
//////////////////////////



.download-btn{
    color: #FFFFFF;
    border-radius: 50px;
    padding: 20px 60px;
    background-color: #ef4937;
    // border: 4px solid #00d6a0;
    font-family: "Mulish", sans-serif;
    font-size: 28px;
    line-height: 24px;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: 700;
}

.register-btn{
    display: flex;
    justify-content: end;
    align-items: center;
}
.login-btn{
    display: flex;
    justify-content: start;
    align-items: center;
}

.hallow-btn{
    color: #000000;
    border-radius: 50px;
    padding: 20px 60px;
 //   background-color: #ef4937;
     border: 2px solid #ef4937;
    font-family: "Mulish", sans-serif;
    font-size: 28px;
    line-height: 24px;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: 700;
}

.hashtags{
    color: #ef4937;
    background-color: #ffffff;
    padding: 1rem 1rem;
    z-index: 214;
    position: relative;
    top: -7em;
    left: 5rem;
    border-radius: 10px;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: "Mulish", sans-serif;
}

//////////////////////////
//paddings //
//////////////////////////


.pd-y2{
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.pd-y3{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.pd-y4{
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.pd-y5{
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.pd-y6{
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.pd-y7{
    padding-top: 7rem;
    padding-bottom: 7rem;
}
.pd-y8{
   padding: 8rem  0;
}
.pd-y10{
    padding: 10rem  0;
 }
.pd-x2{
    padding-left: 2rem;
    padding-right: 2rem;
}
.pd-x3{
    padding-left: 3rem;
    padding-right: 3rem;
}
.pd-x4{
    padding-left: 4rem;
    padding-right: 4rem;
}
.pd-x5{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}
.pd-x6{
    padding-left: 6rem;
    padding-right: 6rem;
}
.pd-x7{
    padding-left: 7rem;
    padding-right: 7rem;
}
.pd-x8{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
}
.pd-x12{
    padding-left: 12rem;
    padding-right: 12rem;
}

.testinomial-w{
    width: 25%;
}
.loginBtnsPadding{
    padding: 5rem 0;
}
.about-amealio-pd{
    padding: 0 8rem;
}
// .whyAmealio{
//     padding: 0 12rem;
// }
.share-pd{
    padding: 10rem 0;
}
.pd-graph{
    padding: 8rem 5rem;
}


//////images/////////

.share-height{
    height: 36em;
}




///desktop only//////////
/// 
/// 
/// 
.slide-bottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 
@media screen and (min-width: 768px) {
    .w-50web{
        width: 50% !important;
    }

}
/* ----------------------------------------------
 * Generated by Animista on 2022-12-21 12:53:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}



.animation {
    height:100px;
    overflow:hidden;
    margin-left: 1rem;
  }
  
  .animation > div > div {
    padding: 0.25rem 0rem;
    height:2.81rem;
    margin-bottom: 2.81rem;
    display:inline-block;
  }
  
  .animation div:first-child {
    animation: text-animation 5s infinite;
  }
  

  
  @keyframes text-animation {
    0% {margin-top: 0;}
    10% {margin-top: 0;}
    20% {margin-top: -11.62rem;}
    30% {margin-top: -11.62rem;}
    40% {margin-top: -11.62rem;}
    60% {margin-top: -23.24rem;}
    70% {margin-top: -23.22rem;}
    80% {margin-top: -23.22rem;}
    90% {margin-top: 0;}
    100% {margin-top: 0;}
  }

  @keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }
.fade-down-animi{
    opacity: 0;
	animation: fadeIn 2s ease-in both;
}
 @keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}


  
  
