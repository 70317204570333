.help-backlayer {
    position: relative;
}

.help-orgin {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.btn-link {
    p {
        // justify-content: start;
        display: flex;
        justify-content: flex-start;
    }
    .arrowdown {
        float: right;
        color: #212121;
        // margin: -30px -243px 0 0;
    }
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    margin: 0 0px 0 12px;
}

.helpresolve {
    display: flex;
    margin: 100px  0px 200px 0px;
    .resolveleft {
        h1 {
            font-size: 46px;
            color: #000;
            font-weight: 600;
        }
        p {
            font-size: 20px;
            width: 74%;
            margin: 30px 0px 30px 0px;
            color: black;
        }
        .searchicon {
            display: none;
            border: 1px solid #b0a8a8;
            border-radius: 10px;
            width: 72%;
            justify-content: space-between;
            padding: 5px 14px;
            h6 {
                font-size: 18px;
                color: #b0a8a8;
            }
            i {
                margin: 12px 0px 0px 0px;
                color: #b0a8a8;
            }
        }
    }
}

.coustomdescribe {
    display: flex;
    border: 1px solid #000;
    border-radius: 10px;
    margin: 35px 0px 45px 0px;
    width: 180px;
    border: solid #2a6282 2px;
    h3 {
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        margin-top: 17px;
        margin-bottom: 15px;
    }
    img {
        width: 30px;
        height: 30px;
        margin: 10px 10px 0px 10px;
    }
}

.coustomdescribe>h3>a {
    color: black;
    text-decoration: none;
}

.resolveright {
    img {
        width: 100%;
        margin: 100px 0px 0px 0px;
    }
}

.frequentdiscribe {
    text-align: center;
    h1 {
        font-size: 50px;
        font-weight: 600;
        color: black;
    }
    p {
        font-size: 22px;
        color: black;
    }
}

.conditionofcontent {
    ul {
        justify-content: space-between;
        margin: 40px 0px 24px 0px;
    }
    .nav-pills.topmenubar>li.active>a,
    .nav-pills.topmenubar>li.active>a:hover,
    .nav-pills.topmenubar>li.active>a:focus {
        color: #212121;
        border-bottom: 1px solid #ffa500;
        background-color: #fff;
        border-radius: 0px;
    }
    .topmenubar {
        li {
            text-align: center;
        }
        a {
            color: #212121;
            font-size: 20px;
            padding: 0;
        }
        &:focus {
            background-color: #fff;
        }
    }
}

.nav.topmenubar>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    background-color: #ffffff;
}

.card.destination {
    border: none;
    margin-bottom: 5px;
}

.card-header.varient {
    background-color: #fff;
}

.btn-link {
    color: #212121;
    text-decoration: none;
    background-color: transparent;
    font-size: 22px;
}

.btn-link:hover {
    color: #212121;
    text-decoration: none;
}

.btn-link {
    &:focus {
        border: none;
        box-shadow: none;
    }
    p {
        // justify-content: start;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
    }
}

.arrowdown {
    float: right;
    color: #212121;
    margin: -35px 0 0 0px;
}

.contactdescribe {
    text-align: center;
    margin: 100px 0px 40px 0px;
    h1 {
        font-size: 50px;
        font-weight: 600;
        color: black;
    }
    p {
        font-size: 20px;
        margin: 0px 60px;
        font-weight: 500;
        line-height: 30px;
        color: black;
    }
}

.contactforms {
    h1 {
        font-size: 40px;
        font-weight: 600;
        margin: 75px 0px 30px 0px;
        color: black;
    }
}

// .plane-contact {
//     &:hover {
//         display: block;
//     }
// }
// .plane-contact1 {
//     display: none;
// }
.plane-contact::before {
    visibility: hidden;
    position: relative;
}

.plane-contact:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "This text replaces the original.";
}

.plane-contact:hover {
    opacity: 0;
    content: "This text replaces the original.";
}

.contactdetails {
    margin-bottom: 100px;
    .formfiller {
        display: flex;
        flex-direction: column;
        // position: relative;
        .secondarycategory {
            width: 100%;
            padding: 10px;
            border: 1px solid #878787;
            border-radius: 5px;
            text-align: start;
            color: #8a8b9c;
            background-color: #fff;
            margin-bottom: 5px;
            &::after {
                // justify-content: end;
                justify-content: flex-end;
            }
        }
    }
    input {
        padding: 10px;
        margin: 5px 0px 5px 0px;
        &:focus {
            border: none;
        }
    }
    .messagespot {
        padding: 10px 10px 135px 10px;
    }
    .primarybutton {
        background-color: #ee3d23;
        border: 1px solid #ee3d23;
        border-radius: 5px;
        width: 30%;
        color: #fff;
        font-size: 20px;
        display: inline-block;
        text-align: center;
    }
}

.maildetails {
    img {
        width: 100%;
    }
}

@media(max-width: 480px) {
    .helpresolve {
        margin: 100px 0px 200px 0px;
        .resolveleft {
            h1 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
                width: 100%;
                margin: 30px 0px 30px 0px;
            }
        }
    }
    .coustomdescribe {
        width: 140px;
        border: solid #2a6282 2px;
        border-radius: 10px;
        img {
            width: 25px;
            height: 25px;
            margin: 14px 10px 0px 12px;
        }
        a {
            font-size: 14px;
        }
        h3 {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
    
    .conditionofcontent .topmenubar a {
        font-size: 17px;
        padding: 0;
    }
    .conditionofcontent ul {
        justify-content: space-between;
        margin: 20px 0px 10px 0px;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
    }
    .conditionofcontent .topmenubar li {
        text-align: center;
        white-space: nowrap;
        margin: 0 10px 0 0;
    }
    .contactdescribe {
        margin: 30px 0px 40px 0px;
        h1 {
            font-size: 28px;
            font-weight: 600;
            color: black;
        }
        p {
            font-size: 18px;
            margin: 0;
            color: black;
        }
    }
    .contactforms h1 {
        font-size: 20px;
        color: black;
    }
    
    .conditionofcontent ul {
        justify-content: space-between;
        margin: 40px 0px 24px 20px;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
    }
    .conditionofcontent .topmenubar li {
        text-align: center;
        white-space: nowrap;
        margin: 0 10px 0 0;
    }
    .customer-varient {
        width: 150px;
    }
    .customer-span {
        margin: 0;
    }
}

@media(max-width: 600px) {
    .helpresolve {
        margin: 102px 0px 200px 0px;
        .resolveleft {
            h1 {
                font-size: 41px;
            }
            p {
                font-size: 22px;
                width: 100%;
                margin: 30px 0px 30px 0px;
            }
        }
    }
    .coustomdescribe {
        width: 180px;
        border: solid #2a6282 2px;
        border-radius: 10px;
        h3 {
            margin-top: 17px;
            margin-bottom: 15px;
        }
    }
    .frequentdiscribe {
        text-align: center;
        padding-top: 100px;
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 23px;
        }
    }
    .conditionofcontent .topmenubar a {
        font-size: 17px;
        padding: 0;
    }
    .contactdescribe {
        h1 {
            font-size: 28px;
            font-weight: 600;
            color: black;
        }
        p {
            font-size: 22px;
            margin: 0;
            color: black;
        }
    }
    .contactforms h1 {
        color: black;
    }
    .maildetails {
        padding-top: 100px;
    }
    .textchangebtn{
        display: inline-block;
        text-align: center;
    } 
    
}

@media(max-width: 768px) {
    .helpresolve {
        margin: 132px 0px 200px 0px;
        .resolveleft {
            h1 {
                font-size: 41px;
            }
            p {
                font-size: 22px;
                width: 100%;
                margin: 30px 0px 30px 0px;
            }
        }
    }
    .coustomdescribe {
        width: 180px;
        border: solid #2a6282 2px;
        border-radius: 10px;
        h3 {
            margin-top: 17px;
            margin-bottom: 15px;
        }
    }
    .frequentdiscribe {
        text-align: center;
        padding-top: 100px;
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 23px;
        }
    }
    .conditionofcontent .topmenubar a {
        font-size: 17px;
        padding: 0;
    }
    .contactdescribe {
        h1 {
            font-size: 28px;
            font-weight: 600;
            color: black;
        }
        p {
            font-size: 22px;
            margin: 0;
            color: black;
        }
    }
    .contactforms h1 {
        color: black;
    }
    .maildetails {
        padding-top: 100px;
    }
}

@media(max-width: 992px) {
    .helpresolve {
        .resolveleft {
            h1 {
                font-size: 41px;
            }
            p {
                font-size: 22px;
                width: 100%;
                margin: 30px 0px 30px 0px;
            }
        }
    }
    .coustomdescribe {
        width: 180px;
        border: solid #2a6282 2px;
        border-radius: 10px;
        h3 {
            margin-top: 17px;
            margin-bottom: 15px;
        }
    }
    .frequentdiscribe {
        text-align: center;
        padding-top: 100px;
        h1 {
            font-size: 50px;
        }
        p {
            font-size: 23px;
        }
    }
    .conditionofcontent .topmenubar a {
        font-size: 17px;
        padding: 0;
    }
    .contactdescribe {
        h1 {
            font-size: 28px;
            font-weight: 600;
            color: black;
        }
        p {
            font-size: 22px;
            margin: 0;
            color: black;
        }
    }
    .contactforms h1 {
        color: black;
    }
    .maildetails {
        padding-top: 100px;
    }
}

@media(max-width: 1200px) {
    .helpresolve {
        .resolveleft {
            h1 {
                font-size: 41px;
            }
            p {
                font-size: 22px;
                width: 100%;
                margin: 30px 0px 30px 0px;
            }
        }
    }
    .coustomdescribe {
        width: 180px;
        border: solid #2a6282 2px;
        border-radius: 10px;
        h3 {
            margin-top: 17px;
            margin-bottom: 15px;
        }
    }
    .frequentdiscribe {
        text-align: center;
        // h1 {
        //     font-size: 50px;
        // }
        p {
            font-size: 16px;
        }
    }
    .conditionofcontent .topmenubar a {
        font-size: 17px;
        padding: 0;
    }
    .contactdescribe {
        h1 {
            font-size: 28px;
            font-weight: 600;
            color: black;
        }
        p {
            font-size: 22px;
            margin: 0;
            color: black;
        }
    }
    .contactforms h1 {
        color: black;
    }
    .maildetails {
        padding-top: 100px;
    }
}

@media(max-width: 480px) {
    .helpresolve {
            display: flex;
            margin: 90px 0 0 0;
        
      
        .resolveleft {
            h1 {
                font-size: 20px;
                color: #000;
                font-weight: 600;
            }
            p {
                font-size: 14px;
                width: 100%;
                // margin: 300px 0px 0px 0px;
                color: black;
            }
            .searchicon {
                display: flex;
                border: 1px solid #b0a8a8;
                border-radius: 10px;
                width: 72%;
                justify-content: space-between;
                padding: 5px 14px;
                h6 {
                    font-size: 18px;
                    color: #b0a8a8;
                }
                i {
                    margin: 12px 0px 0px 0px;
                    color: #b0a8a8;
                }
            }
        }
    }
    .coustomdescribe{
        width: 135px;
    }
    .resolveright {
        // margin: -482px 0 0 0;
    }
    .btn-link {
        color: #212121;
        text-decoration: none;
        background-color: transparent;
        font-size: 14px;
        width: 75%;
    }
    .arrowdown {
        float: right;
        color: #212121;
        margin: -25px -199px 0 0px;
    }
    .contactdescribe h1 {
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 22px 0px;
        color: black;
    }
    .contactdescribe p {
        font-size: 14px;
        margin: 0;
        color: black;
        line-height: 20px;
    }
    .maildetails {
        padding-top: 0px;
        // margin: -800px 0 0 0;
        z-index: 2;
        position: relative;
    }

    .contactdetails .formfiller {
        display: flex;
        flex-direction: column;
        // z-index: 222;
        position: relative;
        order: 2;
    }
    .resolveright img{
        margin: 0;
    }
    .contactforms {
        // margin: 350px 0 0 0;
        
    }
    .contactdetails {
        margin-bottom: 50px;
    }
    .frequentdiscribe {
        padding-top: 25px;
        h1{
        font-size: 20px;
        }
        p {
            font-size: 14px;
        }
    }
    .conditionofcontent{
        button.btn.btn-link {
            width: 100%;
        }
        .btn-link{
            width: 100%;
            text-align: left;
        }
        .card-header.varient {
            background-color: #fff;
            white-space: break-spaces;
            width: 100%;
        }
        .btn-link .arrowdown {
            margin: -42px -15px 0px 0px;
        }
    } 
    .conditionofcontent .topmenubar li{
        padding: 0 0 15px 0;
    }
    .contactdetails .primarybutton{
        font-size: 14px;
    }
}

.form-select {
    height: 44px;
    height: 44px;
    color: #747474;
    font-size: 14px;
    border: 1px solid #6a6565;
}

.form-select:hover {
    height: 44px;
    border-color: black;
}

.dropdown1-item {
    font-size: 0px;
}

.dropdown-item {
    // font-size: 12px;
}