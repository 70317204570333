.communitySec {
    padding-top: 138px;
    display: table;
    width: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 100vh;
    h1 {
        color: white;
        font-size: 51px;
        font-weight: 600;
    }
    p {
        color: white;
        font-size: 20px;
        margin: 0%;
        padding-top: 51px;
    }
    span {
        color: white;
        padding: 0 0 0 15px;
        font-size: 16px;
    }
}

.insta {
    margin-top: 29px;
}

.facebook {
    margin-top: 29px;
    padding: 0 0 0 22px;
}

.socialcommunity {
    display: flex;
    margin-bottom: 50px;
}

.communitybanner1 {
    padding-top: 50px;
    img {
        width: 100%;
    }
}

.communitybanner2 {
    text-align: center;
    justify-content: center;
    background-color: white;
    padding-top: 40px;
    button {
        background-color: #ee3d23;
        color: white;
        width: 189px;
        font-size: 16px;
        font-weight: 100;
        border-radius: 7em;
        padding: 10px;
        border: none;
    }
    .btn-warning:hover,
    .btn-warning:focus,
    .btn-warning:active,
    .btn-warning.active,
    .open>.dropdown-toggle.btn-warning {
        background-color: #ee3d23;
    }
}

.communitybanner3 {
    display: flex;
    text-align: center;
    justify-content: center;
    img {
        height: fit-content;
    }
    a {
        font-weight: 600;
        color: rgb(87, 87, 87); 
        text-decoration: none;
        font-size: 20px;
        padding: 20px 0px;
    }
}

.community-insta{
    width: 100%;
    margin: 65px 0 0 0;
    > div > div{
        flex-basis: 32%;
    }
}

@media(max-width: 1200px){

}
@media(max-width: 992px){

}
@media(max-width: 764px){

}
@media(max-width: 600px){

}
@media(max-width: 480px){
.colorHeader{
    position: absolute;
}
.communitySec h1 {
    color: white;
    font-size: 29px;
    font-weight: 600;
    margin: 0 0px 0px 0px;
    line-height: 41px;
}
.communitySec p {
    color: white;
    font-size: 16px;
    margin: 0%;
    padding-top: 28px;
}
.socialcommunity {
    display: block;
    margin-bottom: 0px; 
}
.facebook {
    margin-top: 29px;
    padding: 0px;
}
.communitySec span {
    color: white;
    padding: 0 0 0 15px;
    font-size: 24px;
}
.community-insta {
    width: 100%;
    margin:0
}
.community-insta-section{
    overflow: hidden;
}
}